import React from 'react'
import { connect } from 'react-redux'

import { callApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

import SHSForm from './shs-form'
import InputElement from './input'


class EditableDisplayField extends React.Component {
    state = {
        inEdit: false
    }

    renderDisplay = () => {
        return (
            <div className={`label-values ${this.props.extraClass ? this.props.extraClass : ''}`}>
                <span className="label">{this.props.label}</span>
                <p className="values can-edit" onClick={() => this.setState({ inEdit: true })}>
                    {this.props.display || '--'}
                </p>
            </div>
        )
    }

    successFX = () => {
        if (this.props.onSuccess) {
            this.props.onSuccess()
        }
        this.setState({ inEdit: false })
    }

    submitFX = (formData, formId) => {
        this.props.dispatch(callApi(
            apiResourceEndpoint(this.props.endpoint, 'UPDATE', this.props.dataObj.id, this.props.extraEndpoint ? this.props.extraEndpoint : ''),
            formData, formId))
    }

    generateInputFields = () => {
        let allFields = []
        this.props.fields.forEach(field => {
            allFields.push(
                <InputElement
                    key={field.field}
                    type={field.type}
                    label={field.label || this.props.label}
                    id={field.field}
                    default={this.props.dataObj[field.field]}
                    options={field.options || null}
                    placeholder={field.placeholder || null}
                    required={field.required || false}
                    isFormatted={field.isFormatted || false}
                    formatMask={field.formatMask || null}
                    {...field.extraProps} />
            )
        })
        return allFields
    }

    renderForm = () => {
        return (
            <SHSForm
                submitFX={this.submitFX}
                successFX={this.successFX}
                extraClass={`${this.props.inTable === true ? 'inline-edit' : ''} ${this.props.extraClass ? this.props.extraClass : ''}`}
                inline={this.props.inTable === true ? true : false}
                buttonLabel="Save"
                cancelButtonLabel="Exit"
                showCancel={true}
                cancelAction={() => this.setState({ inEdit: false })}
            >
                {this.generateInputFields()}
            </SHSForm>
        )
    }

    render = () => {
        return (
            <>
                { this.state.inEdit
                    ? this.renderForm()
                    : this.renderDisplay()
                }
            </>
        )
    }
}

export default connect()(EditableDisplayField)
