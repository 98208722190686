import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import TicketHeader from './parts/ticket-header'
import OverviewBlock from './overview/block'
import ContactBlock from './contact/block'
import GuestBlock from './guests/block'
import LocationBlock from './locations/block'
import MenuBlock from './menus/block'
import ChefBlock from './chefs/block'
import InventoryBlock from './inventory/block'
import BudgetBlock from './budget/block'
import TimelineBlock from './timeline/block'
import GroceryBlock from './groceries/block'
import SousChefBlock from './sous-chefs/block'
import TravelBlock from './travel/block'
import ServerBlock from './servers/block'


const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        sousChefMeta: state.ticketSousChefMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        travelMeta: state.ticketTravelMeta.find(ttm => ttm.ticketId === Number(ownProps.params.ticketId)),
        serverMeta: state.ticketServerMeta.find(tsm => tsm.ticketId === Number(ownProps.params.ticketId)),
        groceryMeta: state.ticketGroceryMeta.find(tgm => tgm.ticketId === Number(ownProps.params.ticketId))
    }
}

const TicketView = ({ dispatch, params, ticket, sousChefMeta, travelMeta, serverMeta, groceryMeta }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-vibes', 'LIST')))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return <span>Loading ticket....</span>
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            <OverviewBlock ticket={ticket} />
            <ContactBlock ticket={ticket} />
            <GuestBlock ticket={ticket} />
            <ChefBlock ticket={ticket} />
            <MenuBlock ticket={ticket} />
            <LocationBlock ticket={ticket} />
            <TimelineBlock ticket={ticket} />
            <InventoryBlock ticket={ticket} />
            <BudgetBlock ticket={ticket} />
            {groceryMeta &&
            <GroceryBlock ticket={ticket} />
            }
            {sousChefMeta &&
            <SousChefBlock ticket={ticket} />
            }
            {travelMeta &&
            <TravelBlock ticket={ticket} />
            }
            {serverMeta &&
            <ServerBlock ticket={ticket} />
            }
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketView))
