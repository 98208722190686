import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded, callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import ChefCard from './chef-card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'

import EditableDisplayField from '../../../utils/editable-display-field'

import SearchComplete from '../../../utils/search-complete'

const mapStateToProps = (state, ownProps)=> {
    const ticketChefs = state.ticketChefs.filter(tm => tm.ticketId === Number(ownProps.params.ticketId))
    const ticketChefIds = ticketChefs.map(tm => tm.apiChefId)
    const chefs = state.adminChefs.filter(adminChef => {
        return ticketChefIds.indexOf(adminChef.id) > -1
    })
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        chefMeta: state.ticketChefMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        chefs,
        ticketChefs,
        ticketChefIds
    }
}

const TicketChefs = ({ dispatch, params, ticket, chefMeta, chefs, ticketChefs, ticketChefIds }) => {
    const [showAddForm, setShowAddForm] = useState(false)
    const ticketChefIdString = ticketChefIds.join(',')

    const renderChefResult = (result) => {
        return (
            <>
                {result.displayName}
            </>
        )
    }

    const handleSelectResult = (result) => {
        const formData = {
            ticketId: ticket.id,
            apiChefId: result.id
        }
        dispatch(callApi(apiResourceEndpoint('admin/ticket-chefs', 'CREATE'), formData, -1))
        setShowAddForm(false)
    }

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-chefs', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    useEffect(() => {
        if (ticketChefIdString) {
            let filterData = ticketChefIdString.split(',').map(id => `id|${id}`)
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chefs', 'LIST'), { filter: filterData, filterMethod: 'OR' }))
        }
    }, [dispatch, ticketChefIdString])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="user-ninja" /> <span>Chefs</span>
                </div>
                <div className="meta-content">
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'CHEFS' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderMetaNotes = () => {
        return (
            <div className="meta-manager">
                <EditableDisplayField
                    label="Notes"
                    display={chefMeta.notes}
                    dataObj={chefMeta}
                    endpoint="admin/ticket-chef-meta"
                    extraClass="field name"
                    inTable={true}
                    fields={[{
                        type: 'textarea',
                        field: 'notes'
                    }]} />
            </div>
        )
    }

    const renderAddChef = () => {
        if (showAddForm) {
            return (
                <div className="item">
                    <SearchComplete
                        placeholder="Search for a Chef"
                        searchEndpoint="admin/chefs"
                        renderTemplate={renderChefResult}
                        onHandleSelect={handleSelectResult}
                        allowCreation={false} />
                    <span onClick={() => setShowAddForm(false)}>Cancel</span>
                </div>
            )
        }
        return (
            <div className="btn menu-block add-menu-item mb-3" onClick={() => setShowAddForm(true)}>
                + Add a Chef
            </div>
        )
    }


    const renderChefs = () => {
        let allChefs = []
        chefs.forEach(chef => {
            allChefs.push(
                <div key={chef.id} className="cards list" >
                    <ChefCard chef={chef} dispatch={dispatch} ticketChefs={ticketChefs} />
                </div>
            )
        })
        return (
            <div className="ticket-items">
                {allChefs}
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderChefs()}
            {renderAddChef()}
            {renderMetaNotes()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketChefs))
