import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { Link } from 'react-router-dom'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

import { generatePasswordResetLink } from '../../../actions/auth'
import { routes } from '../../../constants/routes'

const PasswordHelp = ({ dispatch, fireauth, location }) => {
    const [linkSent, setLinkSent] = useState(false)

    const successFX = () => {
        setLinkSent(true)
    }

    const submitFX = (formData, formId) => {
        dispatch(generatePasswordResetLink(fireauth, formData.email, formId))
        setLinkSent(true)
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <h1>Password Help</h1>
                        {linkSent ? (
                            <p>Check your email, we sent you a link to help reset your password.</p>
                        ) : (
                            <>
                                <p>Please enter your email address and we will send you a link to set your password.</p>
                                <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Submit" buttonClass="black center" buttonDisabled={!fireauth}>
                                    <InputElement
                                        type="email"
                                        default=""
                                        id="email"
                                        label="Email Address"
                                        placeholder="user@example.com"
                                        extraClass="flex-100"
                                        required
                                    />
                                </SHSForm>
                            </>
                        )}
                        <div className="mt-3">
                            <Link to={routes.authLogin.path} className="m-txt">
                            Return to login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect()(PasswordHelp))
