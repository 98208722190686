export const TICKET_CONFIG = {
    OVERVIEW: {
        label: 'Overview',
        icon: ['fas', 'list']
    },
    GUESTS: {
        label: 'Guests',
        icon: 'user-friends'
    },
    VENUES: {
        label: 'Venues',
        icon: 'map-marker-alt'
    },
    MENUS: {
        label: 'Menus',
        icon: 'carrot'
    },
    CHEFS: {
        label: 'Chefs',
        icon: 'user-ninja'
    },
    INVENTORY: {
        label: 'Inventory',
        icon: 'clipboard-list'
    },
    COST: {
        label: 'Budget',
        icon: 'money-bill-wave'
    },
    TIMELINE: {
        label: 'Timeline',
        icon: 'project-diagram'
    },
    GROCERIES: {
        label: 'Groceries',
        icon: 'shopping-cart',
        path: 'groceries'
    },
    TRAVEL: {
        label: 'Travel',
        icon: 'car',
        path: 'travel'
    },
    SERVERS: {
        label: 'Servers',
        icon: 'user-tie',
        path: 'server'
    },
    SOUS_CHEF: {
        label: 'Sous Chef',
        icon: 'mitten',
        path: 'sous-chef'
    },
    CONTACT: {
        label: 'Contact',
        icon: 'address-card',
        path: 'contact'
    },
    AVAILABILITY: {
        label: 'Availability',
        icon: ['far', 'calendar-alt'],
        path: 'availability'
    },
    PHOTOS: {
        label: 'Photos',
        icon: 'images',
        path: 'photos'
    },
    CHEF_MENU: {
        label: 'Menus',
        icon: 'bowl-rice',
        path: 'menu'
    },
    PROFILE: {
        label: 'Profile',
        icon: 'user-ninja'
    },
    DOCUMENTS: {
        label: 'Documents',
        icon: 'file'
    }
}
