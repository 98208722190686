import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import DisplayValue from '../../../utils/display-value'

import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'
import { formatDate, formatTime, valueFromConfig } from '../../../utils/data-formatters'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state) => {
    return {
        apiCuisines: state.apiCuisines,
        serviceStyles: state.apiServiceStyles
    }
}

const LeadEventInfo = (props) => {
    const { leadForm, dispatch, apiCuisines, serviceStyles } = props

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
    }, [dispatch])

    const renderAddress = () => {
        let address = ''
        if (leadForm.street) {
            address = address + ' ' + leadForm.street
        }
        if (leadForm.city) {
            address = address + ' ' + leadForm.city
        }
        if (leadForm.state) {
            address = address + ' ' + leadForm.state
        }
        if (leadForm.postal) {
            address = address + ' ' + leadForm.postal
        }
        return address.trim()
    }

    return (
        <div className="event-block">
            <div className="block-type">
                <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Event Info
            </div>
            <div className="content">
                <div className="displays">
                    <DisplayValue label="Event Name" value={leadForm.eventName} extraClass="two-thirds" />
                    <DisplayValue label="Headcount" value={leadForm.headcount} extraClass="one-third" />
                    <DisplayValue label="Date" value={formatDate(leadForm.eventDate, true)} extraClass="two-thirds" />
                    <DisplayValue label="Time" value={formatTime(leadForm.eventTime)} extraClass="one-third" />
                    <DisplayValue label="Location" value={renderAddress()} />
                    <DisplayValue label="Service Style" value={valueFromConfig(serviceStyles, leadForm.serviceStyleId, 'name')} extraClass="half" />
                    <DisplayValue label="Cuisine Type" value={valueFromConfig(apiCuisines, leadForm.cuisineTypeId, 'name')} extraClass="half" />
                    <DisplayValue label="Message" value={leadForm.message} />
                </div>
            </div>
            <div className="actions">&nbsp;</div>
        </div>
    )
}

export default connect(mapStateToProps)(LeadEventInfo)
