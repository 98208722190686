import React from 'react'
import { connect } from 'react-redux'
import { formatTime } from '../../utils/formatters'

const mapStateToProps = (state, ownProps) => {
    return {
        timelines: state.ticketTimelines.filter(tt => tt.ticketId === ownProps.ticket.id)
            .sort((a, b) => {
                if (a.time > b.time) {
                    return 1
                }
                return -1
            })
    }
}

const TicketTimeline = ({ ticket, timelines }) => {
    const renderTimeline = () => {
        let allEvents = []
        timelines.forEach(timeline => {
            if (timeline.time) {
                allEvents.push(
                    <tr key={timeline.id}>
                        <td>{timeline.event}</td>
                        <td>{formatTime(timeline.time)}</td>
                        <td>{timeline.scope}</td>
                        <td>{timeline.notes}</td>
                    </tr>
                )
            }
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Time</th>
                        <th>Scope</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allEvents}
                </tbody>
            </table>
        )
    }

    return (
        <div className="section ticket-timeline">
            <h2>Event Timeline</h2>
            {renderTimeline()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketTimeline)
