import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import { buildRoutePath, nestedRoutePath, routes } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MenusMenuIndex from './menus/index'
import FoodItemsIndex from './food-items/index'

const MenusIndex = () => {
    return (
        <>
            <div className="section-header">
                <span className="title">Menus</span>
            </div>
            <div className="dashboard-wrapper">
                <div className="sidebar">
                    <div className="side-nav">
                        <NavLink to={buildRoutePath(routes.adminMenusIndex.path)}>
                            <FontAwesomeIcon icon="clipboard-list" /> Menus
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminFoodItemsIndex.path)}>
                            <FontAwesomeIcon icon="carrot" /> Food Items
                        </NavLink>
                    </div>
                </div>
                <div className="main">
                    <Routes>
                        <Route path={nestedRoutePath(routes.adminMenusIndex.path, routes.adminFoodItemsIndex.path, true)} element={<FoodItemsIndex />} />
                        <Route path={nestedRoutePath(routes.adminMenusIndex.path, routes.adminMenusIndex.path, true)} element={<MenusMenuIndex />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default MenusIndex
