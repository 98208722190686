import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'
import TicketHeader from '../parts/ticket-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'
import EditableDisplayField from '../../../utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        apiCuisines: state.apiCuisines,
        apiServiceStyles: state.apiServiceStyles,
        apiVibes: state.apiVibes
    }
}

const TicketOverview = ({ dispatch, params, ticket }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-vibes', 'LIST')))
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon={['fas', 'list']} /> <span>Overview</span>
                </div>
                <div className="meta-content">
                    <span className="meta-value">Coming soon...</span>
                    <span className="icon-btn"><FontAwesomeIcon icon="pen" /></span>
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'OVERVIEW' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderOverview = () => {
        return (
            <div className="category-blocks box">
                <div className="row">
                    <div className="col">
                        <EditableDisplayField
                            label="Event Name"
                            display={ticket.eventName}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'eventName'
                            }]} />
                        <EditableDisplayField
                            label="Event Date"
                            display={ticket.eventDate}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'date',
                                field: 'eventDate'
                            }]} />
                        <EditableDisplayField
                            label="Event Time"
                            display={ticket.eventTime}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'time',
                                field: 'eventTime'
                            }]} />
                        <EditableDisplayField
                            label="Notes"
                            display={ticket.notes}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'textarea',
                                field: 'notes'
                            }]} />
                    </div>
                    <div className="col">
                        <EditableDisplayField
                            label="Street"
                            display={ticket.street}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'street'
                            }]} />
                        <EditableDisplayField
                            label="City"
                            display={ticket.city}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'city'
                            }]} />
                        <EditableDisplayField
                            label="State"
                            display={ticket.state}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'state'
                            }]} />
                        <EditableDisplayField
                            label="Postal"
                            display={ticket.postal}
                            dataObj={ticket}
                            endpoint="admin/tickets"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'postal'
                            }]} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderOverview()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketOverview))
