import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'

import { PaginationWrapper } from 'shs-pagination'


const mapStateToProps = (state) => {
    const paginator = state.paginator.adminFoodItems
    let foodItems = state.adminFoodItems
    if (paginator) {
        foodItems = foodItems
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return { foodItems, paginator }
}

const FoodItemsList = ({ dispatch, foodItems, paginator, navigate }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/food-items', 'LIST')))
    }, [dispatch])

    if (!foodItems) {
        return null
    }

    const renderFoodItemRow = (foodItem, index) => {
        return (
            <div key={foodItem.id} className="paginated-item">
                <span className="index">{index}.</span>
                <Link to={buildRoutePath(routes.adminFoodItemsView.path, { foodItemId: foodItem.id })} className="main">
                    {foodItem.title}
                </Link>
                <span>{foodItem.description}</span>
                <span>{foodItem.owner}</span>
            </div>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/food-items', 'LIST'), paginationParams))
    }

    const renderFoodItems = () => {
        if (!paginator) {
            return null
        }
        let allFoodItems = []
        foodItems.forEach((foodItem, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allFoodItems.push(renderFoodItemRow(foodItem, index))
        })

        const controls = {
            search: { active: true, collapsed: true }
        }

        return (
            <PaginationWrapper
                paginator={paginator}
                controls={controls}
                callbackFX={handlePaginationUpdate}
                countOptions={[2, 5, 10, 20]}>
                {allFoodItems}
            </PaginationWrapper>
        )
    }

    const renderActionButton = () => {
        return (
            <Link to={routes.adminFoodItemsCreate.path}>
                Add Food Items +
            </Link>
        )
    }

    return (
        <>
            {renderFoodItems()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(FoodItemsList))
