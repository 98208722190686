import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { apiResourceEndpoint } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'

import EditableDisplayField from '../../utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        photo: state.adminPhotos.find(p => p.id === ownProps.params.photoId)
    }
}

const ViewPhoto = ({ dispatch, photo, params }) => {
    const photoId = params.photoId

    useEffect(() => {
        if (photoId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/photos', 'VIEW', photoId)))
        }
    }, [dispatch, photoId])

    if (!photo) {
        return null
    }

    return (
        <>
            <div className="section-header">
                <span className="title">
                    {photo.name}
                </span>
            </div>
            <div className="dashboard-wrapper">
                <div className="box w-100">
                    <div className="row">
                        <div className="col-3">
                            <img width="100%" src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.id}`, 'LIST').url + '?style=medium'} alt={photo?.alt || photo.name} />
                        </div>
                        <div className="col-9 field-wrappers">
                            <EditableDisplayField
                                label="Name"
                                display={photo.name}
                                dataObj={photo}
                                endpoint="admin/photos"
                                fields={[{
                                    type: 'text',
                                    field: 'name'
                                }]} />
                            <EditableDisplayField
                                label="Alt Text"
                                display={photo.alt}
                                dataObj={photo}
                                endpoint="admin/photos"
                                fields={[{
                                    type: 'text',
                                    field: 'alt'
                                }]} />
                            <EditableDisplayField
                                label="Caption"
                                display={photo.caption}
                                dataObj={photo}
                                endpoint="admin/photos"
                                fields={[{
                                    type: 'text',
                                    field: 'caption'
                                }]} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewPhoto))
