import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

const mapStateToProps = (state, ownProps) => {
    return {
        foodItem: state.adminFoodItems.find(fi => fi.id === ownProps.params.foodItemId),
        restrictions: state.adminDietaryRestrictions.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}

const AddDietary = ({ dispatch, foodItem, restrictions }) => {
    const [ showForm, setShowForm ] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/dietary-restrictions', 'LIST')))
    }, [dispatch])

    const handleRestrictionsToggle = (dietaryRestrictionId, isActive) => {
        if (isActive) {
            dispatch(callApi(apiResourceEndpoint('admin/food-items', 'UPDATE', foodItem.id, 'detach-dietary'), { dietaryRestrictionId }, -1))
        } else {
            dispatch(callApi(apiResourceEndpoint('admin/food-items', 'UPDATE', foodItem.id, 'attach-dietary'), { dietaryRestrictionId }, -1))
        }
    }

    const renderRestrictions = () => {
        let activeRestrictions = restrictions.filter(restriction => foodItem._computed.dietaryRestrictionIds.indexOf(restriction.id) !== -1)
        let activeRendered = []
        let allRendered = []
        activeRestrictions.forEach(restriction => {
            activeRendered.push(
                <div className="allergen active" key={restriction.id}>
                    {restriction.name}
                </div>
            )
        })
        restrictions.forEach(restriction => {
            const isActive = foodItem._computed.dietaryRestrictionIds.indexOf(restriction.id) !== -1 ? true : false
            allRendered.push(
                <div key={restriction.id} className={`allergen ${isActive ? 'active' : ''}`} onClick={() => handleRestrictionsToggle(restriction.id, isActive)}>
                    {restriction.name}
                </div>
            )
        })
        return (
            <>
                <h2>Dietary Restrictions</h2>
                { showForm
                    ? <>
                        <div className="allergen-options">
                            {allRendered}
                        </div>
                        <span className="clickable" onClick={() => setShowForm(false)}>Save</span>
                    </>
                    : <>
                        <div className="active-allergens">
                            {activeRendered.length > 0 ? activeRendered : <p><strong>No Dietary Restrictions</strong></p>}
                        </div>
                        <span className="clickable" onClick={() => setShowForm(true)}>Manage Dietary Restrictions</span>
                    </>
                }
            </>
        )
    }

    return (
        <div className="event-block">
            <div className="content">
                {renderRestrictions()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AddDietary))
