import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        travels: state.ticketTravel.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketTravel = ({ travels }) => {
    const renderTravels = () => {
        let allTravels = []
        travels.forEach(travel => {
            allTravels.push(
                <tr key={travel.id}>
                    {/* <td>{menu.apiMenuId}</td>
                    <td>{menu.template}</td> */}
                    <td>{travel.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Travel</th>
                        <th>Cost</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allTravels}
                </tbody>
            </table>
        )
    }

    if (!travels || travels.length === 0) {
        return null
    }

    return (
        <div className="section ticket-timeline">
            <h2>Travel Costs</h2>
            {renderTravels()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketTravel)
