import { signOut, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset, EmailAuthProvider, reauthenticateWithCredential, updatePassword, createUserWithEmailAndPassword } from 'firebase/auth'
// import { actionCodeSettings } from '../firebase/firebase'
import { formStates } from '../constants/helper-states'
import { upsertForm } from '../reducers/form'
import { saveAuth } from '../reducers/auth'
import { saveAuthHandler, saveAuthHandlerError } from '../reducers/auth-code'

export const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}


export const clearAuthCode = () => {
    return {
        type: `REMOVE_AUTH_HANDLER`
    }
}


export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                let message = 'Login failed. Please check your email and password.'
                switch (err.code) {
                case 'auth/user-not-found':
                    message = 'User not found. Please check your email and password.'
                    break
                case 'auth/wrong-password':
                    message = 'Incorrect password. Please check your email and password.'
                    break
                case 'email-already-in-use':
                    message = 'Email already in use. Please try another email.'
                    break
                case 'auth/weak-password':
                    message = 'Password is too weak. Please try another password.'
                    break
                default:
                    break
                }
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${message}` }))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then((user) => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                let message = 'Login failed. Please check your email and password.'
                switch (err.code) {
                case 'auth/user-not-found':
                    message = 'User not found. Please check your email and password.'
                    break
                case 'auth/wrong-password':
                    message = 'Incorrect password. Please check your email and password.'
                    break
                case 'email-already-in-use':
                    message = 'Email already in use. Please try another email.'
                    break
                case 'auth/weak-password':
                    message = 'Password is too weak. Please try another password.'
                    break
                default:
                    break
                }
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${message}` }))
            })
    }
}

export const generatePasswordResetLink = (fireauth, email, formId) => {
    return dispatch => {
        sendPasswordResetEmail(fireauth, email)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${err.message}` }))
            })
    }
}

export const resetPasswordWithCode = (auth, code, email, password, formId) => {
    return dispatch => {
        return confirmPasswordReset(auth, code, password)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
                    })
                    .catch(err => {
                        console.log('in here with an error?', err)
                    })
            })
            .catch(err => {
                console.log('in here?', err)
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${err.message}` }))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.password !== formData.confirmPassword) {
            dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Passwords Don't Match` }))
            return false
        }
        const user = fireauth.currentUser
        const credential = EmailAuthProvider.credential(user.email, formData.existingPassword)
        return reauthenticateWithCredential(user, credential)
            .then(() => {
                updatePassword(user, formData.password)
                    .then(() => {
                        dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
                    })
                    .catch(err => {
                        dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${err.message}` }))
                    })
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `${err.message}` }))
            })
    }
}

export const handleFirebaseAuthCode = (auth, code, action) => {
    return dispatch => {
        verifyPasswordResetCode(auth, code)
            .then(email => {
                dispatch(saveAuthHandler({ code, action, email, isAnonymous: false }))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleSignOut = (fireauth) => {
    return dispatch => {
        signOut(fireauth)
            .then(() => {
                console.log('signed out')
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}

// export const updateAuthPassword = (fireauth, formData, formId) => {
//     return dispatch => {
//         if (formData.newPassword !== formData.confirmPassword) {
//             dispatch(upsertForm(formId, formStates.ERROR, 'New passwords do not match.'))
//             return false
//         }
//         const fireUser = fireauth.currentUser
//         const credentials = fireauth.EmailAuthProvider.credential(fireUser.email, formData.existingPassword)
//         return fireUser.reauthenticateWithCredential(credentials)
//             .then(() => {
//                 fireUser.updatePassword(formData.newPassword)
//                     .then(() => {
//                         dispatch(upsertForm(formId, formStates.SUCCESS))
//                     })
//                     .catch(err => {
//                         dispatch(upsertForm(formId, formStates.ERROR, `${err.message}`))
//                     })
//             })
//             .catch(err => {
//                 dispatch(upsertForm(formId, formStates.ERROR, `${err.message}`))
//             })
//     }
// }
