import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint, routes, buildRoutePath } from '../../../../constants/routes'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AddAllergens from './add-allergens'
import AddDietary from './add-dietary'
import AdminFoodPhotosList from './add-photos'

const mapStateToProps = (state, ownProps) => {
    return {
        foodItem: state.adminFoodItems.find(fi => fi.id === ownProps.params.foodItemId)
    }
}

const FoodItemsView = ({ dispatch, foodItem, navigate, allergens, restrictions, foodPhotos, result, params }) => {
    const ownerOptions = [{ label: 'Tivity', value: 'Tivity' }, { label: 'Chef', value: 'Chef' }]

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/food-items', 'VIEW', params.foodItemId)))
    }, [dispatch, params.foodItemId])

    const handleDelete = () => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${foodItem.title}`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/food-items', 'DELETE', foodItem.id), {}, -1))
            navigate(buildRoutePath(routes.adminFoodItemsIndex.path))
        }
    }

    if (!foodItem) {
        return <div>Loading...</div>
    }
    return (
        <>
            <div className="event-block">
                <div className="content">
                    <h2>{foodItem.title}</h2>
                    <div>
                        <EditableDisplayField
                            label="Title"
                            display={foodItem.title}
                            dataObj={foodItem}
                            endpoint="admin/food-items"
                            extraClass="display-value one-third"
                            inline={true}
                            fields={[
                                {
                                    type: 'text',
                                    field: 'title'
                                }
                            ]} />
                        <EditableDisplayField
                            label="Description"
                            display={foodItem.description}
                            dataObj={foodItem}
                            endpoint="admin/food-items"
                            extraClass="display-value one-third"
                            inline={true}
                            fields={[
                                {
                                    type: 'textarea',
                                    field: 'description'
                                }
                            ]} />
                        <EditableDisplayField
                            label="Owner"
                            display={foodItem.owner}
                            dataObj={foodItem}
                            endpoint="admin/food-items"
                            extraClass="display-value one-third"
                            inline={true}
                            fields={[
                                {
                                    type: 'valueSelect',
                                    field: 'owner',
                                    options: ownerOptions
                                }
                            ]} />
                    </div>
                </div>
                <span className="actions" title="Delete" onClick={() => handleDelete(foodItem)}>
                    <FontAwesomeIcon icon={['far', 'trash-can']} />
                </span>
            </div>
            <AddAllergens foodItem={foodItem} allergens={allergens} />
            <AddDietary foodItem={foodItem} restrictions={restrictions} />
            <AdminFoodPhotosList foodItem={foodItem} foodPhotos={foodPhotos} result={result} />
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(FoodItemsView))
