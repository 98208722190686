import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { buildRoutePath, apiResourceEndpoint } from '../../../../constants/routes'
import { callApi } from '../../../../actions/api'

import { TICKET_CONFIG } from '../../../../constants/ticket-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { openDrawer } from '../../../../reducers/ui-drawer'


const TicketBlock = ({ dispatch, ticket, config, routeObj, isDeletable, children }) => {
    const [ isExpanded, setIsExpanded ] = useState(false)
    const [ showMenu, setShowMenu ] = useState(false)
    const menuRef = useRef(null)
    const localConfig = TICKET_CONFIG[config]

    useEffect(() => {
        const handleOutsideBlockClick = (evt) => {
            if (menuRef.current && !menuRef.current.contains(evt.target)) {
                setShowMenu(false)
            }
        }
        document.addEventListener('click', handleOutsideBlockClick, true)
        return () => {
            document.removeEventListener('click', handleOutsideBlockClick, true)
        }
    }, [])

    const handleMenuClick = () => {
        setShowMenu(!showMenu)
    }

    const handleDelete = () => {
        dispatch(callApi(apiResourceEndpoint(`admin/${localConfig.path}`, 'DELETE', ticket.id)))
    }

    return (
        <div className="event-block">
            <div className="block-type">
                <Link to={buildRoutePath(routeObj.path, { ticketId: ticket.id })}>
                    <FontAwesomeIcon icon={localConfig.icon} /> {localConfig.label}
                </Link>
            </div>
            <div className="content">
                <div className={`displays ${isExpanded ? 'expanded' : 'compressed'}`}>
                    {children}
                </div>
            </div>
            <div className="actions">
                <span className="lead-action" title="Menu" onClick={handleMenuClick} ref={menuRef}>
                    <FontAwesomeIcon icon={`ellipsis-v`} />
                    {showMenu && (
                        <div className="ticket-menu">
                            { isDeletable &&
                                <span onClick={() => handleDelete()}>
                                    <FontAwesomeIcon icon={['far', 'trash-can']} /> Delete Section
                                </span>
                            }
                        </div>
                    )}
                </span>
                <span onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: config }))}>
                    <FontAwesomeIcon icon={['far', 'sticky-note']} />
                </span>
                <span onClick={() => setIsExpanded(!isExpanded)} title="Expand/Collapse">
                    <FontAwesomeIcon icon={isExpanded ? 'compress' : 'expand'} />
                </span>
            </div>
        </div>
    )
}

export default connect()(TicketBlock)
