import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { fetchPublicApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import TicketTitle from '../parts/title'
import TicketOverview from '../parts/overview'
import TicketTimeline from '../parts/timeline'
import TicketInventory from '../parts/inventory'
import TicketBudget from '../parts/budget'
import TicketMenu from '../parts/menu'
import TicketGrocery from '../parts/grocery'
import TicketServers from '../parts/servers'
import TicketSousChef from '../parts/sous-chef'
import TicketTravel from '../parts/travel'
// import TicketLength from '../parts/event-length'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.sharedCode === ownProps.params.ticketCode)
    }
}

const ChefTicketPreviews = ({ dispatch, params, ticket }) => {
    useEffect(() => {
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('shared/chefs/ticket-preview', 'VIEW', params.ticketCode)))
    }, [dispatch, params.ticketCode])

    if (!ticket) {
        return <div>Loading ticket...</div>
    }

    return (
        <>
            <TicketTitle ticket={ticket} />
            <TicketOverview ticket={ticket} />
            <TicketTimeline ticket={ticket} />
            <TicketMenu ticket={ticket} />
            <TicketInventory ticket={ticket} />
            <TicketGrocery ticket={ticket} />
            <TicketSousChef ticket={ticket} />
            <TicketServers ticket={ticket} />
            <TicketTravel ticket={ticket} />
            <TicketBudget ticket={ticket} />
            {/* <TicketLength ticket={ticket} /> */}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ChefTicketPreviews))
