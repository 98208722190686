import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'

const CreateFoodItem = ({ dispatch, navigate }) => {
    const ownerOptions = [{ label: 'Tivity', value: 'Tivity' }, { label: 'Chef', value: 'Chef' }]

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/food-items', 'CREATE'), formData, formId))
    }

    const successFX = (foodItemId) => {
        navigate(buildRoutePath(routes.adminFoodItemsView.path, { foodItemId }))
    }

    return (
        <div className="box">
            <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => navigate(routes.adminFoodItemsIndex.path)}>
                <InputElement
                    id="title"
                    label="Title"
                    type="text"
                    extraClass="flex-33"
                    required />
                <InputElement
                    id="description"
                    label="Description"
                    type="text"
                    extraClass="flex-33"
                />
                <InputElement
                    id="owner"
                    label="Owner"
                    type="valueSelect"
                    options={ownerOptions}
                    default={'TIVITY'}
                    extraClass="flex-33"
                    required
                />
            </SHSForm>
        </div>
    )
}

export default withRouterProps(connect()(CreateFoodItem))
