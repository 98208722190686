import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import '../../assets/scss/search-complete.scss'
import { fetchApiDataIfNeeded } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

const SearchComplete = ({ dispatch, searchEndpoint, renderTemplate, onHandleSelect, placeholder, allowCreation, onCreationFx }) => {
    const [ query, setQuery] = useState('')
    const [ searchResults, setSearchResults ] = useState([])

    const triggerSearch = async (queryValue) => {
        if (queryValue.length > 2) {
            const results = await dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(searchEndpoint, 'LIST'), { search: queryValue }, false, true))
            setSearchResults(results)
        }
    }

    const debounceFn = useMemo(() => debounce(triggerSearch, 500), [])

    const handleChange = (evt) => {
        setQuery(evt.target.value)
        setSearchResults([])
        debounceFn(evt.target.value)
    }

    const handleKeyDown = (evt) => {

    }

    const handleSelect = (result) => {
        onHandleSelect(result)
        setQuery('')
        setSearchResults([])
    }

    const handleCreation = () => {
        onCreationFx(query)
        setQuery('')
        setSearchResults([])
    }

    const renderResults = () => {
        if (query === '') {
            return null
        }
        let allResults = []
        searchResults.forEach(result => {
            allResults.push(
                <div key={result.id} className="result-item" onClick={() => handleSelect(result)}>
                    {renderTemplate(result)}
                </div>
            )
        })
        if (allowCreation) {
            allResults.push(
                <div key="new-item" className="result-item new" onClick={handleCreation}>
                    Create <strong>{query}</strong>
                </div>
            )
        }
        if (allResults.length < 0) {
            return null
        }
        return (
            <div className="results-list">
                {allResults}
            </div>
        )
    }

    return (
        <div className="search-complete-wrapper">
            <input
                type="text"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={query}
                placeholder={placeholder || 'Search'} />
            {renderResults()}
        </div>
    )
}

export default connect()(SearchComplete)
