import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { signInWithEmailAndPass } from '../../../actions/auth'
import { routes } from '../../../constants/routes'
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'


const Login = ({ dispatch, fireauth }) => {
    const submitFX = (formData, formId) => {
        dispatch(signInWithEmailAndPass(fireauth, formData.email, formData.password, formId))
    }

    const successFX = () => {
        // nothing for now
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <h1>LOGIN</h1>
                        <p>
                            Access to Tivity
                        </p>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX}>
                        <InputElement
                            type="email"
                            label="Email Address"
                            id="email"
                            placeholder="user@example.com"
                            required />
                        <InputElement
                            type="password"
                            label="Password"
                            id="password"
                            required />
                    </SHSForm>
                </div>
                <div className="mt-3">
                    <p>
                        <Link to={routes.authPasswordHelp.path} className="m-txt">
                                Forgot Your Password?
                        </Link>
                    </p>
                </div>
            </div>
        </>
    )
}

export default connect()(Login)
