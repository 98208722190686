import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'
import TicketHeader from '../parts/ticket-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'
import EditableDisplayField from '../../../utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        venueMeta: state.ticketVenueMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        venues: state.ticketVenues.filter(tg => tg.ticketId === Number(ownProps.params.ticketId))
    }
}

const TicketLocations = ({ dispatch, params, ticket, venueMeta, venues }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="map-marker-alt" /> <span>Venues</span>
                </div>
                <div className="meta-content">
                    <span className="meta-value">{venueMeta.tickedId} Coming soon...</span>
                    <span className="icon-btn"><FontAwesomeIcon icon="pen" /></span>
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'VENUES' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderMetaNotes = () => {
        return (
            <div className="meta-manager">
                <EditableDisplayField
                    label="Notes"
                    display={venueMeta.notes}
                    dataObj={venueMeta}
                    endpoint="admin/ticket-venue-meta"
                    extraClass="field name"
                    inTable={true}
                    fields={[{
                        type: 'textarea',
                        field: 'notes'
                    }]} />
            </div>
        )
    }

    const renderVenues = () => {
        let allVenues = []
        venues.forEach(venue => {
            allVenues.push(
                <div key={venue.id}>Venue Block??</div>
            )
        })
        return (
            <div className="category-blocks">
                {allVenues}
                <div className="btn white bordered centered">Add Venue +</div>
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderMetaNotes()}
            {renderVenues()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketLocations))
