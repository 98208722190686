import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { callApi } from '../../actions/api'
import { apiResourceEndpoint, routes } from '../../constants/routes'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import * as MASKS from '../utils/input-masks'


const CreateUser = (props) => {
    const { dispatch } = props
    const [ shouldNavigate, setShouldNavigate ] = useState(false)

    const submitFX = (formData, formId) => {
        formData.userType = 'chef'
        dispatch(callApi(apiResourceEndpoint('users', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        setShouldNavigate(true)
    }

    if (shouldNavigate) {
        return <Navigate to={routes.router.path} />
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <span className="logo-container">
                            <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" />
                            <h1>register</h1>
                        </span>
                        <p>
                            Let's get started! Please provide some basic information about youself.
                        </p>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX}>
                        <InputElement
                            type="text"
                            label="First Name"
                            id="firstName"
                            placeholder="John"
                            required />
                        <InputElement
                            type="text"
                            label="Last Name"
                            id="lastName"
                            placeholder="Doe"
                            required />
                        <InputElement
                            type="text"
                            label="Phone"
                            id="phone"
                            formatMask={MASKS.phoneMask}
                            isFormatted={true}
                            placeholder="(123) 456-7890" />
                        <InputElement
                            type="text"
                            label="Zipcode"
                            id="postal"
                            formatMask={MASKS.postalMask}
                            isFormatted={true}
                            placeholder="12345" />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default connect()(CreateUser)
