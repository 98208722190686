import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import { formatTime } from '../../../utils/formatters'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        timelines: state.ticketTimelines
            .filter(ti => ti.ticketId === Number(ownProps.params.ticketId))
            .sort((a, b) => {
                if (a.time > b.time) {
                    return 1
                }
                return -1
            })
    }
}

const TicketTimeline = ({ dispatch, params, ticket, timelines }) => {
    const [showForm, setShowForm] = useState(false)
    const partyOptions = ['TBD', 'Client', 'Chef', 'Tivity', 'Other', 'N/A']

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-timeline', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const handleDelete = (timeline) => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${timeline.item}?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-timeline', 'DELETE', timeline.id), {}, -1))
        }
    }

    const renderTimeline = () => {
        let allTimelines = []
        timelines.forEach(timeline => {
            allTimelines.push(
                <div key={timeline.id} className="item">
                    <EditableDisplayField
                        label="Event"
                        display={timeline.event}
                        dataObj={timeline}
                        endpoint="admin/ticket-timeline"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'event'
                        }]} />
                    <EditableDisplayField
                        label="Time"
                        display={formatTime(timeline.time)}
                        dataObj={timeline}
                        endpoint="admin/ticket-timeline"
                        extraClass="field time"
                        inTable={true}
                        fields={[{
                            type: 'time',
                            field: 'time'
                        }]} />
                    <EditableDisplayField
                        label="Scope"
                        display={timeline.scope}
                        dataObj={timeline}
                        endpoint="admin/ticket-timeline"
                        extraClass="field party"
                        inTable={true}
                        fields={[{
                            type: 'select',
                            field: 'scope',
                            options: partyOptions
                        }]} />
                    <EditableDisplayField
                        label="Notes"
                        display={timeline.notes}
                        dataObj={timeline}
                        endpoint="admin/ticket-timeline"
                        extraClass="field notes"
                        inTable={true}
                        fields={[{
                            type: 'textarea',
                            field: 'notes'
                        }]} />
                    <span className="remove" title="Delete" onClick={() => handleDelete(timeline)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return <div className="ticket-items">{allTimelines}</div>
    }

    const submitFX = (formData, formId) => {
        formData.ticketId = params.ticketId
        dispatch(callApi(apiResourceEndpoint('admin/ticket-timeline', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)} extraClass="field">
                        <InputElement
                            id="event"
                            label="Event"
                            type="text"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="time"
                            label="Time"
                            type="time"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="scope"
                            label="Scope"
                            type="select"
                            extraClass="flex-33"
                            options={partyOptions}
                            required />
                        <InputElement
                            id="notes"
                            label="Notes"
                            type="textarea" />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Event +
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderTimeline()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketTimeline))
