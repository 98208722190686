import React from 'react'

import EditableContent from '../../../../utils/editable-content'

const SectionTitle = ({ section }) => {
    return (
        <div className="section-title-block">
            <EditableContent
                dataObj={section}
                field="title"
                endpoint="chefs/menu-sections"
                className="section-title"
                placeholder="Add Section Title" />
            <EditableContent
                dataObj={section}
                field="description"
                endpoint="chefs/menu-sections"
                className="section-description"
                placeholder="Add Section Description" />
        </div>
    )
}

export default SectionTitle
