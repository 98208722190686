import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

const AdminNav = (props) => {
    return (
        <div id="admin-nav">
            <div className="logo">
                <Link to={routes.publicIndex.path}>
                    <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" height="90" />
                </Link>
            </div>
            <div className="admin-menu">
                <Link to={routes.adminLeadsIndex.path}>Leads</Link>
                <Link to={routes.adminTicketsIndex.path}>Tickets</Link>
                <Link to={routes.adminChefsIndex.path}>Chefs</Link>
                <Link to={routes.adminMenusIndex.path}>Menus</Link>
                <Link to={routes.adminPhotosIndex.path}>Photos</Link>
                <Link to={routes.adminSettingsIndex.path}>Settings</Link>
                <Link to={routes.appIndex.path}>App</Link>
            </div>
        </div>
    )
}

export default AdminNav
