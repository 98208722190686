import React from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'
import { withRouterProps } from '../../utils/with-router-props'

import CreateChef from './create'
import ViewChef from './view'
import ListChefs from './list'

import '../../../assets/scss/chefs.scss'

const ChefsIndex = () => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.adminChefsIndex.path, routes.adminChefsCreate.path)} element={<CreateChef />} />
            <Route path={nestedRoutePath(routes.adminChefsIndex.path, routes.adminChefsView.path, true)} element={<ViewChef />} />
            <Route index element={<ListChefs />} />
        </Routes>
    )
}

export default withRouterProps(connect()(ChefsIndex))
