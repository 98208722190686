import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        serverMeta: state.ticketServerMeta.find(tsm => tsm.ticketId === ownProps.ticket.id)
    }
}

const ServerBlock = ({ ticket, serverMeta }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketServer} config="SERVERS" ticket={ticket} isDeletable={true}>
            <DisplayValue label="Notes" value={serverMeta.notes ? serverMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(ServerBlock)
