import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaginationWrapper } from 'shs-pagination'

import * as MASKS from '../../utils/input-masks'

const mapStateToProps = (state) => {
    const paginator = state.paginator.adminChefs
    let chefs = state.adminChefs
    let profilePhotos = state.adminChefPhotos.filter(ap => ap.isProfile === true)
    if (paginator) {
        chefs = chefs
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
        profilePhotos = profilePhotos
            .filter(pp => paginator.itemIds.indexOf(pp.chefId) !== -1)
    }

    return {
        chefs,
        paginator,
        profilePhotos
    }
}

const ListChefs = ({ dispatch, chefs, paginator, profilePhotos }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chefs', 'LIST')))
    }, [dispatch])

    const renderChefRow = (chef, index) => {
        let profilePhoto = profilePhotos.find(pp => pp.chefId === chef.id)
        return (
            <div key={chef.id} className="paginated-item">
                <span className="index">{index}.</span>
                <div className="thumbnail">
                    { profilePhoto &&
                        <img
                            src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${profilePhoto.photoId}`, 'LIST').url + '?style=thumbnail'}
                            alt={profilePhoto.overrideAlt ? profilePhoto.overrideAlt : profilePhoto._computed.photoAlt} />
                    }
                </div>
                <Link to={buildRoutePath(routes.adminChefsView.path, { chefId: chef.id })} className="main">
                    {chef.displayName}
                </Link>
                <span className="flex-20">{chef.email}</span>
                <span className="flex-20">{MASKS.phoneMask.resolve(chef.phone || '')}</span>
            </div>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chefs', 'LIST'), paginationParams))
    }

    const renderChefs = () => {
        if (!paginator) {
            return null
        }
        let allChefs = []
        chefs.forEach((chef, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allChefs.push(renderChefRow(chef, index))
        })

        const controls = {
            search: {
                active: true,
                collapsed: true
            }
        }

        return (
            <PaginationWrapper
                paginator={paginator}
                data={chefs}
                controls={controls}
                callbackFX={handlePaginationUpdate}
                renderTemplate={renderChefRow}
                countOptions={[2, 5, 10, 20]}>
                {allChefs}
            </PaginationWrapper>
        )
    }

    const renderHeader = () => {
        return (
            <div className="section-header">
                <span className="title">All Chefs</span>
                <div className="pagination-controls">
                    <Link className="controls" to={routes.adminChefsCreate.path} title="Add Chef">
                        <FontAwesomeIcon icon="plus" />
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderHeader()}
            {renderChefs()}
        </>
    )
}

export default connect(mapStateToProps)(ListChefs)
