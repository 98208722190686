import React from 'react'
import PublicNav from '../layout/public-nav'

const AppIndex = (props) => {
    return (
        <>
            <PublicNav />
            <div>I am the app index</div>
        </>
    )
}

export default AppIndex
