import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        sousChefMeta: state.ticketSousChefMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const SousChefBlock = ({ ticket, sousChefMeta }) => {
    if (!sousChefMeta) {
        return null
    }
    return (
        <TicketBlock routeObj={routes.adminTicketSousChef} config="SOUS_CHEF" ticket={ticket} isDeletable={true}>
            <DisplayValue label="Notes" value={sousChefMeta.notes ? sousChefMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(SousChefBlock)
