import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import ChefProfileView from './view'


const ChefProfileIndex = ({ chef }) => {
    return (
        <>
            <Routes>
                <Route index element={<ChefProfileView chef={chef} />} />
            </Routes>
        </>
    )
}

export default withRouterProps(connect()(ChefProfileIndex))
