import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'

import sanitizeHtml from 'sanitize-html'
import ContentEditable from 'react-contenteditable'

import { callApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

import { get } from 'lodash'

import '../../assets/scss/editable-content.scss'

const EditableContent = ({ dispatch, dataObj, field, display, placeholder, endpoint, primaryKey, className, title }) => {
    const activeContent = useRef(display ? display : get(dataObj, field) || '')
    const [tempValue, setTempValue] = useState(display ? display : get(dataObj, field) || '')

    const sanitizeConf = {
        allowedTags: ['b', 'i', 'a', 'u'],
        allowedAttributes: { a: ['href'] }
    }

    const handleChange = (evt) => {
        const sanitizedContent = sanitizeHtml(evt.target.value, sanitizeConf)
        activeContent.current = sanitizedContent
        setTempValue(sanitizedContent)
    }

    const handleBlur = (evt) => {
        if (get(dataObj, field) !== '' || (get(dataObj, field) === '' && tempValue !== '')) {
            const formData = { [field]: activeContent.current }
            dispatch(callApi(apiResourceEndpoint(endpoint, 'UPDATE', primaryKey ? dataObj.primaryKey : dataObj.id), formData, -1))
        }
    }

    return (
        <div className={`editable-content-wrapper ${className ? className : ''}`} title={title || ''}>
            <ContentEditable
                className={`editable ${activeContent.current !== '' ? 'has-content' : ''}`}
                onChange={handleChange}
                onBlur={handleBlur}
                data-placeholder={placeholder ? placeholder : 'Enter Text'}
                html={activeContent.current} />
        </div>
    )
}

export default connect()(EditableContent)
