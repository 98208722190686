import React from 'react'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const BudgetBlock = ({ ticket }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketCost} config="COST" ticket={ticket}>
            <DisplayValue label="Budget Count" value={`${ticket._computed.costCount} Item${ticket._computed.costCount === 1 ? '' : 's'}`} />
        </TicketBlock>
    )
}

export default BudgetBlock
