import React from 'react'

import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'

class Home extends React.Component {
    state = {}

    render = () => {
        return (
            <>
                <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
                <div className="form-holder">
                    <div className="event-form">
                        <div className="form-header">
                            <h1>Welcome to Tivity</h1>
                            <p>
                                <Link to={routes.authLogin.path}>Login</Link> or <Link to={routes.authRegister.path}>Register</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Home
