import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import { closeDrawer } from '../../../reducers/ui-drawer'

import { startCase } from 'lodash'

import '../../../assets/scss/drawer.scss'

const mapStateToProps = (state) => {
    const uiDrawer = state.uiDrawer
    let notes = []
    if (uiDrawer.ticketId) {
        notes = state.ticketNotes
            .filter(tn => tn.ticketId === uiDrawer.ticketId)
            .sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1
                }
                return 1
            })
    }
    return { uiDrawer, notes }
}

const AdminDrawer = ({ dispatch, endpoint, uiDrawer, notes }) => {
    const [shadowContent, setShadowContent ] = useState('')
    const [ rawContent, setRawContent ] = useState('')

    useEffect(() => {
        if (uiDrawer.ticketId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-notes', 'LIST'), { filter: [`ticket_id|${uiDrawer.ticketId}`] }))
        }
    }, [dispatch, uiDrawer.ticketId])

    const handleFocus = (evt) => {
        setRawContent(rawContent)
    }

    const handleBlur = (evt) => {
        setRawContent(evt.target.innerText.trim())
    }

    const handleClose = () => {
        setShadowContent(null)
        dispatch(closeDrawer())
    }

    const handleChange = (evt) => {
        let text = evt.target.innerText.trim().split(' ')
        if (text.length === 1 && text[0].trim() === '') {
            setShadowContent(null)
        } else {
            let allText = []
            text.forEach((t, idx) => {
                if (idx % 2 === 0) {
                    allText.push(<span key={idx} className="blue">{t} </span>)
                } else {
                    allText.push(<span key={idx} className="red">{t} </span>)
                }
            })
            if (allText.length > 0) {
                setShadowContent(<>{allText}</>)
            } else {
                setShadowContent(null)
            }
        }
    }

    const sendMessage = () => {
        const payload = {
            textNote: rawContent,
            ticketId: uiDrawer.ticketId,
            category: uiDrawer.category
        }
        dispatch(callApi(apiResourceEndpoint(`admin/${endpoint}`, 'CREATE'), payload, -1))
        setShadowContent('')
        setRawContent('')
    }

    const renderMessages = () => {
        let allMessages = []
        let ticketNotes = notes
        if (uiDrawer.category !== 'GENERAL') {
            ticketNotes = notes.filter(n => n.category.indexOf(uiDrawer.category) !== -1)
        }
        ticketNotes.forEach(note => {
            allMessages.push(
                <div className="message" key={note.id}>
                    <div className="sender">
                        <div className="initials">{note._computed.authorFirstName.substring(0, 1)}{note._computed.authorLastName.substring(0, 1)}</div>
                        <div className="name">{note._computed.authorFirstName}</div>
                    </div>
                    <div className="note">
                        {note.textNote}
                    </div>
                    <div className="date">
                        {moment(note.createdAt).format('MMMM D h:ma')}
                    </div>
                    <div className="category">
                        #{note.category}
                    </div>
                </div>
            )
        })
        return <div className="messages">{allMessages}</div>
    }

    return (
        <div id="admin-drawer">
            <span className="closer" onClick={handleClose}>
                <FontAwesomeIcon icon="times" />
            </span>
            <span className="notes-title">{startCase(uiDrawer.category?.toLowerCase())} Notes</span>
            <div className="messages-wrapper">
                {renderMessages()}
            </div>
            <div className="input-wrapper">
                <div className={`wysiwyg ${shadowContent ? 'has-content' : ''}`}>
                    <div className="shadow-content">{shadowContent}</div>
                    <div
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onInput={handleChange}
                        className="input-area"
                        contentEditable="true"
                        suppressContentEditableWarning >{rawContent}</div>
                </div>
                <div className="submit-area">
                    <span className="instruction">
                        @mention #category
                    </span>
                    <span className="submit-btn" onClick={sendMessage}>
                        <FontAwesomeIcon icon="paper-plane" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminDrawer))
