import { createSlice } from '@reduxjs/toolkit'

// import * as helpers from './helpers'

const INITIAL_STATE = {
    isOpen: false
}

const uiDrawerSlice = createSlice({
    name: 'ui',
    initialState: INITIAL_STATE,
    reducers: {
        openDrawer(state, action) {
            state.isOpen = true
            state.ticketId = action.payload?.ticketId || null
            state.category = action.payload?.category || 'GENERAL'
            state.objId = action.payload?.objId || null
        },
        closeDrawer(state, action) {
            state.isOpen = false
        }
    }
})

export const { openDrawer, closeDrawer } = uiDrawerSlice.actions
export default uiDrawerSlice.reducer
