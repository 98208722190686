import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../constants/routes'

import ChefTicketPreview from './chefs/ticket-preview'
import ClientTicketPreview from './clients/ticket-preview'

import '../../assets/scss/shared.scss'

const SharedIndex = (props) => {
    return (
        <div id="shared-container" className="container">
            <div className="tivity-header">
                <Link to={routes.publicIndex.path}>
                    <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" height="90" />
                </Link>
            </div>
            <div className="shared-body">
                <Routes>
                    <Route path={nestedRoutePath(routes.sharedIndex.path, routes.sharedChefTicketPreview.path)} element={<ChefTicketPreview />} />
                </Routes>
                <Routes>
                    <Route path={nestedRoutePath(routes.sharedIndex.path, routes.sharedClientTicketPreview.path)} element={<ClientTicketPreview />} />
                </Routes>
            </div>
            <div className="tivity-footer mt-5">
                thetivity.com
            </div>
        </div>
    )
}

export default SharedIndex
