import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../../utils/with-router-props'

import moment from 'moment'
import InputElement from '../../../utils/input'
import SHSForm from '../../../utils/shs-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'

const LeadHeader = (props) => {
    const { dispatch, navigate, leadForm } = props
    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/tickets', 'CREATE', null, 'from-lead'), formData, formId))
    }

    const successFX = (ticketId) => {
        navigate(buildRoutePath(routes.adminTicketsView.path, { ticketId }))
    }

    return (
        <div className="lead-header">
            <div className="info">
                <h1>
                    {leadForm.eventName}
                </h1>
                <span className="client">
                    {`${leadForm.firstName} ${leadForm.lastName}`}
                </span>
                <span className="date">
                    {moment(leadForm.eventDate).format('MMMM, D y')}
                </span>
                <span className="status">{leadForm._computed.statusDisplay}</span>
            </div>
            <div className="actions">
                { leadForm._computed.ticketId
                    ? <Link to={buildRoutePath(routes.adminTicketsView.path, { ticketId: leadForm._computed.ticketId })}>View Ticket {leadForm._computed.ticketId}</Link>
                    : <>
                        <span className="lead-action" title="Archive Lead">
                            <FontAwesomeIcon icon="archive" />
                        </span>
                        <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Convert to Ticket">
                            <InputElement
                                type="hidden"
                                id="leadFormId"
                                default={leadForm.id}
                                extraClass="standalone"
                                label={null} />
                        </SHSForm>
                    </>
                }
            </div>
        </div>
    )
}

export default withRouterProps(connect()(LeadHeader))
