import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, callFileApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import Modal from '../../../utils/modal'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

import SearchComplete from '../../../utils/search-complete'

import '../../../../assets/scss/photos.scss'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        photos: state.adminChefPhotos.filter(cp => cp.chefId === ownProps.chef.id)
    }
}

const AdminChefPhotosList = ({ dispatch, chef, photos }) => {
    const [showAdd, setShowAdd] = useState(false)
    const [activePhotoId, setActivePhotoId] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const showPhoto = (photoId) => {
        setActivePhotoId(photoId)
        setShowModal(true)
    }

    const renderModalContents = () => {
        return (
            <img
                src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${activePhotoId}`, 'LIST').url + '?style=large'}
                alt="large version" />
        )
    }

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chef-photos', 'LIST'), { filter: [`chef_id|${chef.id}`] }))
    }, [dispatch, chef.id])

    const handleDelete = (photo) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this photo?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/chef-photos', 'DELETE', photo.id), {}, -1))
        }
    }

    const renderPhotos = () => {
        let allPhotos = []
        photos.forEach(photo => {
            allPhotos.push(
                <div key={photo.id} className="photo-wrapper">
                    <div className="thumbnail" onClick={() => showPhoto(photo.photoId)}>
                        <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.photoId}`, 'LIST').url + '?style=thumbnail'} alt={photo.overrideAlt ? photo.overrideAlt : photo._computed.photoAlt} />
                    </div>
                    <EditableDisplayField
                        label="Profile Photo"
                        display={photo.isProfile ? 'Yes' : 'No'}
                        dataObj={photo}
                        endpoint="admin/chef-photos"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'isProfile',
                            options: [{ value: true, label: 'Yes' }, { value: '0', label: 'No' }]
                        }]} />
                    <EditableDisplayField
                        label="Alt Text"
                        display={photo.overrideAlt ? photo.overrideAlt : photo._computed.photoAlt}
                        dataObj={photo}
                        endpoint="admin/chef-photos"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'overrideAlt',
                            placeholder: 'Override alt text'
                        }]} />
                    <EditableDisplayField
                        label="Caption"
                        display={photo.overrideCaption ? photo.overrideCaption : photo._computed.photoCaption}
                        dataObj={photo}
                        endpoint="admin/chef-photos"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'overrideCaption',
                            placeholder: 'Override caption'
                        }]} />
                    <span className="actions" title="Delete" onClick={() => handleDelete(photo)}>
                        <FontAwesomeIcon icon={['far', 'trash-can']} />
                    </span>
                </div>
            )
        })
        if (allPhotos.length === 0) {
            return <div>No photos yet.</div>
        }
        return (
            <div className="photo-rows">
                {allPhotos}
            </div>
        )
    }

    const submitFX = (formData, formId) => {
        dispatch(callFileApi(apiResourceEndpoint('admin/chef-photos', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        setShowAdd(false)
    }

    const renderPhotoResult = (result) => {
        return (
            <>{result.name}</>
        )
    }

    const handleSelectResult = (result) => {
        const formData = {
            photoId: result.id,
            chefId: chef.id,
            order: photos.length
        }
        dispatch(callApi(apiResourceEndpoint('admin/chef-photos', 'CREATE', null, 'attach'), formData, -1))
        setShowAdd(false)
    }

    const renderAddPhoto = () => {
        if (showAdd) {
            return (
                <div>
                    <SearchComplete
                        placeholder="Search for a Photo"
                        searchEndpoint="admin/photos"
                        renderTemplate={renderPhotoResult}
                        onHandleSelect={handleSelectResult}
                        allowCreation={false} />
                    <p>Or upload a new photo...</p>
                    <SHSForm submitFX={submitFX} successFX={successFX} useFormData={true} showCancel={true} cancelAction={() => setShowAdd(false)}>
                        <InputElement
                            type="file"
                            id="file"
                            label="Image File"
                            required />
                        <InputElement
                            type="hidden"
                            id="chefId"
                            default={chef.id}
                            label={null}
                            extraClass="d-none" />
                        <InputElement
                            type="hidden"
                            id="order"
                            default={photos.length}
                            label={null}
                            extraClass="d-none" />
                    </SHSForm>
                </div>
            )
        }
        return null
    }

    return (
        <>
            <div className="box mb-5">
                {renderPhotos()}
            </div>
            <div className="box">
                { !showAdd &&
                    <span className="clickable" onClick={() => setShowAdd(true)}>+ Add Photo</span>
                }
                {renderAddPhoto()}
            </div>
            { showModal &&
                <Modal closeAction={() => setShowModal(false)}>
                    {renderModalContents()}
                </Modal>
            }
        </>
    )
}

export default connect(mapStateToProps)(AdminChefPhotosList)
