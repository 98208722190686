import apiCacheReducer from './api-cache'
import authReducer from './auth'
import authCodeReducer from './auth-code'
import formReducer from './form'
import genericSlice from './generic-slice'
import pagerReducer from './pager'
import uiDrawerReducer from './ui-drawer'


const indexReducer = {
    adminAllergens: genericSlice('ADMIN_ALLERGENS'),
    adminChefs: genericSlice('ADMIN_CHEFS'),
    adminChefContacts: genericSlice('ADMIN_CHEF_CONTACTS'),
    adminChefDetails: genericSlice('ADMIN_CHEF_DETAILS'),
    adminChefPhotos: genericSlice('ADMIN_CHEF_PHOTOS'),
    adminChefServiceAreas: genericSlice('ADMIN_CHEF_SERVICE_AREAS'),
    adminChefSocials: genericSlice('ADMIN_CHEF_SOCIALS'),
    adminFoodItems: genericSlice('ADMIN_FOOD_ITEMS'),
    adminFoodItemPhotos: genericSlice('ADMIN_FOOD_ITEM_PHOTOS'),
    adminMenus: genericSlice('ADMIN_MENUS'),
    adminMenuItems: genericSlice('ADMIN_MENU_ITEMS'),
    adminMenuSections: genericSlice('ADMIN_MENU_SECTIONS'),
    adminPhotos: genericSlice('ADMIN_PHOTOS'),
    apiCaches: apiCacheReducer,
    apiCuisines: genericSlice('API_CUISINES'),
    apiServiceStyles: genericSlice('API_SERVICE_STYLES'),
    apiVibes: genericSlice('API_VIBES'),
    auth: authReducer,
    authCode: authCodeReducer,
    chefs: genericSlice('CHEFS'),
    chefDetails: genericSlice('CHEF_DETAILS'),
    chefPhotos: genericSlice('CHEF_PHOTOS'),
    chefServiceAreas: genericSlice('CHEF_SERVICE_AREAS'),
    chefSocials: genericSlice('CHEF_SOCIALS'),
    chefMenus: genericSlice('MENUS'),
    chefMenuItems: genericSlice('MENU_ITEMS'),
    chefMenuSections: genericSlice('MENU_SECTIONS'),
    chefFoodItems: genericSlice('FOOD_ITEMS'),
    chefFoodItemPhotos: genericSlice('FOOD_PHOTOS'),
    chefAllergens: genericSlice('ALLERGENS'),
    chefDietaryRestrictions: genericSlice('DIETARY_RESTRICTIONS'),
    configs: genericSlice('CONFIGS'),
    adminDietaryRestrictions: genericSlice('ADMIN_DIETARY_RESTRICTIONS'),
    forms: formReducer,
    leadForms: genericSlice('LEAD_FORMS'),
    tickets: genericSlice('TICKETS'),
    ticketContacts: genericSlice('TICKET_CONTACTS'),
    ticketChefMeta: genericSlice('TICKET_CHEFS_META'),
    ticketChefs: genericSlice('TICKET_CHEFS'),
    ticketGroceryMeta: genericSlice('TICKET_GROCERIES_META'),
    ticketGroceries: genericSlice('TICKET_GROCERIES'),
    ticketGuestMeta: genericSlice('TICKET_GUESTS_META'),
    ticketGuests: genericSlice('TICKET_GUESTS'),
    ticketCosts: genericSlice('TICKET_COSTS'),
    ticketInventories: genericSlice('TICKET_INVENTORIES'),
    ticketMenuMeta: genericSlice('TICKET_MENUS_META'),
    ticketMenus: genericSlice('TICKET_MENUS'),
    ticketNotes: genericSlice('TICKET_NOTES'),
    ticketServerMeta: genericSlice('TICKET_SERVERS_META'),
    ticketServers: genericSlice('TICKET_SERVERS'),
    ticketSousChefMeta: genericSlice('TICKET_SOUS_CHEFS_META'),
    ticketSousChefs: genericSlice('TICKET_SOUS_CHEFS'),
    ticketTimelines: genericSlice('TICKET_TIMELINES'),
    ticketTravelMeta: genericSlice('TICKET_TRAVELS_META'),
    ticketTravel: genericSlice('TICKET_TRAVEL'),
    ticketVenueMeta: genericSlice('TICKET_VENUES_META'),
    ticketVenues: genericSlice('TICKET_VENUES'),
    paginator: pagerReducer,
    uiDrawer: uiDrawerReducer,
    user: genericSlice('USER', {})
}

export default indexReducer
