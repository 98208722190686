import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { NavLink, Routes, Route } from 'react-router-dom'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, nestedRoutePath, routes } from '../../../constants/routes'

// import DisplayValue from '../../utils/display-value'

import AdminChefProfile from './profile/view'
import AdminChefTicketList from './tickets/list'
import AdminChefOverview from './overview/view'
import AdminChefMenuList from './menus/list'
import AdminChefPhotosList from './photos/list'
import AdminChefAvailability from './availability/view'
import AdminChefFinancials from './financials/view'
import AdminChefDocuments from './documents/view'
import AdminChefSettings from './settings/view'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        chef: state.adminChefs.find(ac => ac.id === ownProps.params.chefId)
    }
}

const ViewChef = ({ dispatch, params, chef }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chefs', 'VIEW', params.chefId)))
    }, [dispatch, params.chefId])

    if (!chef) {
        return null
    }

    return (
        <>
            <div className="section-header">
                <span className="title">{chef.displayName}</span>
            </div>
            <div className="dashboard-wrapper">
                <div className="sidebar">
                    {/* <div className="sidebar-box">
                        <h4>Contact Info</h4>
                        <DisplayValue label="Email" value={<a href={`mailto:${chef.email}`}>{chef.email}</a>} />
                        <DisplayValue label="Phone" value={<a href={`tel:${chef.phone}`}>{chef.phone}</a>} />
                    </div> */}
                    <div className="side-nav">
                        <NavLink to={buildRoutePath(routes.adminChefOverviewIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="list" /> Overview
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefTicketIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="ticket" /> Tickets
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefMenuIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="carrot" /> Menus
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefProfileIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="user-ninja" /> Profile
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefPhotosIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="images" /> Photos
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefAvailabilityIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Availability
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefFinancialsIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="money-bill-wave" /> Financials
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefDocumentsIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="file" /> Documents
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminChefSettingsIndex.path, { chefId: chef.id })}>
                            <FontAwesomeIcon icon="cog" /> Settings
                        </NavLink>
                    </div>
                </div>
                <div className="main">
                    <Routes>
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefTicketIndex.path, true)} element={<AdminChefTicketList chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefOverviewIndex.path, true)} element={<AdminChefOverview chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefMenuIndex.path, true)} element={<AdminChefMenuList chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefProfileIndex.path, true)} element={<AdminChefProfile chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefPhotosIndex.path, true)} element={<AdminChefPhotosList chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefAvailabilityIndex.path, true)} element={<AdminChefAvailability chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefFinancialsIndex.path, true)} element={<AdminChefFinancials chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefDocumentsIndex.path, true)} element={<AdminChefDocuments chef={chef} />} />
                        <Route path={nestedRoutePath(routes.adminChefsView.path, routes.adminChefSettingsIndex.path, true)} element={<AdminChefSettings chef={chef} />} />
                        <Route index element={<AdminChefOverview />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewChef))
