import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { closeDrawer } from '../../../reducers/ui-drawer'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../utils/modal'

const mapStateToProps = (state, ownProps) => {
    return {
        photo: state.chefPhotos.find(cp => cp.id === ownProps.photoId)
    }
}

const EditPhoto = ({ dispatch, photoId, photo }) => {
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-photos', 'VIEW', photoId)))
    }, [dispatch, photoId])

    if (!photo) {
        return (
            <h1>Loading...</h1>
        )
    }

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('chefs/chef-photos', 'UPDATE', photoId), formData, formId))
    }

    const successFX = () => {
        dispatch(closeDrawer())
    }

    const handleDelete = () => {
        let deleteMe = window.confirm(`Are you sure you want to delete this photo?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('chefs/chef-photos', 'DELETE', photo.id), {}, -1))
                .then(() => dispatch(closeDrawer()))
        } else {
            dispatch(closeDrawer())
        }
    }

    const renderModalContents = () => {
        return (
            <img
                src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.photoId}`, 'LIST').url + '?style=large'}
                alt="large version" />
        )
    }

    return (
        <>
            <div>
                <h3>Edit Photo</h3>
                <img
                    src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.photoId}`, 'LIST').url + '?style=thumbnail'}
                    alt={photo.overrideAlt ? photo.overrideAlt : photo._computed.photoAlt}
                    onClick={() => setShowModal(true)} />
                <SHSForm submitFX={submitFX} successFX={successFX} resetForm={true} showCancel={true} cancelAction={() => dispatch(closeDrawer())}>
                    <InputElement
                        type="text"
                        id="overrideAlt"
                        placeholder="Enter alt text"
                        label="Alt Text"
                        default={photo.overrideAlt} />
                    { photo._computed?.photoAlt &&
                        <span className="sm-txt">
                            Alt text from uploaded photo is {photo._computed.photoAlt}
                        </span>
                    }
                    <InputElement
                        type="text"
                        id="overrideCaption"
                        placeholder="Enter caption"
                        label="Caption"
                        default={photo.overrideCaption} />
                    {photo._computed?.photoCaption &&
                        <span className="sm-txt">
                            Caption from uploaded photo is {photo._computed.photoCaption}
                        </span>
                    }
                    <InputElement
                        type="number"
                        id="order"
                        placeholder="Enter order"
                        label="Order"
                        default={photo.order} />
                    <InputElement
                        type="valueSelect"
                        label="Use for Profile Photo"
                        id="isProfile"
                        default={photo.isProfile === false ? '0' : true}
                        options={[{ value: true, label: 'Yes' }, { value: '0', label: 'No' }]} />
                </SHSForm>
                <div className="delete-action" title="Delete" onClick={() => handleDelete()}>
                    <FontAwesomeIcon icon={['far', 'trash-can']} /> Delete Photo
                </div>
            </div>
            { showModal &&
                <Modal closeAction={() => setShowModal(false)}>
                    {renderModalContents()}
                </Modal>
            }
        </>
    )
}

export default connect(mapStateToProps)(EditPhoto)
