import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import InputElement from '../../utils/input'
import * as MASKS from '../../utils/input-masks'

import { fetchPublicApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'

const mapStateToProps = (state) => {
    return {
        apiCuisines: state.apiCuisines,
        apiServiceStyles: state.apiServiceStyles,
        apiVibes: state.apiVibes
    }
}

const EventFormInfo = (props) => {
    const { dispatch, formFields, onHandleChange, apiCuisines, apiServiceStyles, apiVibes } = props

    const cuisineOptions = apiCuisines.map(opt => ({ value: opt.id, label: opt.name }))
    const serviceOptions = apiServiceStyles.map(opt => ({ value: opt.id, label: opt.name }))
    const vibeOptions = apiVibes.map(opt => ({ value: opt.id, label: opt.name }))
    const today = new Date()
    const maxDate = new Date()


    useEffect(() => {
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-vibes', 'LIST')))
    }, [dispatch])

    return (
        <>
            <InputElement
                type="text"
                id="eventName"
                default={formFields.eventName}
                onChange={onHandleChange}
                label="Name Your Event"
                placeholder="e.g. Jesse's Birthday Dinner"
                // helperText="Don't worry the name is just so we can refer to something..."
                extraClass="flex-50"
                required />
            <InputElement
                type="text"
                id="headcount"
                label="Number of guests"
                default={formFields.headcount}
                onChange={onHandleChange}
                initialValueLabel="Number of guests"
                helperText="Initial estimate of headcount"
                placeholder="e.g. 12"
                isFormatted={true}
                formatMask={MASKS.integerMask}
                extraClass="flex-50"
                required />
            <InputElement
                type="datePicker"
                dateType="date"
                id="eventDate"
                label="Event Date"
                onChange={onHandleChange}
                options={{
                    minDate: today,
                    maxDate: maxDate.setMonth(today.getMonth() + 11),
                    placeholderText: 'Select event date',
                    todayButton: 'Today',
                    required: true
                }}
                extraClass="flex-50"
                required />
            <InputElement
                type="datePicker"
                dateType="time"
                id="eventTime"
                label="Event Time"
                onChange={onHandleChange}
                options={{
                    minTime: setHours(setMinutes(new Date(), 30), 7),
                    maxTime: setHours(setMinutes(new Date(), 0), 23),
                    showTimeSelect: true,
                    showTimeSelectOnly: true,
                    timeIntervals: 15,
                    timeCaption: 'Time',
                    placeholderText: 'Select Time',
                    dateFormat: 'h:mm aa',
                    showPopperArrow: false,
                    required: true
                }}
                extraClass="flex-50"
                required />
            <InputElement
                type="mapboxAddress"
                id="street"
                label="Address"
                default={formFields.street}
                // onSelect={onHandleChange}
                onChange={onHandleChange}
                extraClass="flex-50"
                placeholder="Enter Street"
                autoComplete="street-address" />
            <InputElement
                type="text"
                id="city"
                label="City"
                default={formFields.city}
                onChange={onHandleChange}
                placeholder="Enter City"
                autoComplete="address-level2"
                extraClass="flex-50" />
            <InputElement
                type="text"
                id="state"
                label="State"
                default={formFields.state}
                onChange={onHandleChange}
                placeholder="Enter State"
                autoComplete="address-level1"
                extraClass="flex-50" />
            <InputElement
                type="text"
                id="postal"
                label="Postal"
                default={formFields.postal}
                onChange={onHandleChange}
                placeholder="Enter Zipcode"
                autoComplete="postal-code"
                extraClass="flex-50"
                required />
            <InputElement
                type="valueSelect"
                id="apiCuisineTypeId"
                label="Cuisine Type"
                default={formFields.apiCuisineId}
                onChange={onHandleChange}
                initialValueLabel="Select a cuisine type"
                options={cuisineOptions}
                extraClass="flex-100"
                required />
            <InputElement
                type="valueSelect"
                id="apiServiceStyleId"
                label="Service Style"
                default={formFields.apiServiceStyleId}
                onChange={onHandleChange}
                initialValueLabel="Select a service style"
                options={serviceOptions}
                extraClass="flex-100"
                required />
            <InputElement
                type="valueSelect"
                id="apiVibeId"
                label="Vibe"
                default={formFields.apiVibeId}
                onChange={onHandleChange}
                initialValueLabel="Select a vibe style"
                options={vibeOptions}
                extraClass="flex-100"
                required />
        </>
    )
}

export default connect(mapStateToProps)(EventFormInfo)
