import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TicketCard from './card'

import { PaginationWrapper } from 'shs-pagination'

const mapStateToProps = (state) => {
    const paginator = state.paginator.tickets
    let tickets = state.tickets
    if (paginator) {
        tickets = tickets
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return {
        tickets,
        paginator
    }
}

const TicketList = ({ dispatch, tickets, paginator }) => {
    const [ticketStyle, setTicketStyle] = useState('CARDS')

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'LIST')))
    }, [dispatch])

    const renderTickets = () => {
        if (!paginator) {
            return null
        }

        let allTickets = []
        tickets.forEach(ticket => {
            allTickets.push(
                <TicketCard key={ticket.id} ticket={ticket} />
            )
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                <div className={`cards ${ticketStyle === 'LIST' ? 'list' : ''}`} >
                    {allTickets}
                </div>
            </PaginationWrapper>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'LIST'), paginationParams))
    }

    return (
        <div>
            <div className="section-header">
                <span className="title">All Tickets</span>
                <div className="pagination-controls">
                    { ticketStyle !== 'CARDS' &&
                        <span className="controls"
                            onClick={() => setTicketStyle('CARDS')}
                            title="View as Cards">
                            <FontAwesomeIcon icon="th-large" />&nbsp; Cards
                        </span>
                    } { ticketStyle !== 'LIST' &&
                        <span className="controls"
                            onClick={() => setTicketStyle('LIST')}
                            title="View as Table">
                            <FontAwesomeIcon icon="list" />&nbsp; List
                        </span>
                    }
                </div>
            </div>
            {renderTickets()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketList)
