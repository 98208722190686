import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { buildRoutePath, routes } from '../../../constants/routes'

import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        guestMeta: state.ticketGuestMeta.find(tgm => tgm.ticketId === ownProps.ticket.id),
        contacts: state.ticketContacts.filter(tc => tc.ticketId === ownProps.ticket.id)
    }
}

const TicketCard = (props) => {
    const { ticket, guestMeta, contacts } = props
    const pointOfContact = contacts.find(c => c.isPointOfContact)
    return (
        <div key={ticket.id} className="card">
            <div className="initials-holder">
                <span className="initials">
                    { pointOfContact
                        ? <>{pointOfContact.firstName.substr(0, 1)}{pointOfContact.lastName.substr(0, 1)}</>
                        : <>&ndash;&ndash;</>
                    }
                </span>
            </div>
            <div className="menu-holder">
                <FontAwesomeIcon icon="ellipsis-v" />
            </div>
            <Link className="title" to={buildRoutePath(routes.adminTicketsView.path, { ticketId: ticket.id })}>
                {ticket.eventName}
            </Link>
            <div className="details-wrapper">
                <span className="details">{pointOfContact ? <>{pointOfContact.firstName} {pointOfContact.lastName}</> : 'Contact not set'}</span>
                <span className="details"><em>Chef Name</em></span>
                <span className="details">{guestMeta ? guestMeta.estimatedHeadcount : 0} Guest{guestMeta && guestMeta.estimatedHeadcount === 1 ? '' : 's'}</span>
            </div>
            <div className="details-wrapper">
                <span className="details">{moment(ticket.eventDate).format('MMMM DD, Y')}</span>
                <span className="details"><em>Tivity Team Member</em></span>
                <span className="details">{ticket.postal}</span>
            </div>
            <span className="btn">{ticket._computed.statusDisplay}</span>
        </div>
    )
}

export default connect(mapStateToProps)(TicketCard)
