import React from 'react'

const ChefPortalDocuments = () => {
    return (
        <div>
            <h1>I am the Chef Documents Index</h1>
        </div>
    )
}

export default ChefPortalDocuments
