import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'
import ShsForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

const mapStateToProps = (state, ownProps) => {
    return {
        socials: state.adminChefSocials.filter(acs => acs.chefId === ownProps.chef.id)
    }
}

const ChefSocial = ({ dispatch, chef, socials }) => {
    const [ showAdd, setShowAdd ] = useState(false)

    const chefId = chef.id
    useEffect(() => {
        if (chefId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chef-socials', 'LIST'), { filter: [`chef_id|${chefId}`] }))
        }
    }, [dispatch, chefId])

    const renderSocial = () => {
        let allSocials = []
        socials.forEach(social => {
            allSocials.push(
                <div className="field-wrappers flex-50" key={social.id}>
                    <div className="flex-50">
                        <p>{social.platform}</p>
                    </div>
                    <div className="flex-50">
                        <p>{social.value}</p>
                    </div>
                </div>
            )
        })
        return allSocials
    }

    const submitFX = (formData, formId) => {
        formData.chefId = chef.id
        dispatch(callApi(apiResourceEndpoint('admin/chef-socials', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        setShowAdd(false)
    }

    const renderAddForm = () => {
        if (showAdd) {
            return (
                <ShsForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowAdd(false)}>
                    <InputElement
                        label="Platform"
                        id="platform"
                        type="select"
                        options={['Instagram', 'Facebook', 'Twitter', 'LinkedIn', 'Pinterest', 'YouTube', 'TikTok', 'Website']} />
                    <InputElement
                        label="Value"
                        id="value"
                        type="text" />
                </ShsForm>
            )
        }
        return null
    }

    return (
        <div className="content-box">
            <h3>Social Links</h3>
            {renderSocial()}
            {renderAddForm()}
            <span className="clickable" onClick={() => setShowAdd(true)}>+ Add Social Link</span>
        </div>
    )
}

export default connect(mapStateToProps)(ChefSocial)
