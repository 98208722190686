import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        servers: state.ticketServers.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketServers = ({ servers }) => {
    const renderServers = () => {
        let allServers = []
        servers.forEach(server => {
            allServers.push(
                <tr key={server.id}>
                    {/* <td>{menu.apiMenuId}</td>
                    <td>{menu.template}</td> */}
                    <td>{server.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Server Company</th>
                        <th>Number of Servers</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allServers}
                </tbody>
            </table>
        )
    }

    if (!servers || servers.length === 0) {
        return null
    }

    return (
        <div className="section ticket-timeline">
            <h2>Servers</h2>
            {renderServers()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketServers)
