import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        // Give case reducers meaningful past-tense "event"-style names
        saveAuth(state, action) {
            state.emailVerified = action.payload.emailVerified
            state.email = action.payload.email
            state.displayName = action.displayName
            state.lastLoginDate = action.payload.lastLoginDate
            state.isAnonymous = action.payload.isAnonymous
            state.authId = action.payload.uid
        },
        removeAuth: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { saveAuth, removeAuth } = authSlice.actions
export default authSlice.reducer
