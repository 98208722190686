import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { apiResourceEndpoint, routes } from '../../../constants/routes'
// import { isEmpty } from 'lodash'
import { fetchApiDataIfNeeded } from '../../../actions/api'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        role: state.role,
        user: state.user
    }
}

const AuthRouter = ({ dispatch, user, location, auth }) => {
    const [readyToRoute, setReadyToRoute] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user/load-details', 'LIST')))
        if (user && user?.id > 0) {
            setReadyToRoute(true)
        }
    }, [dispatch, user])


    if (readyToRoute) {
        const userRoleLevel = user._computed.roleLevel
        if (location.state && location.state.from.pathname) {
            return <Navigate to={location.state.from.pathname} />
        }
        if (userRoleLevel >= 1000) {
            return <Navigate to={routes.adminIndex.path} />
        } else if (userRoleLevel === 500) {
            return <Navigate to={routes.chefPortalIndex.path} />
        }
        return <Navigate to={routes.appIndex.path} />
    }

    if (auth && 'email' in auth) {
        if (user && user.id) {
            if (user.id === -1) {
                return <Navigate to={routes.createUser.path} />
            }
        }
    }

    return <div>Routing you to the right place...</div>
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
