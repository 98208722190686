import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { isEmpty } from 'lodash'

const PublicNav = ({ auth, user }) => {
    return (
        <div id="public-nav">
            <div className="logo">
                <Link to={routes.publicIndex.path}>
                    <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" height="90" />
                </Link>
            </div>
            <div className="public-menu">
                { user && '_computed' in user && user._computed.roleLevel >= 1000 &&
                    <NavLink to={routes.adminIndex.path}>Admin</NavLink>
                }
                {user && '_computed' in user && user._computed.roleLevel >= 500 &&
                    <NavLink to={routes.chefPortalIndex.path}>Chef Portal</NavLink>
                }
                { auth && !isEmpty(auth) && user &&
                    <NavLink to={routes.eventsIndex.path}>My Events</NavLink>
                }
                {auth && !isEmpty(auth)
                    ? <NavLink to={routes.authLogout.path}>Logout</NavLink>
                    : (
                        <>
                            <NavLink to={routes.authRegister.path}>Register</NavLink>
                            <NavLink to={routes.authLogin.path}>Login</NavLink>
                        </>
                    )
                }
                <NavLink to={routes.createEvent.path} className="btn">Book a Chef</NavLink>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

export default connect(mapStateToProps)(PublicNav)
