import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes, nestedRoutePath } from '../../../../../constants/routes'

import CreateMenu from './create'
import ViewMenu from './view'
import ListMenus from './list'

import '../../../../../assets/scss/menus.scss'

const MenusMenuIndex = (props) => {
    return (
        <>
            <div className = "section-header">
                <span className="title">Menus</span>
            </div>
            <Routes>
                <Route path={nestedRoutePath(routes.chefPortalMenusIndex.path, routes.chefPortalMenusCreate.path, true)} element={<CreateMenu />} />
                <Route path={nestedRoutePath(routes.chefPortalMenusIndex.path, routes.chefPortalMenusView.path, true)} element={<ViewMenu />} />
                <Route index element={<ListMenus />} />
            </Routes>
        </>
    )
}

export default MenusMenuIndex
