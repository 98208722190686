import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import { buildRoutePath, nestedRoutePath, routes } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Allergens from './allergens'
import DietaryRestrictions from './dietary-restrictions'

const SettingsIndex = () => {
    return (
        <>
            <div className="section-header">
                <span className="title">Settings</span>
            </div>
            <div className="dashboard-wrapper">
                <div className="sidebar">
                    <div className="side-nav">
                        <NavLink to={buildRoutePath(routes.adminAllergensIndex.path)}>
                            <FontAwesomeIcon icon="person-dots-from-line" /> Allergens
                        </NavLink>
                        <NavLink to={buildRoutePath(routes.adminDietaryRestrictionsIndex.path)}>
                            <FontAwesomeIcon icon="circle-exclamation" /> Dietary Restrictions
                        </NavLink>
                    </div>
                </div>
                <div className="main">
                    <Routes>
                        <Route path={nestedRoutePath(routes.adminSettingsIndex.path, routes.adminAllergensIndex.path, true)} element={<Allergens />} />
                        <Route path={nestedRoutePath(routes.adminSettingsIndex.path, routes.adminDietaryRestrictionsIndex.path, true)} element={<DietaryRestrictions />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default SettingsIndex
