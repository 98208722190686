import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { formatTime } from '../../utils/formatters'

const mapStateToProps = (state, ownProps) => {
    return {
        primaryContact: state.ticketContacts.find(tc => tc.ticketId === ownProps.ticket.id && tc.isPointOfContact),
        guestMeta: state.ticketGuestMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const TicketTitle = ({ ticket, primaryContact, guestMeta }) => {
    const renderEventName = () => {
        return (
            <span>
                {`${ticket.eventName} (${primaryContact.firstName} ${primaryContact.lastName}) ${guestMeta.estimatedHeadcount} guests`}
            </span>
        )
    }

    const renderEventDate = () => {
        return (
            <span>
                {moment(ticket.eventDate).format('dddd MMMM D, Y')} @ {formatTime(ticket.eventTime)}
            </span>
        )
    }

    const renderAddress = () => {
        let address = ''
        if (ticket.street) {
            address = address + ' ' + ticket.street
        }
        if (ticket.city) {
            address = address + ' ' + ticket.city
        }
        if (ticket.state) {
            address = address + ' ' + ticket.state
        }
        if (ticket.postal) {
            address = address + ' ' + ticket.postal
        }
        return address.trim()
    }

    return (
        <div className="section ticket-title">
            {renderEventName()}
            {renderEventDate()}
            {renderAddress()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketTitle)
