import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        inventories: state.ticketInventories
            .filter(ti => ti.ticketId === Number(ownProps.params.ticketId))
            .sort((a, b) => {
                if (a.item > b.item) {
                    return 1
                }
                return -1
            })
    }
}

const TicketInventory = ({ dispatch, params, ticket, inventories }) => {
    const [showForm, setShowForm] = useState(false)
    const partyOptions = ['TBD', 'Client', 'Chef', 'Tivity', 'Other', 'N/A']

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-inventory', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const handleDelete = (inventory) => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${inventory.item}?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-inventory', 'DELETE', inventory.id), {}, -1))
        }
    }

    const renderInventory = () => {
        let allInventory = []
        inventories.forEach(inventory => {
            allInventory.push(
                <div key={inventory.id} className="item">
                    <EditableDisplayField
                        label="Item"
                        display={inventory.item}
                        dataObj={inventory}
                        endpoint="admin/ticket-inventory"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'item'
                        }]} />
                    <EditableDisplayField
                        label="Quantity"
                        display={inventory.quantity}
                        dataObj={inventory}
                        endpoint="admin/ticket-inventory"
                        extraClass="field quantity"
                        inTable={true}
                        fields={[{
                            type: 'number',
                            field: 'quantity'
                        }]} />
                    <EditableDisplayField
                        label="Responsible Party"
                        display={inventory.responsibleParty}
                        dataObj={inventory}
                        endpoint="admin/ticket-inventory"
                        extraClass="field party"
                        inTable={true}
                        fields={[{
                            type: 'select',
                            field: 'responsibleParty',
                            options: partyOptions
                        }]} />
                    <EditableDisplayField
                        label="Notes"
                        display={inventory.notes}
                        dataObj={inventory}
                        endpoint="admin/ticket-inventory"
                        extraClass="field notes"
                        inTable={true}
                        fields={[{
                            type: 'textarea',
                            field: 'notes'
                        }]} />
                    <span className="remove" title="Delete" onClick={() => handleDelete(inventory)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return <div className="ticket-items">{allInventory}</div>
    }

    const submitFX = (formData, formId) => {
        formData.ticketId = params.ticketId
        dispatch(callApi(apiResourceEndpoint('admin/ticket-inventory', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)} extraClass="field">
                        <InputElement
                            id="item"
                            label="Item"
                            type="text"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="quantity"
                            label="Quantity"
                            type="number"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="responsibleParty"
                            label="Responsible Party"
                            type="select"
                            extraClass="flex-33"
                            options={partyOptions}
                            required />
                        <InputElement
                            id="notes"
                            label="Notes"
                            type="textarea" />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Item +
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderInventory()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketInventory))
