import React from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../../../actions/api'
import { buildRoutePath, routes, apiResourceEndpoint } from '../../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as MASKS from '../../../utils/input-masks'


const ChefCard = ({ dispatch, chef, ticketChefs }) => {
    const handleDelete = () => {
        const apiChefId = ticketChefs.find(tm => tm.apiChefId === chef.id).apiChefId
        const ticketChef = ticketChefs.find(tm => tm.apiChefId === apiChefId)
        let deleteMe = window.confirm(`Are you sure you want to delete ${chef.displayName}`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-chefs', 'DELETE', ticketChef.id), {}, -1))
        }
    }

    return (
        <div key={chef.id} className="card">
            <div className="initials-holder">
                <span className="initials">
                    { chef
                        ? <>{chef.firstName.substr(0, 1)}{chef.lastName.substr(0, 1)}</>
                        : <>&ndash;&ndash;</>
                    }
                </span>
            </div>
            <Link className="title" to={buildRoutePath(routes.adminChefsView.path, { chefId: chef.id })}>
                {chef.displayName}
            </Link>
            <div className="details-wrapper">
                <span className="details"><em>{chef.title}</em></span>
            </div>
            <div className="details-wrapper">
                <a href={`tel:${chef.phone}`} className="details">{MASKS.phoneMask.resolve(chef.phone || '')}</a>
                <a href={`mailto:${chef.email}`} className="details">{chef.email}</a>
            </div>
            <FontAwesomeIcon icon={['far', 'trash-can']} onClick={handleDelete}>Remove</FontAwesomeIcon>
        </div>
    )
}

export default (ChefCard)
