import React, { useEffect, useState } from 'react'
import shortid from 'shortid'
import { connect } from 'react-redux'

import { upsertForm } from '../../reducers/form'
import { formStates } from '../../constants/helper-states'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const SHSForm = (props) => {
    const { dispatch, children, forms, extraClass, formClass, buttonClass, buttonLabel, buttonDisabled, cancelAction, cancelButtonLabel, showCancel, resetForm, successFX, submitFX, useFormData, inline } = props
    const [ formId ] = useState(shortid.generate())
    const formStatus = forms.find(f => f.id === formId)
    // initialize the form status
    useEffect(() => {
        dispatch(upsertForm({ id: formId, status: formStates.EMPTY }))
    }, [dispatch, formId])

    useEffect(() => {
        if (formStatus && formStatus.status === formStates.SUCCESS) {
            if (resetForm === true) {
                dispatch(upsertForm({ id: formId, status: formStates.EMPTY }))
            }
            successFX(formStatus.body)
        }
    }, [dispatch, successFX, formStatus, formId, resetForm])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        let validated = evt.target.checkValidity()
        evt.target.classList.remove('was-validated')
        if (!validated) {
            evt.target.classList.add('was-validated')
        } else {
            const formDataObj = new FormData(evt.target)
            dispatch(upsertForm({ id: formId, status: formStates.PROCESSING }))
            if (useFormData) {
                // this is used when we need to send raw file data
                submitFX(formDataObj, formId)
            } else {
                let formData = {}
                formDataObj.forEach((val, key) => {
                    const inputEl = document.getElementById(key)
                    if (inputEl) {
                        let raw = inputEl.getAttribute('rawvalue')
                        if (raw !== '' && raw !== null) {
                            formData[key] = raw
                        } else {
                            formData[key] = val
                        }
                    }
                })
                submitFX(formData, formId)
            }
        }
    }

    const handleCancel = (evt) => {
        evt.preventDefault()
        cancelAction()
    }

    const renderStandard = () => {
        const renderButtonLabel = buttonLabel || 'Submit'
        return (
            <div className={`${formClass || 'flex-100 w-100 form-btn-wrapper'}`}>
                <button
                    type="submit"
                    className={`btn ${buttonClass || ''}`}
                    disabled={formStatus && (formStatus.status === 'PROCESSING' || formStatus.status === 'SUCCESS' || buttonDisabled) ? 'disabled' : null} >
                    {formStatus && formStatus.status === 'SUCCESS' ? 'Redirecting...' : renderButtonLabel}
                </button>
                {showCancel &&
                    <button className={`btn cancel ml-2 ${buttonClass || ''}`} onClick={handleCancel}>
                        {cancelButtonLabel || 'Cancel'}
                    </button>
                }
            </div>
        )
    }

    const renderInline = () => {
        return (
            <div className="action-icons">
                <button
                    className="clickable"
                    type="submit"
                    disabled={formStatus && (formStatus.status === 'PROCESSING' || formStatus.status === 'SUCCESS' || buttonDisabled) ? 'disabled' : null}>
                    <FontAwesomeIcon icon="check" />
                </button>
                {showCancel &&
                    <button
                        className="clickable cancel"
                        onClick={handleCancel}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                }
            </div>
        )
    }


    // const formActions = this.props.inline ? this.renderInline(formStatus) : this.renderStandard(formStatus)
    return (
        <form onSubmit={handleSubmit} className={`${extraClass ? extraClass : ''}`} noValidate>
            <div className="form-group">
                {children}
                {formStatus && formStatus.status === 'ERROR' && formStatus.body !== '' &&
                <div className="alert alert-danger w-100">
                    {formStatus.body}
                </div>
                }
                {inline ? renderInline() : renderStandard()}
                {/* <div className={`${formClass || 'flex-100 w-100 form-btn-wrapper'}`}>
                    <button
                        type="submit"
                        className={`btn ${buttonClass || ''}`}
                        disabled={formStatus && (formStatus.status === 'PROCESSING' || formStatus.status === 'SUCCESS' || buttonDisabled) ? 'disabled' : null} >
                        {formStatus && formStatus.status === 'SUCCESS' ? 'Redirecting...' : renderButtonLabel}
                    </button>
                    { showCancel &&
                    <button className={`btn add clickable ml-2 ${buttonClass || ''}`} onClick={handleCancel}>
                        { cancelButtonLabel || 'Cancel' }
                    </button>
                    }
                </div> */}
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    return {
        forms: state.forms
    }
}

export default connect(mapStateToProps)(SHSForm)
