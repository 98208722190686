import React, { useState } from 'react'
import { connect } from 'react-redux'

import { callApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

const mapStateToProps = (state) => {
    return {
        chef: state.chefs.find(ac => ac.id === state.user?.apiUserId)
    }
}

const ChefScheduler = ({ dispatch, chef, onSetShowForm }) => {
    const [ scheduleType, setScheduleType ] = useState(null)
    const [ availability, setAvailability ] = useState({})
    const [ startDate, setStartDate ] = useState(null)
    const [ endDate, setEndDate ] = useState(null)
    const [ blackoutDates, setBlackoutDates] = useState([])

    const renderNoType = () => {
        if (scheduleType) {
            return null
        }
        return (
            <>
                <h1>New Schedule</h1>
                <p>Select the location type</p>
                <div className="schedule-types">
                    <div onClick={() => setScheduleType('PRIMARY')}>
                        <FontAwesomeIcon icon="map-marker-alt" />
                        Primary
                    </div>
                    <div onClick={() => setScheduleType('TEMPORARY')}>
                        <FontAwesomeIcon icon="suitcase" />
                        Temporary
                    </div>
                </div>
                <p className="clickable mt-4" onClick={() => onSetShowForm(false)}>
                    Cancel
                </p>
            </>
        )
    }

    const handleDayClick = (day) => {
        let localAvailability = { ...availability }
        if (day.key in localAvailability) {
            let dayTime = localAvailability[day.key].time
            if (!dayTime) {
                localAvailability[day.key].time = 'ALL'
            } else {
                localAvailability[day.key].time = null
            }
        } else {
            localAvailability[day.key] = { time: 'ALL', index: day.index }
        }
        setAvailability(localAvailability)
    }

    const handleAmPmClick = (day, period) => {
        let localAvailability = { ...availability }
        if (day.key in localAvailability) {
            let dayTime = localAvailability[day.key].time
            if (!dayTime) {
                localAvailability[day.key].time = period
            } else if (dayTime === period) {
                localAvailability[day.key].time = null
            } else if (dayTime === 'ALL') {
                if (period === 'AM') {
                    localAvailability[day.key].time = 'PM'
                } else if (period === 'PM') {
                    localAvailability[day.key].time = 'AM'
                }
            } else {
                localAvailability[day.key].time = 'ALL'
            }
        } else {
            localAvailability[day.key] = { time: period, index: day.index }
        }
        setAvailability(localAvailability)
    }


    const renderDayNights = () => {
        let days = [
            { key: 'SUN', label: 'Sunday', index: 0, short: 'Sun' },
            { key: 'MON', label: 'Monday', index: 1, short: 'Mon' },
            { key: 'TUE', label: 'Tuesday', index: 2, short: 'Tue' },
            { key: 'WED', label: 'Wednesday', index: 3, short: 'Wed' },
            { key: 'THU', label: 'Thursday', index: 4, short: 'Thur' },
            { key: 'FRI', label: 'Friday', index: 5, short: 'Fri' },
            { key: 'SAT', label: 'Saturday', index: 6, short: 'Sat' }
        ]
        if (scheduleType === 'TEMPORARY') {
            if (!startDate || !endDate) {
                return null
            }
            days = []
            let startMoment = moment(startDate)
            let endMoment = moment(endDate)
            let diff = endMoment.diff(startMoment, 'd')
            if (diff > 0) {
                for (let count = 0; count <= diff; count = count + 1) {
                    days.push({
                        key: startMoment.format('MMM D'),
                        label: startMoment.format('dddd'),
                        index: startMoment.format('d'),
                        short: startMoment.format('MMM D')
                    })
                    startMoment.add(1, 'days')
                }
            }
        }
        let allDays = []
        days.forEach(day => {
            let labelClass = ''
            let amActive = ''
            let pmActive = ''
            if (day.key in availability && availability[day.key].time) {
                labelClass = 'active'
                if (availability[day.key].time === 'PM' || availability[day.key].time === 'ALL') {
                    pmActive = 'active'
                }
                if (availability[day.key].time === 'AM' || availability[day.key].time === 'ALL') {
                    amActive = 'active'
                }
            }
            allDays.push(
                <div className="day-block" key={day.key}>
                    <span className={`day-label ${labelClass}`} onClick={() => handleDayClick(day)}>
                        {day.short}
                    </span>
                    <div className="day-night-options">
                        <div className={`day ${amActive}`} onClick={() => handleAmPmClick(day, 'AM')}>
                            { amActive === 'active'
                                ? <FontAwesomeIcon icon="sun" />
                                : <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="sun" />
                                    <FontAwesomeIcon icon="slash" />
                                </span>
                            }
                        </div>
                        <div className={`night ${pmActive}`} onClick={() => handleAmPmClick(day, 'PM')}>
                            { pmActive === 'active'
                                ? <FontAwesomeIcon icon="moon" />
                                : <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="moon" />
                                    <FontAwesomeIcon icon="slash" />
                                </span>
                            }
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <div className="form-group">
                <div className="input-wrapper">
                    <label className="form-label">Available Days</label>
                    <div className="day-scheduler">
                        {allDays}
                    </div>
                </div>
            </div>
        )
    }

    const submitFX = (formData, formId) => {
        const payload = { ...formData }
        payload.chefId = chef.id
        payload.availabilityType = scheduleType
        if (scheduleType === 'PRIMARY') {
            payload.availability = {
                days: availability,
                blackouts: blackoutDates
            }
        } else if (scheduleType === 'TEMPORARY') {
            payload.availability = {
                dates: availability
            }
        }
        dispatch(callApi(apiResourceEndpoint('chefs/chef-service-areas', 'CREATE'), payload, formId))
    }

    const successFX = () => {
        onSetShowForm(false)
    }

    const handleBlackoutAddition = (key, val) => {
        let stateBlackouts = [...blackoutDates]
        const idx = stateBlackouts.indexOf(val)
        if (idx === -1) {
            stateBlackouts.push(val)
            setBlackoutDates(stateBlackouts)
        }
    }

    const removeBlackout = (blackoutDate) => {
        let stateBlackouts = [...blackoutDates]
        const idx = stateBlackouts.indexOf(blackoutDate)
        stateBlackouts.splice(idx, 1)
        setBlackoutDates(stateBlackouts)
    }

    const renderBlackouts = () => {
        let allBlackouts = []
        blackoutDates.forEach((bd, idx) => {
            allBlackouts.push(
                <div key={idx} className="blackout">
                    {moment(bd).format('ddd. MMM DD, Y')}
                    <span className="remove" onClick={() => removeBlackout(bd)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return (
            <>
                <InputElement
                    type="date"
                    id="blackout"
                    onChange={handleBlackoutAddition}
                    label="Blackout Dates" />
                <div className="blackout-dates">
                    {allBlackouts}
                </div>
            </>
        )
    }

    const renderPrimaryType = () => {
        if (scheduleType !== 'PRIMARY') {
            return null
        }
        return (
            <div>
                <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setScheduleType(null)}>
                    <InputElement
                        type="text"
                        label="Label"
                        placeholder="Name your location"
                        id="label" />
                    <InputElement
                        type="text"
                        label="Zipcode"
                        placeholder="Location where you are based."
                        id="postal" />
                    <InputElement
                        type="valueSelect"
                        id="radius"
                        label="Service Radius"
                        options={[
                            { label: '1 mile', value: 1 },
                            { label: '5 miles', value: 5 },
                            { label: '10 miles', value: 10 },
                            { label: '20 miles', value: 20 },
                            { label: '50 miles', value: 50 },
                            { label: '100 miles', value: 100 },
                            { label: '200 miles', value: 200 }
                        ]} />
                    {renderDayNights()}
                    {renderBlackouts()}
                </SHSForm>
            </div>
        )
    }

    const renderTemporaryType = () => {
        if (scheduleType !== 'TEMPORARY') {
            return null
        }
        return (
            <div>
                <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setScheduleType(null)}>
                    <InputElement
                        type="text"
                        label="Label"
                        placeholder="Name your location"
                        id="label" />
                    <InputElement
                        type="text"
                        label="Zipcode"
                        placeholder="Location where you are based."
                        id="postal" />
                    <InputElement
                        type="valueSelect"
                        id="radius"
                        label="Service Radius"
                        options={[
                            { label: '1 mile', value: 1 },
                            { label: '5 miles', value: 5 },
                            { label: '10 miles', value: 10 },
                            { label: '20 miles', value: 20 },
                            { label: '50 miles', value: 50 },
                            { label: '100 miles', value: 100 },
                            { label: '200 miles', value: 200 }
                        ]} />
                    <InputElement
                        type="date"
                        label="Start Date"
                        onChange={(key, val) => setStartDate(val)}
                        id="start_date" />
                    <InputElement
                        type="date"
                        label="End Date"
                        onChange={(key, val) => setEndDate(val)}
                        id="end_date" />
                    {renderDayNights()}
                </SHSForm>
            </div>
        )
    }

    return (
        <div className="box">
            {renderPrimaryType()}
            {renderTemporaryType()}
            {renderNoType()}
        </div>
    )
}

export default connect(mapStateToProps)(ChefScheduler)
