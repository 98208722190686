import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import { formatCurrency } from '../../../utils/formatters'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        costs: state.ticketCosts
            .filter(ti => ti.ticketId === Number(ownProps.params.ticketId))
            .sort((a, b) => {
                if (a.item > b.item) {
                    return 1
                }
                return -1
            })
    }
}

const TicketCosts = ({ dispatch, params, ticket, costs }) => {
    const [showForm, setShowForm] = useState(false)
    const catOptions = ['General', 'Chef', 'Menu', 'Guest', 'Venue', 'Inventory', 'Other']

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-cost', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const handleDelete = (cost) => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${cost.item}?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-cost', 'DELETE', cost.id), {}, -1))
        }
    }

    const renderCost = () => {
        let allCosts = []
        costs.forEach(cost => {
            allCosts.push(
                <div key={cost.id} className="item">
                    <EditableDisplayField
                        label="Item"
                        display={cost.item}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'item'
                        }]} />
                    {/* <EditableDisplayField
                        label="Description"
                        display={cost.description}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field description"
                        fields={[{
                            type: 'text',
                            field: 'description'
                        }]} /> */}
                    <EditableDisplayField
                        label="Category"
                        display={cost.category}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field category"
                        inTable={true}
                        fields={[{
                            type: 'select',
                            field: 'Category',
                            options: catOptions
                        }]} />
                    <EditableDisplayField
                        label="Units"
                        display={cost.units}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field units"
                        inTable={true}
                        fields={[{
                            type: 'number',
                            field: 'units'
                        }]} />
                    <EditableDisplayField
                        label="Client Rate"
                        display={formatCurrency(cost.clientRate)}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field client-rate"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'clientRate'
                        }]} />
                    <EditableDisplayField
                        label="Actual Rate"
                        display={formatCurrency(cost.actualRate)}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field actual-rate"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'actualRate'
                        }]} />
                    <EditableDisplayField
                        label="Margin"
                        display={`${cost.margin}%`}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field margin"
                        inTable={true}
                        disabled={true}
                        fields={[{
                            type: 'text',
                            field: 'margin',
                            disabled: 'disabled'
                        }]} />
                    {/* <EditableDisplayField
                        label="Notes"
                        display={cost.notes}
                        dataObj={cost}
                        endpoint="admin/ticket-cost"
                        extraClass="field notes"
                        fields={[{
                            type: 'textarea',
                            field: 'notes'
                        }]} /> */}
                    <span className="remove" title="Delete" onClick={() => handleDelete(cost)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return <div className="ticket-items">{allCosts}</div>
    }

    const submitFX = (formData, formId) => {
        formData.ticketId = params.ticketId
        dispatch(callApi(apiResourceEndpoint('admin/ticket-cost', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)} extraClass="field">
                        <InputElement
                            id="item"
                            label="Item"
                            type="text"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="description"
                            label="Description"
                            type="text"
                            extraClass="flex-33"/>
                        <InputElement
                            id="category"
                            label="Category"
                            type="select"
                            extraClass="flex-33"
                            options={catOptions}
                            required />
                        <InputElement
                            id="units"
                            label="Units"
                            type="number"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="clientRate"
                            label="Client Rate"
                            type="number"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="actualRate"
                            label="Actual Rate"
                            type="number"
                            extraClass="flex-33"
                            required />
                        {/* <InputElement
                            id="margin"
                            label="Margin"
                            type="number"
                            extraClass="flex-33"
                            required /> */}
                        <InputElement
                            id="notes"
                            label="notes"
                            type="textarea" />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Item +
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderCost()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketCosts))
