import React from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { withRouterProps } from '../../../utils/with-router-props'
import { nestedRoutePath, routes } from '../../../../constants/routes'

import FoodItemsList from './list'
import FoodItemsView from './view'
import FoodItemsCreate from './create'

const FoodItemsIndex = () => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.adminFoodItemsIndex.path, routes.adminFoodItemsCreate.path)} element={<FoodItemsCreate />} />
            <Route path={nestedRoutePath(routes.adminFoodItemsIndex.path, routes.adminFoodItemsView.path)} element={<FoodItemsView />} />
            <Route index element={<FoodItemsList />} />
        </Routes>
    )
}

export default withRouterProps(connect()(FoodItemsIndex))
