import React from 'react'

import EditableDisplayField from '../../../utils/editable-display-field'
import * as MASKS from '../../../utils/input-masks'

const ChefPrimaryFields = ({ chef }) => {
    const contactMethods = [{ label: 'Email', value: 'EMAIL' }, { label: 'Phone', value: 'PHONE' }]

    return (
        <div className="content-box">
            <h3>Personal Information</h3>
            <div className="field-wrappers">
                <EditableDisplayField
                    label="First Name"
                    display={chef.firstName}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'firstName'
                    }]} />
                <EditableDisplayField
                    label="Last Name"
                    display={chef.lastName}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'lastName'
                    }]} />
                <EditableDisplayField
                    label="Display Name"
                    display={chef.displayName}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'displayName'
                    }]} />
                <EditableDisplayField
                    label="Email"
                    display={chef.email}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'email',
                        field: 'email'
                    }]} />
                <EditableDisplayField
                    label="Phone"
                    display={MASKS.phoneMask.resolve(chef.phone)}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'phone',
                        isFormatted: true,
                        formatMask: MASKS.phoneMask
                    }]} />
                <EditableDisplayField
                    label="Preferred Contact Method"
                    display={chef.contactMethod}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'valueSelect',
                        field: 'contactMethod',
                        options: contactMethods
                    }]} />
                <EditableDisplayField
                    label="Title"
                    display={chef.title}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'title'
                    }]} />
                <EditableDisplayField
                    label="Pronouns"
                    display={chef.pronouns}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'pronouns'
                    }]} />
                <EditableDisplayField
                    label="Pronounciation"
                    display={chef.pronounciation}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    inTable={true}
                    fields={[{
                        type: 'text',
                        field: 'pronounciation'
                    }]} />
                <EditableDisplayField
                    label="Short Bio"
                    display={chef.shortBio}
                    dataObj={chef}
                    endpoint="admin/chefs"
                    extraClass="flex-100"
                    inTable={true}
                    fields={[{
                        type: 'textarea',
                        field: 'shortBio'
                    }]} />
            </div>
        </div>
    )
}

export default ChefPrimaryFields
