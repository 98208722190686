import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { callApi } from '../../../actions/api'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import * as MASKS from '../../utils/input-masks'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

const CreateChef = ({ dispatch, navigate }) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [manualEdit, setManualEdit] = useState(false)
    const contactMethods = [{ label: 'Email', value: 'EMAIL' }, { label: 'Phone', value: 'PHONE' }]

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/chefs', 'CREATE'), formData, formId))
    }

    const successFX = (chefId) => {
        navigate(buildRoutePath(routes.adminChefsView.path, { chefId }))
    }

    const handleNameChange = (key, val) => {
        if (key === 'firstName') {
            setFirstName(val)
            if (!manualEdit) {
                setDisplayName(`${val} ${lastName}`)
            }
        } else if (key === 'lastName') {
            setLastName(val)
            if (!manualEdit) {
                setDisplayName(`${firstName} ${val}`)
            }
        } else if (key === 'displayName') {
            setDisplayName(val)
            setManualEdit(true)
        }
    }

    return (
        <>
            <div className="section-header">
                <span className="title">Create Chef</span>
            </div>
            <p>Use the form below to create a Chef.</p>
            <div className="form-wrapper">
                <SHSForm submitFX={submitFX} successFX={successFX}>
                    <InputElement
                        type="text"
                        id="firstName"
                        label="First Name"
                        placeholder="Julia"
                        extraClass="flex-33"
                        default={firstName}
                        onChange={handleNameChange}
                        required />
                    <InputElement
                        type="text"
                        id="lastName"
                        label="Last Name"
                        placeholder="Child"
                        extraClass="flex-33"
                        default={lastName}
                        onChange={handleNameChange}
                        required />
                    <InputElement
                        type="text"
                        id="displayName"
                        label="Display Name"
                        placeholder="The Julia Child"
                        default={displayName}
                        onChange={handleNameChange}
                        extraClass="flex-33" />
                    <InputElement
                        type="text"
                        id="phone"
                        label="Phone Number"
                        placeholder="(555) 123-4567"
                        isFormatted={true}
                        formatMask={MASKS.phoneMask}
                        extraClass="flex-33"
                        required />
                    <InputElement
                        type="email"
                        id="email"
                        label="Email Address"
                        placeholder="user@example.com"
                        extraClass="flex-33"
                        required />
                    <InputElement
                        type="valueSelect"
                        id="contactMethod"
                        label="Preferred Contact Method"
                        includeInitialValue={false}
                        options={contactMethods}
                        extraClass="flex-33"
                        required />
                    <InputElement
                        type="text"
                        id="title"
                        label="Title"
                        placeholder="Chef extrodinaire"
                        extraClass="flex-33" />
                    <InputElement
                        type="text"
                        id="pronouns"
                        label="Pronouns"
                        placeholder="he/him, she/her, etc"
                        extraClass="flex-33" />
                    <InputElement
                        type="text"
                        id="pronounciation"
                        label="Pronounciation"
                        extraClass="flex-33" />
                    <InputElement
                        type="textarea"
                        id="shortBio"
                        label="Short Bio"
                        placeholder="Brief summary of chef experience..." />
                </SHSForm>
            </div>
        </>
    )
}

export default withRouterProps(connect()(CreateChef))
