import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { resetPasswordWithCode } from '../../../actions/auth'
import { removeAuthHandler } from '../../../reducers/auth-code'
import { routes } from '../../../constants/routes'
import { isEmpty } from 'lodash'

import VerifyPassword from '../../utils/verify-password'
import { upsertForm } from '../../../reducers/form'
import { formStates } from '../../../constants/helper-states'

const mapStateToProps = (state) => {
    return {
        authCode: state.authCode
    }
}

const PasswordReset = ({ authCode, dispatch, fireauth }) => {
    const [isMissingCode, setIsMissingCode] = useState(false)
    const [allValid, setAllValid] = useState(false)

    useEffect(() => {
        if (isEmpty(authCode)) {
            setIsMissingCode(true)
            dispatch(removeAuthHandler())
        }
    }, [authCode, dispatch])

    const handleValidationChange = (isValid) => {
        setAllValid(isValid)
    }


    const submitFX = (formData, formId) => {
        if (allValid && formData.password === formData.confirmPassword) {
            dispatch(resetPasswordWithCode(fireauth, authCode.authCode, authCode.authTempEmail, formData.password, formId))
        } else {
            dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: 'Validation failed. Please check you followed the password requirements.' }))
        }
    }

    const successFX = () => {
        // handle by auth router
    }

    if (isMissingCode) {
        return <Navigate to={routes.authLogin.path} />
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <h1>Reset Your Password</h1>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Reset Password" buttonClass="black center" buttonDisabled={!allValid}>
                        <InputElement
                            default={authCode.authTempEmail}
                            label="Email"
                            extraClass="flex-100"
                            readOnly />
                        <VerifyPassword onValidationChange={handleValidationChange} />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(PasswordReset)
