import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded, callApi } from '../../../../actions/api'
import { apiResourceEndpoint, routes, buildRoutePath } from '../../../../constants/routes'

import MenuTitle from './menu-title'
import MenuSections from './menu-sections'

const mapStateToProps = (state, ownProps) => {
    return {
        menu: state.adminMenus.find(am => am.id === ownProps.params.menuId)
    }
}

const ViewMenu = ({ dispatch, params, menu, navigate }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/menus', 'VIEW', params.menuId)))
    }, [dispatch, params.menuId])

    const handleDelete = () => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${menu.title}`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/menus', 'DELETE', menu.id), {}, -1))
        }
        navigate(buildRoutePath(routes.adminMenusIndex.path))
    }

    if (!menu) {
        return null
    }

    return (
        <div>
            <h2>View and Manage Menu</h2>
            <div className="box menu-manager">
                <MenuTitle menu={menu} />
                <MenuSections menu={menu} />
                <div className="btn" onClick={handleDelete}>
                Delete Menu
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewMenu))
