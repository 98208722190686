import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        groceryMeta: state.ticketGroceryMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const GroceryBlock = ({ ticket, groceryMeta }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketGrocery} config="GROCERIES" ticket={ticket} isDeletable={true}>
            {/* <DisplayValue label="Grocery Count" value={`${ticket._computed.groceryCount} Item${ticket._computed.groceryCount === 1 ? '' : 's'}`} /> */}
            <DisplayValue label="Notes" value={groceryMeta.notes ? groceryMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(GroceryBlock)
