import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import * as MASKS from '../../../utils/input-masks'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        contacts: state.ticketContacts
            .filter(ti => ti.ticketId === Number(ownProps.params.ticketId))
            .sort((a, b) => {
                if (a.firstName > b.firstName) {
                    return 1
                }
                return -1
            })
    }
}

const TicketContact = ({ dispatch, params, ticket, contacts }) => {
    const [showForm, setShowForm] = useState(false)
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-contact', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const handleDelete = (contact) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this contact?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-contact', 'DELETE', contact.id), {}, -1))
        }
    }

    const renderContact = () => {
        let allContacts = []
        contacts.forEach(contact => {
            allContacts.push(
                <div key={contact.id} className="item">
                    <EditableDisplayField
                        label="First Name"
                        display={contact.firstName}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'firstName'
                        }]} />
                    <EditableDisplayField
                        label="Last Name"
                        display={contact.lastName}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'lastName'
                        }]} />
                    <EditableDisplayField
                        label="Email"
                        display={contact.email}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'email',
                            field: 'email'
                        }]} />
                    <EditableDisplayField
                        label="Phone"
                        display={MASKS.phoneMask.resolve(contact.phone)}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'phone',
                            isFormatted: true,
                            formatMask: MASKS.phoneMask
                        }]} />
                    <EditableDisplayField
                        label="Is Client"
                        display={contact.isClient ? 'Yes' : 'No'}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'isClient',
                            options: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
                        }]} />
                    <EditableDisplayField
                        label="Primary Contact"
                        display={contact.isPointOfContact ? 'Yes' : 'No'}
                        dataObj={contact}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'isPointOfContact',
                            options: [{ label: 'Yes', value: true }, { label: 'No', value: false }]
                        }]} />
                    <span className="remove" title="Delete" onClick={() => handleDelete(contact)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return <div className="ticket-items">{allContacts}</div>
    }

    const submitFX = (formData, formId) => {
        formData.ticketId = params.ticketId
        dispatch(callApi(apiResourceEndpoint('admin/ticket-contact', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)}>
                        <InputElement
                            id="firstName"
                            label="First Name"
                            type="text"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="lastName"
                            label="Last Name"
                            type="text"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="email"
                            label="Email"
                            type="email"
                            extraClass="flex-33"
                            required />
                        <InputElement
                            id="phone"
                            label="Phone"
                            type="phone"
                            isFormatted={true}
                            formatMask={MASKS.phoneMask}
                            extraClass="flex-33"
                            required />
                        <InputElement
                            type="valueSelect"
                            id="isClient"
                            label="Is Client"
                            options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                            extraClass="flex-33"
                            required />
                        <InputElement
                            type="valueSelect"
                            id="isPointOfContact"
                            label="Primary Contact"
                            options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                            extraClass="flex-33"
                            required />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Contact +
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderContact()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketContact))
