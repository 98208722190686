import React from 'react'
import moment from 'moment'

import * as MASKS from './input-masks'

export const formatDate = (date, showRelative = false) => {
    let dateString = moment(date).format('MMM DD, Y')
    if (showRelative) {
        let relative = moment(date).fromNow()
        dateString = dateString + ` (${relative})`
    }
    return dateString
}

export const formatTime = (time) => {
    let ampm = 'am'
    let [hour, minute] = time.split(':')
    if (hour > 12) {
        hour = hour - 12
        ampm = 'pm'
    }
    return `${hour}:${minute} ${ampm}`
}

export const valueFromConfig = (options, id, field) => {
    const item = options.find(o => o.id === id)
    if (item && field in item) {
        return item[field]
    }
    return null
}

export const formatEmail = (email) => {
    if (!email) {
        return null
    }
    return <a href={`mailto:${email}`}>{email}</a>
}

export const formatPhone = (phone) => {
    if (!phone) {
        return null
    }
    return <a href={`tel:${phone}`}>{MASKS.phoneMask.resolve(phone)}</a>
}
