import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        contacts: state.ticketContacts.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const ContactBlock = ({ ticket, contacts }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketContact} config="CONTACT" ticket={ticket}>
            <DisplayValue label="Primary Contact" value={contacts.isPointOfContact ? contacts.firstName + ' ' + contacts.lastName : contacts.firstName + ' ' + contacts.lastName} extraClass="half" />
            <DisplayValue label="Email" value={<a href={`mailto:${contacts.email}`}>{contacts.email}</a>} extraClass="half" />
            <DisplayValue label="Phone" value={<a href={`tel:${contacts.phone}`}>{contacts.phone}</a>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(ContactBlock)
