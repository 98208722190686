import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DisplayValue from '../../../utils/display-value'

import { formatEmail, formatPhone } from '../../../utils/data-formatters'

const LeadPersonalInfo = (props) => {
    const { leadForm } = props

    return (
        <div className="event-block">
            <div className="block-type">
                <FontAwesomeIcon icon="user" /> Submitter
            </div>
            <div className="content">
                <div className="displays">
                    <DisplayValue label="Name" value={`${leadForm.firstName} ${leadForm.lastName}`} />
                    <DisplayValue label="Email" value={formatEmail(leadForm.email)} />
                    <DisplayValue label="Phone" value={formatPhone(leadForm.phone)} />
                    <DisplayValue label="Linked Account" value={leadForm.associatedUserId} />
                </div>
            </div>
            <div className="actions">
                &nbsp;
            </div>
        </div>
    )
}

export default LeadPersonalInfo
