import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { routes } from '../../../../constants/routes'

import TicketBlock from '../parts/ticket-block'
import DisplayValue from '../../../utils/display-value'

import { formatTime } from '../../../utils/formatters'

const mapStateToProps = (state) => {
    return {
        apiCuisines: state.apiCuisines,
        apiServiceStyles: state.apiServiceStyles,
        apiVibes: state.apiVibes
    }
}

const OverviewBlock = ({ ticket, apiCuisines, apiServiceStyles, apiVibes }) => {
    const cuisine = apiCuisines.find(ac => ac.id === ticket.apiCuisineTypeId)
    const service = apiServiceStyles.find(as => as.id === ticket.apiServiceStyleId)
    const vibe = apiVibes.find(av => av.id === ticket.apiVibeId)

    const renderAddress = () => {
        let address = ''
        if (ticket.street) {
            address = address + ' ' + ticket.street
        }
        if (ticket.city) {
            address = address + ' ' + ticket.city
        }
        if (ticket.state) {
            address = address + ' ' + ticket.state
        }
        if (ticket.postal) {
            address = address + ' ' + ticket.postal
        }
        return address.trim()
    }

    return (
        <TicketBlock routeObj={routes.adminTicketOverview} config="OVERVIEW" ticket={ticket}>
            <DisplayValue label="Event Name" value={ticket.eventName} extraClass="half" />
            <DisplayValue label="Location" value={renderAddress()} extraClass="half" />
            <DisplayValue label="Event Date" value={moment(ticket.eventDate).format('ddd. MMM Do, Y')} extraClass="half" />
            <DisplayValue label="Event Time" value={formatTime(ticket.eventTime)} extraClass="half" />
            <DisplayValue label="Service Style" value={cuisine?.name} extraClass="half expanded-only" />
            <DisplayValue label="Cuisine Type" value={service?.name} extraClass="half expanded-only" />
            <DisplayValue label="Vibe" value={vibe?.name} extraClass="half expanded-only" />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(OverviewBlock)
