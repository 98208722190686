import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        sousChefs: state.ticketSousChefs.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketSousChef = ({ sousChefs }) => {
    const renderSousChefs = () => {
        let allSousChefs = []
        sousChefs.forEach(sc => {
            allSousChefs.push(
                <tr key={sc.id}>
                    {/* <td>{menu.apiMenuId}</td>
                    <td>{menu.template}</td> */}
                    <td>{sc.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Name</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allSousChefs}
                </tbody>
            </table>
        )
    }

    if (!sousChefs || sousChefs.length === 0) {
        return null
    }

    return (
        <div className="section ticket-timeline">
            <h2>Sous Chef</h2>
            {renderSousChefs()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketSousChef)
