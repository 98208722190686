import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../../components/utils/shs-form'
import InputElement from '../../../components/utils/input'
import EditableDisplayField from '../../../components/utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        details: state.chefDetails.filter(c => c.chefId === state.user?.apiUserId)
    }
}

const ChefDetails = ({ dispatch, chef, details }) => {
    const [showForm, setShowForm] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-details', 'LIST')))
    }, [dispatch])

    const renderDetailsList = () => {
        let allDetails = []
        details.forEach(detail => {
            allDetails.push(
                <div className="field-wrappers flex-100" key={detail.id}>
                    <EditableDisplayField
                        label="Label"
                        display={detail.label}
                        dataObj={detail}
                        endpoint="chefs/chef-details"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'label'
                        }]} />
                    <EditableDisplayField
                        label="Content"
                        display={detail.content}
                        dataObj={detail}
                        endpoint="chefs/chef-details"
                        inTable={true}
                        fields={[{
                            type: 'textarea',
                            field: 'content'
                        }]} />
                </div>
            )
        })
        return allDetails
    }

    const successFX = () => {
        setShowForm(false)
    }

    const submitFX = (formData, formId) => {
        formData.chefId = chef.id
        dispatch(callApi(apiResourceEndpoint('chefs/chef-details', 'CREATE'), formData, formId))
    }

    const renderAddContact = () => {
        if (showForm) {
            return (
                <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)}>
                    <InputElement
                        type="text"
                        id="label"
                        label="Section Label"
                        required />
                    <InputElement
                        type="textarea"
                        id="content"
                        label="Content"
                        required />
                </SHSForm>
            )
        }
        return (
            <div className="clickable" onClick={() => setShowForm(true)}>
                + Add Section
            </div>
        )
    }

    return (
        <div className="bio-content">
            <h3>Profile Sections</h3>
            {renderDetailsList()}
            {renderAddContact()}
        </div>
    )
}

export default connect(mapStateToProps)(ChefDetails)
