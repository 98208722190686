import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { closeDrawer } from '../../../reducers/ui-drawer'

const mapStateToProps = (state, ownProps) => {
    return {
        serviceStyles: state.apiServiceStyles.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}

const EditServiceStyles = ({ dispatch, chef, serviceStyles }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
    }, [dispatch])

    const handleServiceStyleToggle = (serviceStyleId, isActive) => {
        if (isActive) {
            dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id, 'detach-service-style'), { serviceStyleId }, -1))
        } else {
            dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id, 'attach-service-style'), { serviceStyleId }, -1))
        }
    }

    const renderServiceStyles = () => {
        let activeSS = serviceStyles.filter(ss => chef._computed.serviceStyleId.indexOf(ss.id) !== -1)
        let activeRendered = []
        let allRendered = []
        activeSS.forEach(ss => {
            activeRendered.push(
                <div className="allergen active" key={ss.id}>
                    {ss.name}
                </div>
            )
        })
        serviceStyles.forEach(ss => {
            const isActive = chef._computed.serviceStyleId.indexOf(ss.id) !== -1 ? true : false
            allRendered.push(
                <div key={ss.id} className={`allergen ${isActive ? 'active' : ''}`} onClick={() => handleServiceStyleToggle(ss.id, isActive)}>
                    {ss.name}
                </div>
            )
        })
        return (
            <div className="allergen-options">
                {allRendered}
            </div>
        )
    }

    if (!chef) {
        return null
    }

    return (
        <div>
            <h3>Service Styles</h3>
            {renderServiceStyles()}
            <span className="clickable" onClick={() => dispatch(closeDrawer())}>Close</span>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(EditServiceStyles))
