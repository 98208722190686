import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { withRouterProps } from '../../utils/with-router-props'

import LeadHeader from './parts/lead-header'
import LeadEventInfo from './parts/lead-event-info'
import LeadPersonalInfo from './parts/lead-personal-info'

const mapStateToProps = (state, ownProps) => {
    return {
        leadForm: state.leadForms.find(lf => lf.id === Number(ownProps.params.leadId))
    }
}

const ViewLead = (props) => {
    const { dispatch, leadForm, params } = props

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/lead-forms', 'VIEW', params.leadId)))
    }, [dispatch, params.leadId])

    if (!leadForm) {
        return null
    }

    return (
        <>
            <LeadHeader leadForm={leadForm} />
            <LeadEventInfo leadForm={leadForm} />
            <LeadPersonalInfo leadForm={leadForm} />
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewLead))
