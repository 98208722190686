import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { Link } from 'react-router-dom'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { openDrawer } from '../../../../reducers/ui-drawer'
import { buildRoutePath, routes } from '../../../../constants/routes'
import SHSForm from '../../../utils/shs-form'
import Modal from '../../../utils/modal'
import TicketModal from './ticket-modal'

const mapStateToProps = (state) => {
    return {
        uiDrawer: state.uiDrawer
    }
}

const TicketHeader = ({ dispatch, ticket, uiDrawer, navigate, params }) => {
    const [ showMenu, setShowMenu ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const menuRef = useRef(null)

    useEffect(() => {
        const handleOutsideClick = (evt) => {
            if (menuRef.current && !menuRef.current.contains(evt.target)) {
                setShowMenu(false)
            }
        }
        document.addEventListener('click', handleOutsideClick, true)
        return () => {
            document.removeEventListener('click', handleOutsideClick, true)
        }
    }, [])

    const handleOpenDrawer = () => {
        dispatch(openDrawer({
            ticketId: ticket.id,
            category: 'GENERAL'
        }))
    }

    const handleNavigate = () => {
        navigate(buildRoutePath(routes.adminTicketsView.path, { ticketId: ticket.id }))
    }

    const handleMenuClick = (evt) => {
        setShowMenu(!showMenu)
    }

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/tickets', 'UPDATE', params.ticketId, 'manage-sections'), formData, formId))
        setShowModal(false)
    }

    const cancelAction = () => {
        setShowModal(false)
    }

    return (
        <div className="ticket-header">
            <div className="info">
                <h1 onClick={handleNavigate}>
                    {ticket.eventName}
                </h1>
                <span className="date">
                    {moment(ticket.eventDate).format('MMMM, D y')}
                </span>
                <span className="status">{ticket._computed.statusDisplay}</span>
            </div>
            <div className="actions">
                <span ref={menuRef} className="lead-action" title="Menu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon="ellipsis-v" />
                    { showMenu &&
                        <div className="ticket-menu">
                            <span onClick={() => setShowModal(true)}>
                                <FontAwesomeIcon icon={'plus'} /> Add New Block
                            </span>
                            <Link to={buildRoutePath(routes.sharedChefTicketPreview.path, { ticketCode: `${ticket.obfuscatedId}-${ticket.chefCode}` })} target="_blank">
                                <FontAwesomeIcon icon={['far', 'file-pdf']} /> View Summary
                            </Link>
                            <Link to={buildRoutePath(routes.sharedChefTicketPreview.path, { ticketCode: `${ticket.obfuscatedId}-${ticket.chefCode}` })} target="_blank">
                                <FontAwesomeIcon icon={['far', 'file-pdf']} />Chef Summary
                            </Link>
                            <Link to={buildRoutePath(routes.sharedClientTicketPreview.path, { ticketCode: `${ticket.clientCode}-${ticket.obfuscatedId}` })} target="_blank">
                                <FontAwesomeIcon icon={['far', 'file-pdf']} /> Client Summary
                            </Link>
                            <span>
                                <FontAwesomeIcon icon={['far', 'clone']} /> Clone
                            </span>
                            <span>
                                <FontAwesomeIcon icon={['far', 'trash-can']} /> Delete
                            </span>
                        </div>
                    }
                </span>
                <span className="lead-action" title="Notes" onClick={handleOpenDrawer}>
                    <FontAwesomeIcon icon={['far', 'sticky-note']} />
                </span>
            </div>
            {showModal &&
            <Modal closeAction={() => setShowModal(false)}>
                <h3>Add New Section(s)</h3>
                <SHSForm submitFX={submitFX} successFX={cancelAction} showCancel={true} cancelAction={cancelAction} >
                    <TicketModal ticket={ticket} />
                </SHSForm>
            </Modal>}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketHeader))
