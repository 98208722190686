import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchApiDataIfNeeded } from '../../actions/api'
import { apiResourceEndpoint, routes } from '../../constants/routes'

const mapStateToProps = (state) => {
    return {
        photo: state.chefPhotos.find(cp => cp.isProfile)
    }
}

const ChefPortalNav = ({ chef, dispatch, photo }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-photos', 'LIST')))
    }, [dispatch])

    return (
        <div id="admin-nav">
            <div className="logo">
                <Link to={routes.chefPortalIndex.path}>
                    <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" height="90" />
                </Link>
            </div>
            <div className="admin-menu">
                {photo ?
                    <>
                        <img className="chef-photo" src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.photoId}`, 'LIST').url + '?style=sm-square'} alt="Profile" />
                    </> :
                    <>
                        <div className="chef-photo">
                            <span className="initials">
                                {chef.firstName[0]}{chef.lastName[0]}
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(ChefPortalNav)
