import React, { useEffect } from 'react'
import { withRouterProps } from '../utils/with-router-props'
import { connect } from 'react-redux'

import { callPublicApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

const mapStateToProps = (state, ownProps) => {
    return {
        chef: state.chefs.find(c => c.id === ownProps.params.chefId),
        chefPhotos: state.chefPhotos.filter(cp => cp.chefId === ownProps.params.chefId)
    }
}

const ChefPreview = ({ dispatch, chef, chefPhotos, params }) => {
    // TODO: Load the chef based on the id in the URL
    useEffect(() => {
        dispatch(callPublicApi(apiResourceEndpoint('public/api-chefs/', 'VIEW', params.chefId), {}))
    }, [dispatch, params.chefId])

    const renderHeadshot = () => {
        let headshot = chefPhotos.find(cp => cp.isProfile)
        if (headshot) {
            return (
                <img
                    src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${headshot.photoId}`, 'LIST').url + '?style=md-square'}
                    alt={chef.displayName}
                />
            )
        }
        return null
    }

    const renderTags = () => {
        let hasTags = false
        let cuisineTags = []
        let serviceTags = []
        if (chef._computed?.cuisineNames && chef._computed.cuisineNames.length !== 0) {
            hasTags = true
            cuisineTags.push(
                <label key="cuisine-label">
                    Specialties
                </label>
            )
            chef._computed.cuisineNames.forEach((cuisine, idx) => {
                cuisineTags.push(
                    <div key={idx}>
                        {cuisine}
                    </div>
                )
            })
        }
        if (chef._computed?.serviceStyleNames && chef._computed.serviceStyleNames.length !== 0) {
            hasTags = true
            serviceTags.push(
                <label key="services-label">
                    Services
                </label>
            )
            chef._computed.serviceStyleNames.forEach((service, idx) => {
                serviceTags.push(
                    <div key={idx}>
                        {service}
                    </div>
                )
            })
        }
        if (!hasTags) {
            return null
        }
        return (
            <div className="tags">
                { cuisineTags }
                { serviceTags }
            </div>
        )
    }

    if (!chef) {
        return 'Loading...'
    }

    // let headshot = chefPhotos.find(cp => cp.isProfile)
    // const bgPhotoUrl = process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${headshot.photoId}`, 'LIST').url + '?style=md-square'

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/place-setting.jpg")' }} />
            {/* <div className="full-page-bg-img" style={{ backgroundImage: 'url("' + bgPhotoUrl + '")' }} /> */}
            <div className="preview-holder chef">
                <div className="photo">
                    { renderHeadshot() }
                </div>
                <div className="content">
                    <h1>{chef.displayName ? chef.displayName : <>{chef.firstName} {chef.lastName}</>}</h1>
                    <div className="details">
                        <div className="body p-wrap">
                            { chef.shortBio }
                        </div>
                        { renderTags() }
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(ChefPreview))
