import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'

import Create from './create'
import View from './view'
import List from './list'

import '../../../assets/scss/photos.scss'

const PhotosIndex = () => {
    return (
        <>
            <Routes>
                <Route path={nestedRoutePath(routes.adminPhotosIndex.path, routes.adminPhotosCreate.path)} element={<Create />} />
                <Route path={nestedRoutePath(routes.adminPhotosIndex.path, routes.adminPhotosView.path)} element={<View />} />
                <Route index element={<List />} />
            </Routes>
        </>
    )
}

export default PhotosIndex
