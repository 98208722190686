import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes, nestedRoutePath } from '../../../../constants/routes'

import CreateMenu from './create'
import ViewMenu from './view'
import ListMenus from './list'

import '../../../../assets/scss/menus.scss'

const MenusMenuIndex = (props) => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.adminMenusIndex.path, routes.adminMenusCreate.path)} element={<CreateMenu />} />
            <Route path={nestedRoutePath(routes.adminMenusIndex.path, routes.adminMenusView.path)} element={<ViewMenu />} />
            <Route index element={<ListMenus />} />
        </Routes>
    )
}

export default MenusMenuIndex
