import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { closeDrawer } from '../../../reducers/ui-drawer'

const mapStateToProps = (state, ownProps) => {
    return {
        cuisines: state.apiCuisines.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}

const EditCuisines = ({ dispatch, chef, cuisines }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
    }, [dispatch])

    const handleCuisineToggle = (cuisineId, isActive) => {
        if (isActive) {
            dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id, 'detach-cuisine'), { cuisineId }, -1))
        } else {
            dispatch(callApi(apiResourceEndpoint('chefs', 'UPDATE', chef.id, 'attach-cuisine'), { cuisineId }, -1))
        }
    }

    const renderCuisines = () => {
        let activeCuisines = cuisines.filter(cuisine => chef._computed.cuisineId.indexOf(cuisine.id) !== -1)
        let activeRendered = []
        let allRendered = []
        activeCuisines.forEach(cuisine => {
            activeRendered.push(
                <div className="allergen active" key={cuisine.id}>
                    {cuisine.name}
                </div>
            )
        })
        cuisines.forEach(cuisine => {
            const isActive = chef._computed.cuisineId.indexOf(cuisine.id) !== -1 ? true : false
            allRendered.push(
                <div key={cuisine.id} className={`allergen ${isActive ? 'active' : ''}`} onClick={() => handleCuisineToggle(cuisine.id, isActive)}>
                    {cuisine.name}
                </div>
            )
        })
        return (
            <div className="allergen-options">
                {allRendered}
            </div>
        )
    }

    if (!chef) {
        return null
    }

    return (
        <div>
            <h3>Specialties</h3>
            {renderCuisines()}
            <span className="clickable" onClick={() => dispatch(closeDrawer())}>Close</span>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(EditCuisines))
