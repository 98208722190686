import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

const mapStateToProps = (state, ownProps) => {
    return {
        foodItem: state.adminFoodItems.find(fi => fi.id === ownProps.params.foodItemId),
        allergens: state.adminAllergens.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}

const AddAllergens = ({ dispatch, foodItem, allergens }) => {
    const [ showForm, setShowForm ] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/allergens', 'LIST')))
    }, [dispatch])

    const handleAllergenToggle = (allergenId, isActive) => {
        if (isActive) {
            dispatch(callApi(apiResourceEndpoint('admin/food-items', 'UPDATE', foodItem.id, 'detach-allergen'), { allergenId }, -1))
        } else {
            dispatch(callApi(apiResourceEndpoint('admin/food-items', 'UPDATE', foodItem.id, 'attach-allergen'), { allergenId }, -1))
        }
    }

    const renderAllergens = () => {
        let activeAllergens = allergens.filter(allergen => foodItem._computed.allergenIds.indexOf(allergen.id) !== -1)
        let activeRendered = []
        let allRendered = []
        activeAllergens.forEach(allergen => {
            activeRendered.push(
                <div className="allergen active" key={allergen.id}>
                    {allergen.name}
                </div>
            )
        })
        allergens.forEach(allergen => {
            const isActive = foodItem._computed.allergenIds.indexOf(allergen.id) !== -1 ? true : false
            allRendered.push(
                <div key={allergen.id} className={`allergen ${isActive ? 'active' : ''}`} onClick={() => handleAllergenToggle(allergen.id, isActive)}>
                    {allergen.name}
                </div>
            )
        })
        return (
            <>
                <h2>Allergens</h2>
                { showForm
                    ? <>
                        <div className="allergen-options">
                            {allRendered}
                        </div>
                        <span className="clickable" onClick={() => setShowForm(false)}>Save</span>
                    </>
                    : <>
                        <div className="active-allergens">
                            {activeRendered.length > 0 ? activeRendered : <p><strong>No Allergens</strong></p>}
                        </div>
                        <span className="clickable" onClick={() => setShowForm(true)}>Manage Allergens</span>
                    </>
                }
            </>
        )
    }

    return (
        <div className="event-block">
            <div className="content">
                {renderAllergens()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AddAllergens))
