import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'

import EditableDisplayField from '../../../utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        groceryMeta: state.ticketGroceryMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        groceries: state.ticketGroceries.filter(tg => tg.ticketId === Number(ownProps.params.ticketId))
    }
}

const TicketGroceries = ({ dispatch, params, ticket, groceryMeta, groceries }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="shopping-cart" /> <span>Groceries</span>
                </div>
                <div className="meta-content">
                    {/* <span className="meta-value">Coming soon...</span> */}
                    {/* <span className="icon-btn"><FontAwesomeIcon icon="pen" /></span> */}
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'GROCERY' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderMetaNotes = () => {
        return (
            <div className="meta-manager">
                <EditableDisplayField
                    label="Notes"
                    display={groceryMeta.notes}
                    dataObj={groceryMeta}
                    endpoint="admin/ticket-grocery-meta"
                    extraClass="field name"
                    fields={[{
                        type: 'textarea',
                        field: 'notes'
                    }]} />
            </div>
        )
    }

    const renderGroceries = () => {
        groceries.map(grocery => {
            <div key={grocery.id}>Grocery Block??</div>
            return (
                <div className="category-blocks">
                    <div key={grocery.id}>Grocery Block??</div>
                    <div className="btn white bordered centered">Add Item +</div>
                </div>
            )
        })
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderMetaNotes()}
            {renderGroceries()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketGroceries))
