export const ALLERGEN_CONFIG = {
    MILK: {
        label: 'Milk',
        icon: 'cow'
    },
    EGGS: {
        label: 'Eggs',
        icon: 'egg'
    },
    FISH: {
        label: 'Fish',
        icon: 'fish'
    },
    SHELLFISH: {
        label: 'Shellfish',
        icon: 'shrimp'
    },
    TREE_NUTS: {
        label: 'Tree Nuts',
        icon: 'tree'
    },
    PEANUTS: {
        label: 'Peanuts',
        icon: 'seedling'
    },
    WHEAT: {
        label: 'Wheat',
        icon: 'wheat-awn-circle-exclamation'
    },
    SOY: {
        label: 'Soy',
        icon: 'sun-plant-wilt'
    }
}
