import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../../actions/api'

import { PaginationWrapper } from 'shs-pagination'

const mapStateToProps = (state) => {
    const paginator = state.paginator.adminMenus
    let menus = state.adminMenus
    if (paginator) {
        menus = menus
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return {
        menus,
        paginator
    }
}

const ListMenus = ({ dispatch, menus, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/menus', 'LIST')))
    }, [dispatch])

    const renderMenuRow = (menu, index) => {
        return (
            <div key={menu.id} className="paginated-item">
                <span className="index">{index}</span>
                <Link to={buildRoutePath(routes.adminMenusView.path, { menuId: menu.id })} className="main">
                    {menu.title}
                </Link>
            </div>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/menus', 'LIST'), paginationParams))
    }

    const renderMenusList = () => {
        if (!paginator) {
            return null
        }
        let allMenus = []
        menus.forEach((menu, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allMenus.push(renderMenuRow(menu, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}
                countOptions={[2, 5, 10, 20]}>
                {allMenus}
            </PaginationWrapper>
        )
    }

    return (
        <div>
            <h2>Menus List</h2>
            <Link to={routes.adminMenusCreate.path}>+ Create Menu</Link>
            {renderMenusList()}
        </div>
    )
}

export default connect(mapStateToProps)(ListMenus)
