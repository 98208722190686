import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { handleFirebaseAuthCode } from '../../../actions/auth'
import { routes } from '../../../constants/routes'

const mapStateToProps = (state) => {
    return {
        authCode: state.authCode
    }
}

const AuthHandler = ({ fireauth, dispatch, authCode }) => {
    const hasFireAuth = fireauth ? true : false
    const urlParams = new URLSearchParams(window.location.search)
    const urlCode = urlParams.get('oobCode')
    const actionType = urlParams.get('mode')

    useEffect(() => {
        if (hasFireAuth) {
            dispatch(handleFirebaseAuthCode(fireauth, urlCode, actionType))
        }
    }, [hasFireAuth, dispatch, fireauth, urlCode, actionType])

    if (authCode && ('authCode' in authCode)) {
        switch (actionType) {
        case 'resetPassword':
            return <Navigate to={routes.authPasswordReset.path} />
        default:
            return null
        }
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="content">
                        { authCode && authCode.hasError
                            ? (
                                <div className="alert alert-danger w-100 text-center">
                                    <p>
                                        There was a problem with the reset password token.<br />
                                        It may have expired or already been used. Please try again.
                                    </p>
                                    <Link to={routes.authLogin.path}>Return to Login</Link>
                                </div>
                            )
                            : <div>Validating the authorization code...</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(AuthHandler)
