import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'

import AdminDrawer from '../layout/drawer'

import View from './view'
import List from './list'
import Overview from './overview/main'
import Guests from './guests/main'
import Locations from './locations/main'
import Menu from './menus/main'
import Chefs from './chefs/main'
import Groceries from './groceries/main'
import Inventory from './inventory/main'
import Servers from './servers/main'
import SousChefs from './sous-chefs/main'
import Travel from './travel/main'
import Budget from './budget/main'
import Timeline from './timeline/main'
import Contact from './contact/main'

import '../../../assets/scss/tickets.scss'

const EventsIndex = () => {
    return (
        <>
            <Routes>
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketsView.path)} element={<View />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketOverview.path)} element={<Overview />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketContact.path)} element={<Contact />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketGuests.path)} element={<Guests />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketVenue.path)} element={<Locations />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketMenu.path)} element={<Menu />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketChef.path)} element={<Chefs />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketGrocery.path)} element={<Groceries />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketServer.path)} element={<Servers />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketSousChef.path)} element={<SousChefs />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketTravel.path)} element={<Travel />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketInventory.path)} element={<Inventory />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketCost.path)} element={<Budget />} />
                <Route path={nestedRoutePath(routes.adminTicketsIndex.path, routes.adminTicketTimeline.path)} element={<Timeline />} />
                <Route index element={<List />} />
            </Routes>
            <AdminDrawer endpoint="ticket-notes" />
        </>
    )
}

export default EventsIndex
