import React from 'react'

const ChefPortalTickets = () => {
    return (
        <div>
            <h1>I am the Chef Tickets Index</h1>
        </div>
    )
}

export default ChefPortalTickets
