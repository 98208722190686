import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { fetchApiDataIfNeeded, callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import MenuCard from './menu-card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'

import EditableDisplayField from '../../../utils/editable-display-field'

import SearchComplete from '../../../utils/search-complete'

const mapStateToProps = (state, ownProps)=> {
    const ticketMenus = state.ticketMenus.filter(tm => tm.ticketId === Number(ownProps.params.ticketId))
    const ticketMenuIds = ticketMenus.map(tm => tm.apiMenuId)
    const menus = state.adminMenus.filter(adminMenu => {
        return ticketMenuIds.indexOf(adminMenu.id) > -1
    })
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        menuMeta: state.ticketMenuMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        menus,
        ticketMenus,
        ticketMenuIds
    }
}

const TicketMenus = ({ dispatch, params, ticket, menuMeta, menus, ticketMenus, ticketMenuIds }) => {
    const [showAddForm, setShowAddForm] = useState(false)
    const ticketMenuIdString = ticketMenuIds.join(',')

    const renderMenuResult = (result) => {
        return (
            <>
                {result.title}
            </>
        )
    }

    const handleSelectResult = (result) => {
        const formData = {
            ticketId: ticket.id,
            apiMenuId: result.id
        }
        dispatch(callApi(apiResourceEndpoint('admin/ticket-menus', 'CREATE'), formData, -1))
        setShowAddForm(false)
    }

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-menus', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
    }, [dispatch, params.ticketId])

    useEffect(() => {
        if (ticketMenuIdString) {
            let filterData = ticketMenuIdString.split(',').map(id => `id|${id}`)
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/menus', 'LIST'), { filter: filterData, filterMethod: 'OR' }))
        }
    }, [dispatch, ticketMenuIdString])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="carrot" /> <span>Menus</span>
                </div>
                <div className="meta-content">
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'MENUS' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderMetaNotes = () => {
        return (
            <div className="meta-manager">
                <EditableDisplayField
                    label="Notes"
                    display={menuMeta.notes}
                    dataObj={menuMeta}
                    endpoint="admin/ticket-menu-meta"
                    extraClass="field name"
                    inTable={true}
                    fields={[{
                        type: 'textarea',
                        field: 'notes'
                    }]} />
            </div>
        )
    }

    const renderAddMenu = () => {
        if (showAddForm) {
            return (
                <div className="item">
                    <SearchComplete
                        placeholder="Search for a Menu"
                        searchEndpoint="admin/menus"
                        renderTemplate={renderMenuResult}
                        onHandleSelect={handleSelectResult}
                        allowCreation={false} />
                    <span onClick={() => setShowAddForm(false)}>Cancel</span>
                </div>
            )
        }
        return (
            <div className="btn menu-block add-menu-item mb-3" onClick={() => setShowAddForm(true)}>
                + Add a Menu
            </div>
        )
    }


    const renderMenus = () => {
        let allMenus = []
        menus.forEach(menu=> {
            allMenus.push(
                <div key={menu.id} className="cards list" >
                    <MenuCard menu={menu} dispatch={dispatch} ticketMenus={ticketMenus} />
                </div>
            )
        })
        return (
            <div className="ticket-items">
                {allMenus}
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderMenus()}
            {renderAddMenu()}
            {renderMetaNotes()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketMenus))
