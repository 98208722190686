import React from 'react'
import { connect } from 'react-redux'

import SectionTitle from './section-title'
import MenuItems from './menu-items'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

const mapStateToProps = (state, ownProps) => {
    return {
        sections: state.adminMenuSections.filter(as => as.menuId === ownProps.menu.id)
            .sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                return -1
            })
    }
}

const MenuSections = ({ dispatch, menu, sections }) => {
    const addSection = (sectionOrder) => {
        const formData = {
            menuId: menu.id,
            order: sectionOrder + 1
        }
        dispatch(callApi(apiResourceEndpoint('admin/menu-sections', 'CREATE'), formData, -1))
    }

    const renderAddSection = (sectionOrder) => {
        return (
            <div className="menu-block add-menu-section" onClick={() => addSection(sectionOrder)}>
                + Add a Section
            </div>
        )
    }

    const renderSections = () => {
        let allSections = []
        sections.forEach(section => {
            allSections.push(
                <div key={section.id}>
                    <div className="menu-block section">
                        <div className="block-details">
                            Section
                        </div>
                        <SectionTitle section={section} />
                        <MenuItems menu={menu} section={section} />
                    </div>
                    {renderAddSection(section.order)}
                </div>
            )
        })
        return allSections
    }

    return (
        <div className="sections-wrapper">
            {renderSections()}
        </div>
    )
}

export default connect(mapStateToProps)(MenuSections)
