import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        guestMeta: state.ticketGuestMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const GuestBlock = (props) => {
    const { ticket, guestMeta } = props

    return (
        <TicketBlock routeObj={routes.adminTicketGuests} config="GUESTS" ticket={ticket}>
            <DisplayValue label="Estimated Headcount" value={guestMeta.estimatedHeadcount} extraClass="half" />
            <DisplayValue label="Using Invitations" value={guestMeta.isUsingInvitations ? 'Yes' : 'No'} extraClass="half expanded-only" />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(GuestBlock)
