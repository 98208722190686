import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        venueMeta: state.ticketVenueMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const LocationBlock = ({ ticket, venueMeta }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketVenue} config="VENUES" ticket={ticket}>
            <DisplayValue label="Notes" value={venueMeta.notes ? venueMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(LocationBlock)
