import { initializeApp } from 'firebase/app'

import { FB_CONFIG } from './config'

const initFirebase = () => {
    const app = initializeApp(FB_CONFIG)
    return app
}

const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        {
            // provider: fireauth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
            }
        }
    ]
}

const actionCodeSettings = {
    url: 'http://localhost:3000/password-reset/',
    handleCodeInApp: true
}

export {
    initFirebase,
    uiConfig,
    actionCodeSettings
}
