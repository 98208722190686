import moment from 'moment'
// return currency formatting
// TODO: need to work in some parametes to control the options...
export const formatCurrency = (value, digits = 2, min = 0) => {
    return parseFloat(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: min,
        maximumFractionDigits: digits
    })
}

// return a total with the numerical unit
// TODO: work in more parametes and controls...
export const formatTotalWithUnit = (total) => {
    let value = total
    let unit = ''
    let formatDigits = 3
    const digits = Math.ceil(Math.log10(total + 1))
    if (digits < 4) {
        formatDigits = 2
    } else if (digits >= 4 && digits < 7) {
        value = Math.round((total * 1000) / 1000) / 1000
        unit = 'k'
    } else if (digits >= 7 && digits < 10) {
        value = Math.round((total * 1000) / 1000000) / 1000
        unit = 'M'
    } else {
        value = Math.round((total * 1000) / 1000000000) / 1000
        unit = 'B'
    }
    return `${formatCurrency(value, formatDigits)}${unit}`
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const monthsToYears = (months) => {
    let years = removeInsignificantZeros(months / 12, 1)
    return `${years} Year${years === 0 ? '' : 's'}`
}

export const dateDisplay = (date, format = 'MM/DD/YYYY') => {
    return moment(date).format(format)
}

export const toDecimals = (num, decimals = 2) => {
    return Number(num).toFixed(decimals)
}

export const removeInsignificantZeros = (num, decimals = 2) => {
    return Number(num).toFixed(decimals).toString()
}

export const roundToUnit = (total) => {
    let value = total
    const digits = Math.ceil(Math.log10(total + 1))
    if (digits < 4) {
        // do nothing
    } else if (digits >= 4 && digits < 7) {
        value = Math.round((total * 1000) / 1000) / 1000 * 1000
    } else if (digits >= 7 && digits < 10) {
        value = Math.round((total * 1000) / 1000000) / 1000 * 1000000
    } else {
        value = Math.round((total * 1000) / 1000000000) / 1000 * 1000000000
    }
    return value
}

export const formatTime = (time) => {
    if (!time) {
        return ''
    }
    const timePieces = time.split(':')
    if (timePieces.length < 2) {
        return time
    }
    let hour = parseInt(timePieces[0], 10)
    return `${hour > 12 ? hour - 12 : hour === 0 ? 12 : hour }:${timePieces[1]} ${hour > 11 ? 'pm' : 'am'}`
}
