import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import InputElement from '../../../utils/input'


const mapStateToProps = (state, ownProps) => {
    return {
        sousChefMeta: state.ticketSousChefMeta.find(tscm => tscm.ticketId === Number(ownProps.ticket.id)),
        travelMeta: state.ticketTravelMeta.find(ttm => ttm.ticketId === Number(ownProps.ticket.id)),
        serverMeta: state.ticketServerMeta.find(tsm => tsm.ticketId === Number(ownProps.ticket.id)),
        groceryMeta: state.ticketGroceryMeta.find(tgm => tgm.ticketId === Number(ownProps.ticket.id))
    }
}

const TicketModal = ({ sousChefMeta, travelMeta, serverMeta, groceryMeta }) => {
    return (
        <div className="form-group">
            <div className="input-wrapper">
                <InputElement
                    id="sousChef"
                    type="checkbox"
                    label="Sous Chef"
                    extraClass={`check-form ${sousChefMeta && 'disabled'}`}
                />
                <InputElement
                    id="travel"
                    type="checkbox"
                    label="Travel"
                    extraClass={`check-form ${travelMeta && 'disabled'}`}
                />
                <InputElement
                    id="groceries"
                    type="checkbox"
                    label="Groceries"
                    extraClass={`check-form ${groceryMeta && 'disabled'}`}
                />
                <InputElement
                    id="server"
                    type="checkbox"
                    label="Servers"
                    extraClass={`check-form ${serverMeta && 'disabled'}`}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(withRouterProps(TicketModal))
