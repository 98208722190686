import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        travelMeta: state.ticketTravelMeta.find(ttm => ttm.ticketId === ownProps.ticket.id)
    }
}

const TravelBlock = ({ ticket, travelMeta }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketTravel} config="TRAVEL" ticket={ticket} isDeletable={true}>
            <DisplayValue label="Notes" value={travelMeta.notes ? travelMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(TravelBlock)
