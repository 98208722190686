import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MenusMenuIndex from './create/menus/index'
import FoodItemsIndex from './create/food-items/index'

const MenusIndex = () => {
    return (
        <>
            <Routes>
                <Route path={nestedRoutePath(routes.chefPortalMenusIndex.path, routes.chefPortalFoodItemsIndex.path, true)} element={<FoodItemsIndex />} />
                <Route path={nestedRoutePath(routes.chefPortalMenusIndex.path, routes.chefPortalMenusIndex.path, true)} element={<MenusMenuIndex />} />
            </Routes>
        </>
    )
}

export default MenusIndex
