import React from 'react'
import { Link } from 'react-router-dom'
import { buildRoutePath, routes } from '../../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { callApi } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'


const MenuCard = ({ menu, ticketMenus, dispatch }) => {
    const handleDelete = () => {
        const apiMenuId = ticketMenus.find(tm => tm.apiMenuId === menu.id).apiMenuId
        const ticketMenu = ticketMenus.find(tm => tm.apiMenuId === apiMenuId)
        let deleteMe = window.confirm(`Are you sure you want to delete ${menu.title}`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-menus', 'DELETE', ticketMenu.id), {}, -1))
        }
    }


    return (
        <div key={menu.id} className="card">
            <div className="initials-holder">
                <span className="initials">
                    { menu
                        ? <>{menu.title.substr(0, 1)}</>
                        : <>&ndash;&ndash;</>
                    }
                </span>
            </div>
            <div className="title" to={buildRoutePath(routes.adminMenusView.path, { menuId: menu.id })}>
                {menu.title}
            </div>
            <div className="details-wrapper">
                <span className="details"><em>{menu.subtitle}</em></span>
            </div>
            <div className="details-wrapper">
                <span className="details">{menu.owner}</span>
            </div>
            <div className="details-wrapper">
                <Link className="details" to={buildRoutePath(routes.adminMenusView.path, { menuId: menu.id })}>
                Manage Menu
                </Link>
            </div>
            <FontAwesomeIcon icon={['far', 'trash-can']} onClick={handleDelete}/>
        </div>
    )
}

export default (MenuCard)
