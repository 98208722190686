import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../constants/routes'

import ChefPreview from './chef-preview'


// NOTE: The idea is this would be for the chef, tickets, etc. Items not behind a login that also dont have navigation bar at the top
const PreviewIndex = () => {
    return (
        <>
            <div id="preview-wrapper">
                <Routes>
                    <Route path={nestedRoutePath(routes.previewIndex.path, routes.chefPreview.path, true)} element={<ChefPreview />} />
                </Routes>
            </div>
        </>
    )
}

export default PreviewIndex
