import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        menus: state.ticketMenus.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketMenu = ({ ticket, menuMeta, menus }) => {
    const renderMenu = () => {
        let allMenus = []
        menus.forEach(menu => {
            allMenus.push(
                <tr key={menu.id}>
                    <td>{menu.apiMenuId}</td>
                    <td>{menu.template}</td>
                    <td>{menu.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Menu Type</th>
                        <th>Menu Items</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allMenus}
                </tbody>
            </table>
        )
    }

    if (!menus || menus.length === 0) {
        return null
    }

    return (
        <div className="section ticket-timeline">
            <h2>Menu Items</h2>
            {renderMenu()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketMenu)
