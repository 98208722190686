import React from 'react'

const DisplayValue = (props) => {
    const { label, value, showNull, defaultNullValue } = props

    if (!value && !showNull) {
        return null
    }

    return (
        <div className={`display-value ${props.extraClass ? props.extraClass : ''}`}>
            <span className="label">
                {label}
            </span>
            <span className="value">
                { value
                    ? <>{value}</>
                    : <>{defaultNullValue ? defaultNullValue : '--'}</>
                }
            </span>
        </div>
    )
}

export default DisplayValue
