import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { callFileApi } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

import ShsForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

const CreatePhoto = ({ dispatch, navigate }) => {
    const submitFX = (formData, formId) => {
        dispatch(callFileApi(apiResourceEndpoint('admin/photos', 'CREATE'), formData, formId))
    }

    const successFX = (photoId) => {
        navigate(buildRoutePath(routes.adminPhotosView.path, { photoId }))
    }

    return (
        <>
            <div className="section-header">
                <span className="title">Photos</span>
            </div>
            <div className="form-wrapper">
                <p>Use the form below to add a photo to the database.</p>
                <ShsForm submitFX={submitFX} successFX={successFX} useFormData={true}>
                    <InputElement
                        type="file"
                        id="file"
                        label="Upload Photo" />
                    <InputElement
                        type="text"
                        id="alt"
                        label="Alt Text (Optional)"
                        placeholder="Short description of image " />
                    <InputElement
                        type="text"
                        id="caption"
                        label="Caption (Optional)"
                        placeholder="Enter a caption" />
                </ShsForm>
            </div>
        </>
    )
}

export default withRouterProps(connect()(CreatePhoto))
