import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        inventories: state.ticketInventories.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketInventory = ({ ticket, inventories }) => {
    const renderInventories = () => {
        let allInventory = []
        inventories.forEach(inventory => {
            allInventory.push(
                <tr key={inventory.id}>
                    <td>{inventory.item}</td>
                    <td>{inventory.quantity}</td>
                    <td>{inventory.responsibleParty}</td>
                    <td>{inventory.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Responsible Party</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allInventory}
                </tbody>
            </table>
        )
    }

    return (
        <div className="section ticket-timeline">
            <h2>Party Inventory List</h2>
            {renderInventories()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketInventory)
