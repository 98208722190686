import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import { fetchApiDataIfNeeded, callApi } from '../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../constants/routes'

const mapStateToProps = (state) => {
    return {
        chef: state.adminChefs.filter(c => c.id === 'chef_01hdf1v393wkp18ybrm8rh2f08')
    }
}

const CreateMenu = ({ dispatch, navigate, chef }) => {
    const ownerOptions = [{ label: 'Tivity', value: 'Tivity' }, { label: 'Chef', value: 'Chef' }]
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chefs', 'LIST')))
    }, [dispatch])

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/menus', 'CREATE'), formData, formId))
    }

    const successFX = (menuId) => {
        navigate(buildRoutePath(routes.adminMenusView.path, { menuId }))
    }

    return (
        <div>
            <h2>Create a New Menu</h2>
            <p>To start, give the menu a title.</p>
            <div className="form-wrapper">
                <SHSForm submitFX={submitFX} successFX={successFX}>
                    <InputElement
                        type="text"
                        id="title"
                        label="Menu Title"
                        placeholder="e.g. Smith Summer Dinner Menu or Sample Southwest BBQ"
                        required />
                    <InputElement
                        id="owner"
                        label="Owner"
                        type="valueSelect"
                        options={ownerOptions}
                        default={'TIVITY'}
                        required
                    />
                    <InputElement
                        type="hidden"
                        id="chefId"
                        default={chef.id}
                        label={null}
                        extraClass="d-none" />
                </SHSForm>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateMenu))
