import React, { useState } from 'react'
import { connect } from 'react-redux'

import MenuItemTitle from './menu-item-title'
import SearchComplete from '../../../../utils/search-complete'
import { callApi } from '../../../../../actions/api'
import { apiResourceEndpoint } from '../../../../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        menuItems: state.chefMenuItems.filter(ami => ami.menuSectionId === ownProps.section.id)
            .sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                return -1
            })
    }
}

const MenuItems = ({ dispatch, menu, section, menuItems }) => {
    const [showAddForm, setShowAddForm] = useState(false)

    const renderFoodItemResult = (result) => {
        return (
            <>
                {result.title}
            </>
        )
    }

    const handleSelectResult = (result) => {
        const formData = {
            menuId: menu.id,
            menuSectionId: section.id,
            foodItemId: result.id,
            order: menuItems.length
        }
        dispatch(callApi(apiResourceEndpoint('chefs/menu-items', 'CREATE'), formData, -1))
        setShowAddForm(false)
    }

    const handleDelete = (item) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this menu item?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('chefs/menu-items', 'DELETE', item.id), {}, -1))
        }
    }

    const handleNewItem = (foodItemTitle) => {
        const formData = {
            menuId: menu.id,
            menuSectionId: section.id,
            order: menuItems.length,
            foodItemTitle: foodItemTitle
        }
        dispatch(callApi(apiResourceEndpoint('chefs/food-items', 'CREATE', null, 'create-and-add-to-menu'), formData, -1))
        setShowAddForm(false)
    }

    const renderAddItem = () => {
        if (showAddForm) {
            return (
                <div className="menu-block add-item-form">
                    <SearchComplete
                        placeholder="Search for a Food Item"
                        searchEndpoint="chefs/food-items"
                        renderTemplate={renderFoodItemResult}
                        onHandleSelect={handleSelectResult}
                        allowCreation={true}
                        onCreationFx={handleNewItem} />
                    <span onClick={() => setShowAddForm(false)}>Cancel</span>
                </div>
            )
        }
        return (
            <div className="menu-block add-menu-item" onClick={() => setShowAddForm(true)}>
                + Add a Menu Item
            </div>
        )
    }

    const renderItems = () => {
        let allItems = []
        menuItems.forEach(item => {
            allItems.push(
                <div key={item.id}>
                    <div className="menu-block item">
                        <div className="block-details">
                            Menu Item
                            <FontAwesomeIcon icon={['far', 'trash-can']} onClick={() => handleDelete(item)} />
                        </div>
                        <MenuItemTitle item={item} />
                    </div>
                </div>
            )
        })
        return allItems
    }

    return (
        <div className="items-wrapper">
            {renderItems()}
            {renderAddItem()}
        </div>
    )
}

export default connect(mapStateToProps)(MenuItems)
