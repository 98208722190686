export const DIET_CONFIG = {
    LACTOSE: {
        label: 'Lactose',
        icon: 'cow'
    },
    VEGETARIAN: {
        label: 'Vegetarian',
        icon: 'egg'
    },
    VEGAN: {
        label: 'Vegan',
        icon: 'carrot'
    },
    KOSHER: {
        label: 'Kosher',
        icon: 'star-of-david'
    },
    HALAL: {
        label: 'Halal',
        icon: 'star-and-crescent'
    },
    LOW_CARB: {
        label: 'Low Carb',
        icon: 'drumstick-bite'
    },
    GLUTEN: {
        label: 'Gluten',
        icon: 'wheat-awn-circle-exclamation'
    }
}
