import React from 'react'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const TimelineBlock = (props) => {
    const { ticket } = props

    return (
        <TicketBlock routeObj={routes.adminTicketTimeline} config="TIMELINE" ticket={ticket}>
            <DisplayValue label="Timeline Count" value={`${ticket._computed.timelineCount} Item${ticket._computed.timelineCount === 1 ? '' : 's'}`} />
        </TicketBlock>
    )
}

export default TimelineBlock
