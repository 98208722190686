import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

import { PaginationWrapper } from 'shs-pagination'
import { formatBytes } from '../../utils/formatters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state) => {
    const paginator = state.paginator.adminPhotos
    let photos = state.adminPhotos
    if (paginator) {
        photos = photos
            .filter(photo => paginator.itemIds.indexOf(photo.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return { photos, paginator }
}

const PhotosList = ({ dispatch, photos, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/photos', 'LIST')))
    }, [dispatch])

    const handleDelete = (photo) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this photo?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/photos', 'DELETE', photo.id), {}, -1))
        }
    }

    const renderPhotoItem = (photo, index) => {
        return (
            <div className="paginated-item" key={photo.id}>
                <span className="index">{index}.</span>
                <div className="thumbnail">
                    <img src={process.env.REACT_APP_API_URL + apiResourceEndpoint(`api-photos/${photo.id}`, 'LIST').url + '?style=thumbnail'} alt={photo?.alt || photo.name} />
                </div>
                <Link to={buildRoutePath(routes.adminPhotosView.path, { photoId: photo.id })}>{photo.name}</Link>
                <span>
                    {photo.width}x{photo.height}
                </span>
                <span>
                    {formatBytes(photo.filesize)}
                </span>
                <span className="actions" title="Delete" onClick={() => handleDelete(photo)}>
                    <FontAwesomeIcon icon={['far', 'trash-can']} />
                </span>
            </div>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/photos', 'LIST'), paginationParams))
    }

    const renderPhotos = () => {
        if (!paginator) {
            return null
        }
        let allPhotos = []
        photos.forEach((photo, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allPhotos.push(renderPhotoItem(photo, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate} >
                {allPhotos}
            </PaginationWrapper>
        )
    }

    return (
        <div>
            <Link to={routes.adminPhotosCreate.path}>+ Add Photos</Link>
            {renderPhotos()}
        </div>
    )
}

export default connect(mapStateToProps)(PhotosList)
