import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchPublicApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import DisplayValue from '../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        apiCuisines: state.apiCuisines,
        apiServiceStyles: state.apiServiceStyles,
        apiVibes: state.apiVibes,
        contacts: state.ticketContacts.filter(tc => tc.ticketId === ownProps.ticket.id),
        guestMeta: state.ticketGuestMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const TicketOverview = ({ dispatch, ticket, apiCuisines, apiServiceStyles, apiVibes, contacts, guestMeta }) => {
    useEffect(() => {
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-cuisines', 'LIST')))
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-service-styles', 'LIST')))
        dispatch(fetchPublicApiDataIfNeeded(apiResourceEndpoint('public/api-vibes', 'LIST')))
    }, [dispatch])
    const clientContact = contacts.find(c => c.isClient === true)
    const pointOfContact = contacts.find(c => c.isPointOfContact === true)

    const cuisine = apiCuisines.find(ac => ac.id === ticket.apiCuisineTypeId)
    const service = apiServiceStyles.find(as => as.id === ticket.apiServiceStyleId)
    const vibe = apiVibes.find(av => av.id === ticket.apiVibeId)

    const displayValueConfig = {
        showNull: true,
        defaultNullValue: 'TBD'
    }

    const renderAddress = () => {
        let address = ''
        if (ticket.street) {
            address = address + ' ' + ticket.street
        }
        if (ticket.city) {
            address = address + ' ' + ticket.city
        }
        if (ticket.state) {
            address = address + ' ' + ticket.state
        }
        if (ticket.postal) {
            address = address + ' ' + ticket.postal
        }
        return address.trim()
    }

    const renderContact = (contact) => {
        if (!contact) {
            return null
        }
        return (
            <span>
                {`${contact.firstName} ${contact.lastName}`}&nbsp;
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>&nbsp;
                <a href={`mailto:${contact.phone}`}>{contact.email}</a>
            </span>
        )
    }

    return (
        <div className="section ticket-overview">
            <h2>Meal Event Overview</h2>
            <DisplayValue label="Date" value={moment(ticket.eventDate).format('dddd MMMM D, Y')} {...displayValueConfig} />
            <DisplayValue label="Location" value={renderAddress()} {...displayValueConfig} />
            <DisplayValue label="Client" value={renderContact(clientContact)} {...displayValueConfig} />
            <DisplayValue label="Point of Contact" value={renderContact(pointOfContact)} {...displayValueConfig} />
            <DisplayValue label="Guest Count" value={guestMeta.estimatedHeadcount} {...displayValueConfig} />
            <DisplayValue label="Vibe" value={vibe?.name} {...displayValueConfig} />
            <DisplayValue label="Cuisine" value={cuisine?.name} {...displayValueConfig} />
            <DisplayValue label="Service Style" value={service?.name} {...displayValueConfig} />
        </div>
    )
}

export default connect(mapStateToProps)(TicketOverview)
