import React, { useState } from 'react'
import { connect } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import EventFormInfo from './forms/event-form-info'
import EventFormContact from './forms/event-form-contact'
import SHSForm from '../utils/shs-form'

import { upsertForm } from '../../reducers/form'
import { formStates } from '../../constants/helper-states'
import { callPublicApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'

const FORM_STEPS = ['EVENT', 'CONTACT', 'SUBMITTED']

const CreateEvent = (props) => {
    const { dispatch } = props
    const [ formState, setFormState ] = useState('EVENT')
    const [formFields, setFormFields] = useState({
        eventName: '',
        eventDate: '',
        eventTime: '',
        headcount: '',
        street: '',
        city: '',
        state: '',
        postal: '',
        apiCuisineTypeId: '',
        apiServiceStyleId: '',
        apiVibeId: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: ''
    })

    // const navigate = useNavigate()

    const handleChange = (field, value) => {
        setFormFields(prevState => ({ ...prevState, [field]: value }))
    }

    const advanceForm = () => {
        const formIdx = FORM_STEPS.indexOf(formState)
        setFormState(FORM_STEPS[formIdx + 1])
        window.scrollTo(0, 0)
    }

    const renderForm = () => {
        const commonProps = {
            formFields: formFields,
            onHandleChange: handleChange,
            onAdvanceForm: advanceForm
        }
        if (formState === 'EVENT') {
            return <EventFormInfo {...commonProps} />
        } else if (formState === 'CONTACT') {
            return <EventFormContact {...commonProps} />
        } else if (formState === 'SUBMITTED') {
            return (<div>Thank you for submitting the form. Someone from Tivity will follow up within 24 hours.</div>)
        }
        return null
    }

    const submitFX = (formData, formId) => {
        if (formState === 'EVENT') {
            dispatch(upsertForm({ id: formId, status: formStates.EMPTY }))
            advanceForm()
        } else if (formState === 'CONTACT') {
            dispatch(callPublicApi(apiResourceEndpoint('public/event-forms', 'CREATE'), formFields, formId))
        }
    }

    const successFX = (formId) => {
        advanceForm()
    }

    const renderButtonLabel = () => {
        if (formState === 'EVENT') {
            return 'Next'
        }
        return 'Book a Chef'
    }

    const renderContent = () => {
        switch (formState) {
        case 'EVENT':
            return (
                <p>
                    Tell a few essentials about your event.<br />
                    This will help us get started with an event proposal.
                </p>
            )
        case 'INFO':
            return (
                <p>
                    Now a few personal details so we can contact you.
                </p>
            )
        default:
            return null
        }
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/place-setting.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <img src="/img/icons/tivity-logo.png" alt="logo" width="100px" />
                        <h1>Book a Chef</h1>
                        {renderContent()}
                        {
                            formState === 'SUBMITTED'
                                ? renderForm()
                                : <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel={renderButtonLabel()} formClass="center" resetForm={true}>
                                    {renderForm()}
                                </SHSForm>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect()(CreateEvent)
