import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import TicketHeader from '../parts/ticket-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'
import EditableDisplayField from '../../../utils/editable-display-field'
import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import * as MASKS from '../../../utils/input-masks'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        guestMeta: state.ticketGuestMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        guestType: state.configs.filter(c => c.type === 'GUEST_TYPE')
            .sort((a, b) => {
                if (a.order > b.order) {
                    return 1
                }
                return -1
            }),
        guests: state.ticketGuests.filter(tg => tg.ticketId === Number(ownProps.params.ticketId))
            .sort((a, b) => {
                if (a.fullName > b.fullName) {
                    return 1
                }
                return -1
            })
    }
}

const TicketGuests = ({ dispatch, params, ticket, guestMeta, guests, guestType }) => {
    const [showForm, setShowForm] = useState(false)
    const guestOptions = guestType.map(opt => ({ value: opt.id, label: opt.display }))

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/ticket-guests', 'LIST'), { filter: [`ticket_id|${params.ticketId}`] }))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/configs', 'LIST'), { filter: [`type|GUEST_TYPE`] }))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="user-friends" /> <span>Guests</span>
                </div>
                <div className="meta-content">
                    <span className="meta-value">{guestMeta.estimatedHeadcount} Headcount;</span>
                    <span className="meta-value">{guestMeta.isUsingInvitations ? 'Is' : 'Not'} using invites</span>
                    <span className="icon-btn"><FontAwesomeIcon icon="pen" /></span>
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'GUESTS' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const handleDelete = (guest) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this guest?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/ticket-guests', 'DELETE', guest.id), {}, -1))
        }
    }

    const renderGuests = () => {
        let allGuests = []
        guests.forEach(guest => {
            let rsvpDisplay = ''
            if (guest.rsvp === true) {
                rsvpDisplay = 'Yes'
            } else if (guest.rsvp === false) {
                rsvpDisplay = 'No'
            }
            allGuests.push(
                <div key={guest.id} className="item">
                    <EditableDisplayField
                        label="Name"
                        display={guest.fullName}
                        dataObj={guest}
                        endpoint="admin/ticket-guests"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'fullName'
                        }]} />
                    <EditableDisplayField
                        label="Email"
                        display={guest.email}
                        dataObj={guest}
                        endpoint="admin/ticket-guests"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'email',
                            field: 'email'
                        }]} />
                    <EditableDisplayField
                        label="Phone"
                        display={MASKS.phoneMask.resolve(guest.phone)}
                        dataObj={guest}
                        endpoint="admin/ticket-contact"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'phone',
                            isFormatted: true,
                            formatMask: MASKS.phoneMask
                        }]} />
                    <EditableDisplayField
                        label="RSVP"
                        display={rsvpDisplay}
                        dataObj={guest}
                        endpoint="admin/ticket-guests"
                        extraClass="field party"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'rsvp',
                            options: [{ label: 'Yes', value: true }, { label: 'No', value: '0' }]
                        }]} />
                    <EditableDisplayField
                        label="Guest Type"
                        display={guest._computed.guestTypeDisplay}
                        dataObj={guest}
                        endpoint="admin/ticket-guests"
                        extraClass="field party"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'guestTypeId',
                            options: guestOptions
                        }]} />
                    <EditableDisplayField
                        label="Notes"
                        display={guest.notes}
                        dataObj={guest}
                        endpoint="admin/ticket-guests"
                        extraClass="field notes"
                        inTable={true}
                        fields={[{
                            type: 'textarea',
                            field: 'notes'
                        }]} />
                    <span className="remove" title="Delete" onClick={() => handleDelete(guest)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return (
            <div className="ticket-items">
                {allGuests}
            </div>
        )
    }

    const submitFX = (formData, formId) => {
        formData.ticketId = params.ticketId
        dispatch(callApi(apiResourceEndpoint('admin/ticket-guests', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)} >
                        <InputElement
                            id="fullName"
                            label="Full Guest Name"
                            type="text"
                            extraClass="flex-50"
                            required />
                        <InputElement
                            id="email"
                            label="Email"
                            type="email"
                            extraClass="flex-50"
                            required />
                        <InputElement
                            id="phone"
                            label="Phone"
                            type="text"
                            isFormatted={true}
                            formatMask={MASKS.phoneMask}
                            extraClass="flex-50"
                            required />
                        <InputElement
                            type="valueSelect"
                            id="guestTypeId"
                            label="Guest Type"
                            default={guests.guestTypeId}
                            initialValueLabel="Select Type of Guest"
                            options={guestOptions}
                            extraClass="flex-50"
                            required />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Guest +
            </div>
        )
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderGuests()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketGuests))
