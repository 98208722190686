import { generatePath } from 'react-router-dom'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    publicIndex: { path: '/', label: 'Home' },
    privacyPolicy: { path: '/privacy-policy/', label: 'Privacy Policy' },
    router: { path: '/router/', label: '' },

    authLogin: { path: '/login/', label: 'Login' },
    authLogout: { path: '/logout/', label: 'Logout' },
    authRegister: { path: '/register/', label: 'Register' },
    authPasswordHelp: { path: '/password-help/', label: 'Password Help' },
    authPasswordReset: { path: '/password-reset/', label: 'Password Reset' },
    authHandler: { path: '/auth-handler/', label: '' },
    authRouter: { path: '/auth-router/', label: '' },

    createUser: { path: '/create-user/', label: '' },

    createEvent: { path: '/book-a-chef/', label: '' },

    contactInfo: { path: '/contact-info/', label: '' },

    // preview routes
    previewIndex: { path: '/preview/', label: 'Preview' },
    chefPreview: { path: '/preview/chef/:chefId/', label: 'Chef Preview' },

    // shared public routes
    sharedIndex: { path: '/shared/', label: '' },
    sharedChefTicketPreview: { path: '/shared/chefs/ticket-preview/:ticketCode/', label: 'Chef Ticket Preview' },
    sharedClientTicketPreview: { path: '/shared/clients/ticket-preview/:ticketCode/', label: 'Client Ticket Preview' },

    // app routes
    appIndex: { path: '/app/', label: 'Dashboard' },

    eventsIndex: { path: '/app/events/', label: 'My Events' },

    adminIndex: { path: '/admin/', label: 'Dashboard' },

    adminChefsIndex: { path: '/admin/chefs/', label: 'Chefs' },
    adminChefsView: { path: '/admin/chefs/:chefId/', label: 'View' },
    adminChefsCreate: { path: '/admin/chefs/create/', label: 'Create' },

    //  Chef Sections
    adminChefOverviewIndex: { path: '/admin/chefs/:chefId/overview/', label: 'Overview' },
    adminChefOverviewView: { path: '/admin/chefs/:chefId/overview/:overviewId/', label: 'View' },
    adminChefTicketIndex: { path: '/admin/chefs/:chefId/tickets/', label: 'Tickets' },
    adminChefTicketView: { path: '/admin/chefs/:chefId/tickets/:ticketId/', label: 'View' },
    adminChefMenuIndex: { path: '/admin/chefs/:chefId/menus/', label: 'Menus' },
    adminChefMenuView: { path: '/admin/chefs/:chefId/menus/:menuId/', label: 'View' },
    adminChefProfileIndex: { path: '/admin/chefs/:chefId/profile/', label: 'Profile' },
    adminChefProfileView: { path: '/admin/chefs/:chefId/profile/:profileId/', label: 'View' },
    adminChefPhotosIndex: { path: '/admin/chefs/:chefId/photos/', label: 'Photos' },
    adminChefPhotosList: { path: '/admin/chefs/:chefId/photos/list/', label: 'List' },
    adminChefPhotosView: { path: '/admin/chefs/:chefId/photos/:photoId/', label: 'View' },
    adminChefAvailabilityIndex: { path: '/admin/chefs/:chefId/availability/', label: 'Availability' },
    adminChefAvailabilityView: { path: '/admin/chefs/:chefId/availability/:availabilityId/', label: 'View' },
    adminChefFinancialsIndex: { path: '/admin/chefs/:chefId/financials/', label: 'Financials' },
    adminChefFinancialsView: { path: '/admin/chefs/:chefId/financials/:financialId/', label: 'View' },
    adminChefDocumentsIndex: { path: '/admin/chefs/:chefId/documents/', label: 'Documents' },
    adminChefDocumentsView: { path: '/admin/chefs/:chefId/documents/:documentId/', label: 'View' },
    adminChefSettingsIndex: { path: '/admin/chefs/:chefId/settings/', label: 'Settings' },
    adminChefSettingsView: { path: '/admin/chefs/:chefId/settings/:settingId/', label: 'View' },

    adminPhotosIndex: { path: '/admin/photos/', label: 'Photos' },
    adminPhotosCreate: { path: '/admin/photos/create/', label: 'Create' },
    adminPhotosView: { path: '/admin/photos/:photoId/', label: 'View' },

    adminTicketsIndex: { path: '/admin/tickets/', label: 'Tickets' },
    adminTicketsView: { path: '/admin/tickets/:ticketId/', label: 'View' },
    adminTicketsCreate: { path: '/admin/tickets/create/', create: 'Create' },

    adminTicketOverview: { path: '/admin/tickets/:ticketId/overview/', label: 'Overview' },
    adminTicketGuests: { path: '/admin/tickets/:ticketId/guests/', label: 'Guests' },
    adminTicketChef: { path: '/admin/tickets/:ticketId/chef/', label: 'Chef' },
    adminTicketMenu: { path: '/admin/tickets/:ticketId/menu/', label: 'Menu' },
    adminTicketVenue: { path: '/admin/tickets/:ticketId/venue/', label: 'Venue' },
    adminTicketTimeline: { path: '/admin/tickets/:ticketId/timeline/', label: 'Timeline' },
    adminTicketInventory: { path: '/admin/tickets/:ticketId/inventory/', label: 'Inventory' },
    adminTicketBudget: { path: '/admin/tickets/:ticketId/budget/', label: 'Budget' },
    adminTicketSousChef: { path: '/admin/tickets/:ticketId/sous-chef/', label: 'Sous Chef' },
    adminTicketTravel: { path: '/admin/tickets/:ticketId/travel/', label: 'Travel' },
    adminTicketServer: { path: '/admin/tickets/:ticketId/server/', label: 'Servers' },
    adminTicketGrocery: { path: '/admin/tickets/:ticketId/grocery/', label: 'Groceries' },
    adminTicketCost: { path: '/admin/tickets/:ticketId/cost/', label: 'Cost' },
    adminTicketContact: { path: '/admin/tickets/:ticketId/contact/', label: 'Contact' },

    adminLeadsIndex: { path: '/admin/leads/', label: 'Leads' },
    adminLeadsView: { path: '/admin/leads/:leadId/', label: 'View' },

    adminMenusIndex: { path: '/admin/menus/', label: 'Menus' },
    adminMenusView: { path: '/admin/menus/:menuId/', label: 'View' },
    adminMenusCreate: { path: '/admin/menus/create/', label: 'Create' },

    adminFoodItemsIndex: { path: '/admin/menus/food-items/', label: 'Food Items' },
    adminFoodItemsCreate: { path: '/admin/menus/food-items/create/', label: 'Create' },
    adminFoodItemsView: { path: '/admin/menus/food-items/:foodItemId/', label: 'View' },

    adminSettingsIndex: { path: '/admin/settings/', label: 'Settings' },
    adminSettingsView: { path: '/admin/settings/:settingId/', label: 'View' },
    adminSettingsCreate: { path: '/admin/settings/view/', label: 'Create' },

    adminAllergensIndex: { path: '/admin/settings/allergens/', label: 'Allergens' },
    adminDietaryRestrictionsIndex: { path: '/admin/settings/dietary-restrictions/', label: 'Dietary Restrictions' },

    // Chef Portal Routes
    chefPortalIndex: { path: '/chefs/', label: 'Index' },
    chefPortalOverview: { path: '/chefs/overview/', label: 'Overview' },
    chefPortalSetup: { path: '/chefs/setup/', label: 'Setup' },
    chefPortalProfile: { path: '/chefs/profile/', label: 'Profile' },
    chefPortalPhotos: { path: '/chefs/photos/', label: 'Photos' },
    chefPortalAvailability: { path: '/chefs/availability/', label: 'Availability' },
    // Chef Portal Menus
    chefPortalMenusIndex: { path: '/chefs/menus/', label: 'Menus' },
    chefPortalMenusView: { path: '/chefs/menus/:menuId/', label: 'View' },
    chefPortalMenusCreate: { path: '/chefs/menus/create/', label: 'Create' },

    chefPortalFoodItemsIndex: { path: '/chefs/menus/food-items/', label: 'Food Items' },
    chefPortalFoodItemsView: { path: '/chefs/menus/food-items/:foodItemId/', label: 'View' },
    chefPortalFoodItemsCreate: { path: '/chefs/menus/food-items/create/', label: 'Create' },

    chefPortalTickets: { path: '/chefs/tickets/', label: 'Tickets' },
    chefPortalDocuments: { path: '/chefs/documents/', label: 'Documents' },

    modalSample: { path: '/:pagePath*/m/sample/', label: '' }
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}
