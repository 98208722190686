import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../utils/with-router-props'
import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'
import TicketHeader from '../parts/ticket-header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDrawer } from '../../../../reducers/ui-drawer'
import EditableDisplayField from '../../../utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        ticket: state.tickets.find(t => t.id === Number(ownProps.params.ticketId)),
        travelMeta: state.ticketTravelMeta.find(tm => tm.ticketId === Number(ownProps.params.ticketId)),
        travels: state.ticketTravel.filter(tg => tg.ticketId === Number(ownProps.params.ticketId))
    }
}

const TicketTravels = ({ dispatch, params, ticket, travelMeta, travels }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/tickets', 'VIEW', params.ticketId)))
    }, [dispatch, params.ticketId])

    if (!ticket) {
        return null
    }

    const renderSectionHeader = () => {
        return (
            <div className="ticket-section-header">
                <div className="title">
                    <FontAwesomeIcon icon="car" /> <span>Travels</span>
                </div>
                <div className="meta-content">
                    {/* <span className="meta-value">{travelMeta.tickedId} Coming soon...</span>
                    <span className="icon-btn"><FontAwesomeIcon icon="pen" /></span> */}
                    <span className="icon-btn" onClick={() => dispatch(openDrawer({ ticketId: ticket.id, category: 'TRAVELS' }))}>
                        <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </span>
                </div>
                <div className="status">
                    <span className="label">Status</span>
                    <div className="btn-wrapper">
                        <span className="active">In Progress</span>
                        <span>Pending</span>
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderMetaNotes = () => {
        return (
            <div className="meta-manager">
                <EditableDisplayField
                    label="Notes"
                    display={travelMeta.notes}
                    dataObj={travelMeta}
                    endpoint="admin/ticket-travel-meta"
                    extraClass="field name"
                    fields={[{
                        type: 'textarea',
                        field: 'notes'
                    }]} />
            </div>
        )
    }

    const renderTravels = () => {
        travels.map(travel => {
            return (
                <div className="category-blocks">
                    <div key={travel.id}>Travel Block??</div>
                    <div className="btn white bordered centered">Add Travel +</div>
                </div>
            )
        })
    }

    return (
        <>
            <TicketHeader ticket={ticket} />
            {renderSectionHeader()}
            {renderMetaNotes()}
            {renderTravels()}
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TicketTravels))
