import React from 'react'
import { connect } from 'react-redux'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const mapStateToProps = (state, ownProps) => {
    return {
        chefMeta: state.ticketChefMeta.find(tgm => tgm.ticketId === ownProps.ticket.id)
    }
}

const ChefBlock = ({ ticket, chefMeta }) => {
    return (
        <TicketBlock routeObj={routes.adminTicketChef} config="CHEFS" ticket={ticket}>
            <DisplayValue label="Notes" value={chefMeta.notes ? chefMeta.notes : <i>No notes yet...</i>} />
        </TicketBlock>
    )
}

export default connect(mapStateToProps)(ChefBlock)
