import React from 'react'
import { connect } from 'react-redux'
import { formatCurrency } from '../../utils/formatters'

const mapStateToProps = (state, ownProps) => {
    return {
        costs: state.ticketCosts.filter(tc => tc.ticketId === ownProps.ticket.id)
    }
}

const TicketBudget = ({ ticket, costs }) => {
    const renderCosts = () => {
        let allCosts = []
        let totalCost = 0
        costs.forEach(cost => {
            totalCost = totalCost + (cost.units * cost.clientRate)
            allCosts.push(
                <tr key={cost.id}>
                    <td>{cost.item}</td>
                    <td>{cost.units}</td>
                    <td>{formatCurrency(cost.clientRate)}</td>
                    <td>{formatCurrency(cost.units * cost.clientRate)}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {allCosts}
                    <tr>
                        <td style={{ textAlign: 'right' }} colSpan={3}>
                            <strong>Total Cost</strong>
                        </td>
                        <td>
                            <strong>{formatCurrency(totalCost)}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
    return (
        <div className="section ticket-timeline">
            <h2>Event Budget</h2>
            {renderCosts()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketBudget)
