import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
    return {
        groceries: state.ticketGroceries.filter(ti => ti.ticketId === ownProps.ticket.id)
    }
}

const TicketGrocery = ({ groceries }) => {
    const renderGrocery = () => {
        let allGroceries = []
        groceries.forEach(grocery => {
            allGroceries.push(
                <tr key={grocery.id}>
                    {/* <td>{menu.apiMenuId}</td>
                    <td>{menu.template}</td> */}
                    <td>{grocery.notes}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Grocery Items</th>
                        <th>Quantity</th>
                        <th>Responsible Party</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {allGroceries}
                </tbody>
            </table>
        )
    }

    if (!groceries || groceries.length === 0) {
        return null
    }

    return (
        <div className="section ticket-timeline">
            <h2>Grocery List</h2>
            {renderGrocery()}
        </div>
    )
}

export default connect(mapStateToProps)(TicketGrocery)
