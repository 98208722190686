import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'
import { Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../constants/routes'

import AdminNav from '../layout/admin-nav'
import ChefsIndex from './chefs'
import TicketsIndex from './tickets'
import LeadsIndex from './leads/index'
import MenusIndex from './menus'
import PhotosIndex from './photos'
import SettingsIndex from './settings'

import { closeDrawer } from '../../reducers/ui-drawer'

import '../../assets/scss/admin.scss'

const mapStateToProps = (state) => {
    return {
        uiDrawer: state.uiDrawer
    }
}

const AdminIndex = ({ dispatch, uiDrawer, location }) => {
    useEffect(() => {
        dispatch(closeDrawer())
    }, [dispatch, location.pathname])

    return (
        <>
            <AdminNav />
            <div id="admin-wrapper" className={`${uiDrawer.isOpen ? 'drawer-open' : 'drawer-closed'}`}>
                <div id="admin-layout">
                    <Routes>
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminLeadsIndex.path, true)} element={<LeadsIndex />} />
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminTicketsIndex.path, true)} element={<TicketsIndex />} />
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminChefsIndex.path, true)} element={<ChefsIndex />} />
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminMenusIndex.path, true)} element={<MenusIndex />} />
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminPhotosIndex.path, true)} element={<PhotosIndex />} />
                        <Route path={nestedRoutePath(routes.adminIndex.path, routes.adminSettingsIndex.path, true)} element={<SettingsIndex />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminIndex))
