import React from 'react'

import InputElement from '../../utils/input'

import * as MASKS from '../../utils/input-masks'

const EventFormContact = (props) => {
    const { formFields, onHandleChange } = props

    return (
        <>
            <InputElement
                type="text"
                id="firstName"
                label="First Name"
                default={formFields.firstName}
                onChange={onHandleChange}
                extraClass="flex-50"
                placeholder="First Name"
                required />
            <InputElement
                type="text"
                id="lastName"
                label="Last Name"
                default={formFields.lastName}
                onChange={onHandleChange}
                extraClass="flex-50"
                placeholder="Last Name"
                required />
            <InputElement
                type="tel"
                id="phone"
                label="Phone"
                default={formFields.phone}
                onChange={onHandleChange}
                isFormatted={true}
                formatMask={MASKS.phoneMask}
                extraClass="flex-100"
                placeholder="Phone"
                required />
            <InputElement
                type="email"
                id="email"
                label="Email"
                default={formFields.email}
                onChange={onHandleChange}
                extraClass="flex-100"
                placeholder="Email"
                required />
            <InputElement
                type="textarea"
                id="message"
                label="Additional requests and comments"
                default={formFields.message}
                onChange={onHandleChange}
                extraClass="flex-100"
                placeholder="Enter Comments Here" />
        </>
    )
}

export default EventFormContact
