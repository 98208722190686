import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import SHSForm from '../../../utils/shs-form'
import InputElement from '../../../utils/input'
import EditableDisplayField from '../../../utils/editable-display-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DIET_CONFIG } from '../../../../constants/diet-config'

const mapStateToProps = (state) => {
    return {
        restrictions: state.adminDietaryRestrictions.slice().sort((a, b) => {
            if (a.name > b.name) {
                return 1
            }
            return -1
        })
    }
}


const DietaryRestrictions = ({ dispatch, restrictions }) => {
    const [showForm, setShowForm] = useState(false)
    const restrictionOptions = Object.values(DIET_CONFIG).map(diet => ({ value: diet.icon, label: diet.label }))

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/dietary-restrictions', 'LIST')))
    }, [dispatch])

    if (!restrictions) {
        return null
    }

    const handleDelete = (restriction) => {
        let deleteMe = window.confirm(`Are you sure you want to delete ${restriction.name}`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('admin/dietary-restrictions', 'DELETE', restriction.id), {}, -1))
        }
    }

    const renderRestrictions = () => {
        let allRestrictions = []
        restrictions.forEach(restriction => {
            allRestrictions.push(
                <div key={restriction.id} className="item">
                    <EditableDisplayField
                        label="Name"
                        display={restriction.name}
                        dataObj={restriction}
                        endpoint="admin/dietary-restrictions"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'name'
                        }]} />
                    <EditableDisplayField
                        label="Description"
                        display={restriction.description}
                        dataObj={restriction}
                        endpoint="admin/dietary-restrictions"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'description'
                        }]} />
                    <EditableDisplayField
                        label="Icon"
                        display={<FontAwesomeIcon icon={restriction.icon}/>}
                        dataObj={restriction}
                        endpoint="admin/dietary-restrictions"
                        extraClass="field select"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'icon',
                            options: restrictionOptions
                        }]} />
                    <EditableDisplayField
                        label="Priority Level"
                        display={restriction.level}
                        dataObj={restriction}
                        endpoint="admin/dietary-restrictions"
                        extraClass="field name"
                        inTable={true}
                        fields={[{
                            type: 'number',
                            field: 'level'
                        }]} />
                    <span className="remove" title="Delete" onClick={() => handleDelete(restriction)}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            )
        })
        return <div className="ticket-items">{allRestrictions}</div>
    }

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/dietary-restrictions', 'CREATE'), formData, formId))
    }

    const successFX = (itemId) => {
        setShowForm(false)
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <div className="ticket-items">
                <div className="item">
                    <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)}>
                        <InputElement
                            id="name"
                            label="Name"
                            type="text"
                            extraClass="flex-50"
                            required />
                        <InputElement
                            id="description"
                            label="Description"
                            type="text"
                            extraClass="flex-50"
                        />
                        <InputElement
                            id="icon"
                            label="Icon"
                            type="valueSelect"
                            options={restrictionOptions}
                            extraClass="flex-50"
                        />
                        <InputElement
                            id="level"
                            label="Priority Level"
                            type="number"
                            extraClass="flex-50"
                            required />
                    </SHSForm>
                </div>
            </div>
        )
    }

    const renderActionButton = () => {
        if (showForm) {
            return null
        }
        return (
            <div className="btn white bordered centered" onClick={() => setShowForm(true)}>
                Add Dietary Restriction +
            </div>
        )
    }

    return (
        <>
            {renderRestrictions()}
            {renderAddForm()}
            {renderActionButton()}
        </>
    )
}

export default connect(mapStateToProps)(DietaryRestrictions)
