import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import ChefScheduler from './scheduler'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

const mapStateToProps = (state) => {
    return {
        primaryAreas: state.chefServiceAreas.filter(area => area.chefId === state.user?.apiUserId && area.availabilityType === 'PRIMARY'),
        temporaryAreas: state.chefServiceAreas.filter(area => area.chefId === state.user?.apiUserId && area.availabilityType === 'TEMPORARY')
    }
}

const ChefPortalAvailability = ({ dispatch, chef, primaryAreas, temporaryAreas }) => {
    const [ showForm, setShowForm ] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-service-areas', 'LIST')))
    }, [dispatch])

    const renderAddButton = () => {
        if (showForm) {
            return null
        }
        return (
            <span className="btn" onClick={() => setShowForm(true)}>
                + Add Location
            </span>
        )
    }

    const renderAddForm = () => {
        if (!showForm) {
            return null
        }
        return (
            <ChefScheduler chef={chef} onSetShowForm={setShowForm} />
        )
    }

    const renderDays = (area) => {
        let days = [
            { key: 'SUN', label: 'Sunday', index: 0, short: 'Sun' },
            { key: 'MON', label: 'Monday', index: 1, short: 'Mon' },
            { key: 'TUE', label: 'Tuesday', index: 2, short: 'Tue' },
            { key: 'WED', label: 'Wednesday', index: 3, short: 'Wed' },
            { key: 'THU', label: 'Thursday', index: 4, short: 'Thur' },
            { key: 'FRI', label: 'Friday', index: 5, short: 'Fri' },
            { key: 'SAT', label: 'Saturday', index: 6, short: 'Sat' }
        ]
        let allDays = []
        days.forEach(day => {
            let labelClass = ''
            let amActive = ''
            let pmActive = ''
            if (day.key in area.availability.days && area.availability.days[day.key].time) {
                labelClass = 'active'
                if (area.availability.days[day.key].time === 'PM' || area.availability.days[day.key].time === 'ALL') {
                    pmActive = 'active'
                }
                if (area.availability.days[day.key].time === 'AM' || area.availability.days[day.key].time === 'ALL') {
                    amActive = 'active'
                }
            }
            allDays.push(
                <div className="day-block" key={day.key}>
                    <span className={`day-label ${labelClass}`}>
                        {day.short}
                    </span>
                    <div className="day-night-options">
                        <div className={`day ${amActive}`}>
                            {amActive === 'active'
                                ? <FontAwesomeIcon icon="sun" />
                                : <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="sun" />
                                    <FontAwesomeIcon icon="slash" />
                                </span>
                            }
                        </div>
                        <div className={`night ${pmActive}`}>
                            {pmActive === 'active'
                                ? <FontAwesomeIcon icon="moon" />
                                : <span className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="moon" />
                                    <FontAwesomeIcon icon="slash" />
                                </span>
                            }
                        </div>
                    </div>
                </div>
            )
        })
        return <div className="day-scheduler">{allDays}</div>
    }

    const handleDelete = (areaId) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this schedule?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('chefs/chef-service-areas', 'DELETE', areaId), {}, -1))
        }
    }

    const renderAreas = () => {
        let allAreas = []
        primaryAreas.forEach(area => {
            allAreas.push(
                <div className="box mb-4" key={area.id}>
                    <h2>
                        <FontAwesomeIcon icon="map-marker-alt" />&nbsp;
                        {area.label}
                    </h2>
                    <div>{area.postal} +/- {area.radius} miles</div>
                    {renderDays(area)}
                    <p className="mt-4 mb-0">
                        <span className="clickable" onClick={() => handleDelete(area.id)}>Delete</span>
                    </p>
                </div>
            )
        })
        temporaryAreas.forEach(area => {
            allAreas.push(
                <div className="box mb-4" key={area.id}>
                    <h2>
                        <FontAwesomeIcon icon="suitcase" />&nbsp;
                        {area.label}
                    </h2>
                    <div>{area.postal} +/- {area.radius} miles</div>
                    <div className="mt-4">
                        {moment(area.startDate).format('ddd. MMMM DD, Y')} - {moment(area.endDate).format('ddd. MMMM DD, Y')}
                    </div>
                    <p className="mt-4 mb-0">
                        <span className="clickable" onClick={() => handleDelete(area.id)}>Delete</span>
                    </p>
                </div>
            )
        })
        return allAreas
    }

    return (
        <>
            <h2>Chef Availability</h2>
            {renderAreas()}
            {renderAddButton()}
            {renderAddForm()}
        </>
    )
}

export default connect(mapStateToProps)(ChefPortalAvailability)
