import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import SHSForm from '../../../../components/utils/shs-form'
import InputElement from '../../../../components/utils/input'
import EditableDisplayField from '../../../../components/utils/editable-display-field'

const mapStateToProps = (state, ownProps) => {
    return {
        contacts: state.adminChefContacts.filter(acc => acc.chefId === ownProps.chef.id)
    }
}

const ChefContactInfo = ({ dispatch, chef, contacts }) => {
    const [showForm, setShowForm] = useState(false)
    const contactMethods = [{ label: 'Email', value: 'EMAIL' }, { label: 'Phone', value: 'PHONE' }]

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/chef-contacts', 'LIST'), { filter: [`chef_id|${chef.id}`] }))
    }, [dispatch, chef.id])

    const renderContactList = () => {
        let allContacts = []
        contacts.forEach(contact => {
            allContacts.push(
                <div className="field-wrappers flex-50" key={contact.id}>
                    <EditableDisplayField
                        label="Type of Contact"
                        display={contact.contactType}
                        dataObj={contact}
                        endpoint="admin/chef-contacts"
                        inTable={true}
                        fields={[{
                            type: 'valueSelect',
                            field: 'contactType',
                            options: contactMethods
                        }]} />
                    <EditableDisplayField
                        label="Contact Value"
                        display={contact.value}
                        dataObj={contact}
                        endpoint="admin/chef-contacts"
                        inTable={true}
                        fields={[{
                            type: 'text',
                            field: 'value'
                        }]} />
                </div>
            )
        })
        return allContacts
    }

    const successFX = () => {
        setShowForm(false)
    }

    const submitFX = (formData, formId) => {
        formData.chefId = chef.id
        dispatch(callApi(apiResourceEndpoint('admin/chef-contacts', 'CREATE'), formData, formId))
    }

    const renderAddContact = () => {
        if (showForm) {
            return (
                <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowForm(false)}>
                    <InputElement
                        type="valueSelect"
                        id="contactType"
                        label="Type of Contact"
                        options={contactMethods}
                        extraClass="flex-50"
                        required />
                    <InputElement
                        type="text"
                        id="value"
                        label="Contact Value"
                        extraClass="flex-50"
                        required />
                </SHSForm>
            )
        }
        return (
            <div className="clickable" onClick={() => setShowForm(true)}>
                + Add Contact
            </div>
        )
    }

    return (
        <div className="content-box">
            <h3>Additional Contact Information</h3>
            {renderContactList()}
            {renderAddContact()}
        </div>
    )
}

export default connect(mapStateToProps)(ChefContactInfo)
