import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'

import { PaginationWrapper } from 'shs-pagination'
import moment from 'moment'

const mapStateToProps = (state) => {
    const paginator = state.paginator.leadForms
    let leadForms = state.leadForms
    if (paginator) {
        leadForms = leadForms
            .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
            .sort((a, b) => {
                if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                    return 1
                }
                return -1
            })
    }
    return {
        leads: leadForms,
        paginator
    }
}

const LeadsList = ({ dispatch, leads, paginator }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/lead-forms', 'LIST')))
    }, [dispatch])

    const renderLeadRow = (lead, index) => {
        return (
            <div key={lead.id} className="paginated-item">
                <span className="index">{index}</span>
                <Link to={buildRoutePath(routes.adminLeadsView.path, { leadId: lead.id })} className="main">
                    {lead.eventName}
                </Link>
                <span className="flex-20">{moment(lead.eventDate).format('MMM Do \'Y')}</span>
                <span className="flex-20">{lead._computed.statusDisplay}</span>
                <span className="flex-20">{moment(lead.createdAt).format('MMM Do \'Y h:mm:s a')}</span>
            </div>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/lead-forms', 'LIST'), paginationParams))
    }

    const renderLeads = () => {
        if (!paginator) {
            return null
        }
        let allLeads = []
        leads.forEach((lead, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allLeads.push(renderLeadRow(lead, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                {allLeads}
            </PaginationWrapper>
        )
    }

    return (
        <div>
            {renderLeads()}
        </div>
    )
}

export default connect(mapStateToProps)(LeadsList)
