import React from 'react'
import { connect } from 'react-redux'

import { callFileApi } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { closeDrawer } from '../../../reducers/ui-drawer'

const CreatePhoto = ({ dispatch, chef }) => {
    const submitFX = (formData, formId) => {
        dispatch(callFileApi(apiResourceEndpoint('chefs/chef-photos', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        dispatch(closeDrawer())
    }

    return (
        <div>
            <h3>Add a Photo</h3>
            <p>Upload a new photo.</p>
            <SHSForm submitFX={submitFX} successFX={successFX} useFormData={true} showCancel={true} cancelAction={() => closeDrawer()}>
                <InputElement
                    type="file"
                    id="file"
                    label="Image File"
                    required />
                <InputElement
                    type="hidden"
                    id="chefId"
                    default={chef.id}
                    label={null}
                    extraClass="d-none" />
                <InputElement
                    type="hidden"
                    id="order"
                    default={chef.photoCount + 1}
                    label={null}
                    extraClass="d-none" />
            </SHSForm>
        </div>
    )
}

export default connect()(CreatePhoto)
