import React, { useState } from 'react'
import { connect } from 'react-redux'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import { registerWithEmailAndPass } from '../../actions/auth'
import VerifyPassword from '../utils/verify-password'
import { formStates } from '../../constants/helper-states'
import { upsertForm } from '../../reducers/form'

const Register = ({ dispatch, fireauth }) => {
    const [allValid, setAllValid] = useState(false)

    const submitFX = (formData, formId) => {
        if (allValid) {
            dispatch(registerWithEmailAndPass(fireauth, formData.email, formData.password, formId))
        } else {
            dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: 'Validation failed. Please check you followed the password requirements.' }))
        }
    }

    const successFX = () => {
        // this will be handled by firebase auth
    }

    const handleValidationChange = (isValid) => {
        setAllValid(isValid)
    }

    return (
        <>
            <div className="full-page-bg-img" style={{ backgroundImage: 'url("/img/bg/pizza-salad.jpg")' }} />
            <div className="form-holder">
                <div className="event-form">
                    <div className="form-header">
                        <span className="logo-container">
                            <img src="/img/icons/tivity-logo.png" alt="Tivity Logo" />
                            <h1>register</h1>
                        </span>
                        <p>
                            Use the form below to create an account with Tivity.
                        </p>
                    </div>
                    <SHSForm submitFX={submitFX} successFX={successFX}>
                        <InputElement
                            type="email"
                            id="email"
                            label="Email Address"
                            placeholder="user@example.com"
                            required />
                        <VerifyPassword onValidationChange={handleValidationChange} />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default connect()(Register)
