import React from 'react'
import TicketBlock from '../parts/ticket-block'
import { routes } from '../../../../constants/routes'
import DisplayValue from '../../../utils/display-value'

const InventoryBlock = (props) => {
    const { ticket } = props

    return (
        <TicketBlock routeObj={routes.adminTicketInventory} config="INVENTORY" ticket={ticket}>
            <DisplayValue label="Inventory Count" value={`${ticket._computed.inventoryCount} Item${ticket._computed.inventoryCount === 1 ? '' : 's'}`} />
        </TicketBlock>
    )
}

export default InventoryBlock
