import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../../../utils/with-router-props'

import SHSForm from '../../../../utils/shs-form'
import InputElement from '../../../../utils/input'

import { callApi } from '../../../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../../../constants/routes'

const mapStateToProps = (state) => {
    return {
        chef: state.chefs.filter(c => c.id === state.user?.apiUserId)
    }
}

const CreateFoodItem = ({ dispatch, navigate, chef }) => {
    // const ownerOptions = [{ label: 'Tivity', value: 'Tivity' }, { label: 'Chef', value: 'Chef' }]

    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('chefs/food-items', 'CREATE'), formData, formId))
    }

    const successFX = (foodItemId) => {
        navigate(buildRoutePath(routes.chefPortalFoodItemsView.path, { foodItemId }))
    }

    return (
        <div className="box">
            <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => navigate(routes.chefPortalFoodItemsIndex.path)}>
                <InputElement
                    id="title"
                    label="Title"
                    type="text"
                    extraClass="flex-33"
                    required />
                <InputElement
                    id="description"
                    label="Description"
                    type="text"
                    extraClass="flex-33"
                />
                <InputElement
                    id="owner"
                    label={null}
                    type="hidden"
                    default={'CHEF'}
                    extraClass="d-none"
                    required
                />
                <InputElement
                    type="hidden"
                    id="chefId"
                    default={chef.id}
                    label={null}
                    extraClass="d-none"
                    required />
            </SHSForm>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateFoodItem))
