import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import EditableDisplayField from '../../utils/editable-display-field'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        socials: state.chefSocials.filter(cs => cs.chefId === ownProps.chef.id)
    }
}

const ChefSocial = ({ dispatch, chef, socials }) => {
    const [ showAdd, setShowAdd ] = useState(false)
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('chefs/chef-socials', 'LIST')))
    }, [dispatch])

    const handleDelete = (social) => {
        let deleteMe = window.confirm(`Are you sure you want to delete this item?`)
        if (deleteMe) {
            dispatch(callApi(apiResourceEndpoint('chefs/chef-socials', 'DELETE', social.id), {}, -1))
        }
    }

    const renderSocialList = () => {
        let allSocials = []
        socials.forEach(social => {
            allSocials.push(
                <tr key={social.id}>
                    <td>
                        <EditableDisplayField
                            label={null}
                            display={social.platform}
                            dataObj={social}
                            endpoint="chefs/chef-socials"
                            inTable={true}
                            fields={[{
                                type: 'select',
                                field: 'platform',
                                options: ['Instagram', 'Facebook', 'Twitter', 'LinkedIn', 'Pinterest', 'YouTube', 'TikTok', 'Website']
                            }]} />
                    </td>
                    <td>
                        <EditableDisplayField
                            label={null}
                            display={social.value}
                            dataObj={social}
                            endpoint="chefs/chef-socials"
                            inTable={true}
                            fields={[{
                                type: 'text',
                                field: 'value'
                            }]} />
                    </td>
                    <td>
                        <span className="clickable" onClick={() => handleDelete(social)}>
                            <FontAwesomeIcon icon={['far', 'trash-can']} />
                        </span>
                    </td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Platform</th>
                        <th>Handle/Address</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {allSocials}
                </tbody>
            </table>
        )
    }

    const submitFX = (formData, formId) => {
        formData.chefId = chef.id
        dispatch(callApi(apiResourceEndpoint('chefs/chef-socials', 'CREATE'), formData, formId))
    }

    const successFX = () => {
        setShowAdd(false)
    }

    const renderSocialAdd = () => {
        if (!showAdd) {
            return null
        }
        return (
            <SHSForm submitFX={submitFX} successFX={successFX} showCancel={true} cancelAction={() => setShowAdd(false)} resetForm={true}>
                <InputElement
                    type="select"
                    options={['Instagram', 'Facebook', 'Twitter', 'LinkedIn', 'Pinterest', 'YouTube', 'TikTok', 'Website']}
                    id="platform"
                    label="Platform"
                    required />
                <InputElement
                    type="text"
                    id="value"
                    label="Social Handle or URL"
                    placeHolder="i.e. @tivity or https://tivity.com"
                    required />
            </SHSForm>
        )
    }

    return (
        <div>
            <h2>Social Presence</h2>
            {renderSocialList()}
            {renderSocialAdd()}
            <span className="clickable" onClick={() => setShowAdd(true)}>+ Add Social</span>
        </div>
    )
}

export default connect(mapStateToProps)(ChefSocial)
