import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../../constants/routes'

import View from './view'
import List from './list'

import '../../../assets/scss/leads.scss'

const LeadsIndex = () => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.adminLeadsIndex.path, routes.adminLeadsView.path)} element={<View />} />
            <Route index element={<List />} />
        </Routes>
    )
}

export default LeadsIndex
