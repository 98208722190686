import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faChevronDown, faTimes, faCircle, faEye, faPen, faArrowRight, faCircleXmark, faCircleCheck, faPlus, faCircleExclamation, faMagnifyingGlass,
    faHeart, faSliders, faUser, faUsers, faArchive, faEllipsisV, faSlidersH, faList, faUserFriends, faMapMarkerAlt,
    faClipboardList, faProjectDiagram, faUserNinja, faCarrot, faMoneyBillWave, faExpand, faCompress, faSortUp, faSortDown, faSort, faPaperPlane, faThLarge, faShoppingCart, faUserTie, faCar, faMitten, faCheck, faAddressCard, faTicket, faImages,
    faFile, faFileAlt, faCog, faCow, faEgg, faFish, faShrimp, faWheatAwnCircleExclamation, faSunPlantWilt, faTree, faSeedling, faPersonDotsFromLine,
    faPlantWilt, faStarOfDavid, faStarAndCrescent, faDrumstickBite, faSun, faMoon, faSlash, faSuitcase, faBowlRice,
    faHandSparkles, faSignOut, faCaretDown
} from '@fortawesome/free-solid-svg-icons'

import {
    faDotCircle as farDotCircle, faCircle as farCircle, faCalendar as farCalendar, faTrashCan as farTrashCan, faCircleXmark as farCircleXmark, faBell as farBell, faHeart as farHeart, faCalendarAlt, faStickyNote, faClone, faFilePdf
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faChevronDown, faTimes, faCircle, faEye, faPen, faArrowRight, faCircleXmark, faCircleCheck, faPlus, faCircleExclamation, faMagnifyingGlass,
    faHeart, faSliders, faUser, faUsers, faArchive, faEllipsisV, faSlidersH, faList, faUserFriends, faMapMarkerAlt,
    faClipboardList, faProjectDiagram, faUserNinja, faCarrot, faMoneyBillWave, faExpand, faCompress, faSortUp, faSort, faSortDown, faPaperPlane, faThLarge, faShoppingCart, faUserTie, faCar, faMitten, faCheck,
    farCircle, farDotCircle, farCalendar, farTrashCan, farCircleXmark, farBell, farHeart, faStickyNote,
    faCalendarAlt, faClone, faFilePdf, faAddressCard, faTicket, faImages, faFile, faFileAlt,
    faCog, faCow, faEgg, faFish, faShrimp, faWheatAwnCircleExclamation, faSunPlantWilt, faTree, faSeedling, faPersonDotsFromLine, faPlantWilt, faStarOfDavid, faStarAndCrescent, faDrumstickBite, faSun, faMoon, faSlash, faSuitcase, faBowlRice,
    faHandSparkles, faSignOut, faCaretDown
)
